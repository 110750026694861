import React, { Component } from 'react';
import bg from '../../assets/img/bg-full.png';
import Nav from '../nav';
import Tit from '../components/Tit';
import Tab from '../components/Tab';
import ECharts from '../../components/ECharts';
import options from './options';
import CountUp from 'react-countup';
import Map from '../../components/Map';
import './index.scss'
import dy from '../../assets/icon_01.svg';
import { cqRequest } from '../../tools/http';
import empty from '../../assets/empty.png';
import _ from 'lodash';
import ScrollArea from 'react-scrollbar';
import moment from 'moment';
const ProgressChartColors1 = ['#00DBFF', '#91CC76', '#605BFF', '#F9B52E', '#C93ACA'];
class Index extends Component {

  constructor() {
    super()
    this.state = {
      data: [],
      level: 'SGC',
      tagList: [],//员工标签
      gradeList: [],//职级结构
      overview: {},//员工概览统计
      cpc: {},//员工党员统计
      distribution: {},//在职员工4饼图（年龄、学历等分布）
      onJob: {},// 在职员工趋势
      territory: [],// 在职员工地域分布
      empFunc: {},// 员工职能属性分布
      threeAge: {},// 在职员工三龄（年龄、司龄、工龄）统计（部门+职级）
      filterList: [],
      statisMonth: moment().subtract(1, 'month').format('YYYYMM'),
      dataQualityInfo: ''
    }
  }
  _init = () => {
    const { level, statisMonth } = this.state
    cqRequest(`/org/onJobEmpTagStat?level=${level}&statisMonth=${statisMonth}`).then((res) => {// 员工标签统计
      this.setState({
        tagList: res.data
      })
    })

    cqRequest(`/org/onJobEmpGradesStat?level=${level}&statisMonth=${statisMonth}`).then((res) => {// 职级结构
      this.setState({
        gradeList: res.data
      })
    })

    cqRequest(`/org/onJobEmpOverviewStat?level=${level}&statisMonth=${statisMonth}`).then((res) => {// 员工概览统计
      this.setState({
        overview: res.data
      })
    })

    cqRequest(`/org/onJobEmpCpcStat?level=${level}&statisMonth=${statisMonth}`).then((res) => {// 员工党员统计
      this.setState({
        cpc: res.data
      })
    })

    cqRequest(`/org/onJobEmpPieDistributionStat?level=${level}&statisMonth=${statisMonth}`).then((res) => {// 在职员工4饼图（年龄、学历等分布）
      this.setState({
        distribution: res.data
      })
    })

    cqRequest(`/org/onJobEmpStat?level=${level}&statisMonth=${statisMonth}`).then((res) => {// 在职员工趋势
      this.setState({
        onJob: res.data
      })
    })

    cqRequest(`/org/onJobEmpTerritoryStat?level=${level}&statisMonth=${statisMonth}`).then((res) => {// 在职员工地域分布
      this.setState({
        territory: res.data
      })
    })

    cqRequest(`/org/onJobEmpFuncStat?level=${level}&statisMonth=${statisMonth}`).then((res) => {// 员工职能属性分布
      let total = Number(_.sum(res.data.funcPie.map(d => Number(d.value))))
      res.data.funcPie.forEach(d => {
        d.value = Number(d.value)
        d.percent = Number(((d.value / total) * 100).toString().match(/^\d+(?:\.\d{0,1})?/))
      })
      this.setState({
        empFunc: res.data
      })
    })

    cqRequest(`/org/onJobEmpThreeAgeStat?level=${level}&statisMonth=${statisMonth}`).then((res) => {// 在职员工三龄（年龄、司龄、工龄）统计（部门+职级）
      this.setState({
        threeAge: res.data,
        filterList: ['平均年龄', '平均司龄', '平均工龄']
      })
    })

    cqRequest(`/common/dataQualityInfo?level=${level}&pageType=ORG_PAGE`).then((res) => {// 感叹号数据
      this.setState({
        dataQualityInfo: res.data
      })
    })
  }
  componentDidMount() {
    this._init()
  }
  getData = (data) => {
    this.setState({ data })
  }
  filter = (d) => {
    const { filterList } = this.state
    if (filterList.includes(d)) {
      this.setState({
        filterList: filterList.filter(e => e != d),
      })
    } else {
      this.setState({
        filterList: [...filterList, d]
      })
    }
  }
  render() {
    const { tagList, gradeList, overview, cpc, distribution, onJob, territory, empFunc, threeAge, filterList } = this.state
    let all = document.documentElement.clientHeight == 720 ||
      document.documentElement.clientHeight == 864 ||
      document.documentElement.clientHeight == 1080 ||
      document.documentElement.clientHeight == 818 ||
      document.documentElement.clientHeight == 850 ||
      document.documentElement.clientHeight == 960 ||
      document.documentElement.clientHeight == 610 ||
      (document.documentElement.clientHeight == 578 && document.documentElement.clientWidth != 1280) ||
      document.documentElement.clientHeight == 681 ||

      document.documentElement.clientHeight == 781 ||
      document.documentElement.clientHeight == 823 ||
      document.documentElement.clientHeight == 1042 ||

      document.documentElement.clientHeight == 1010 ||
      document.documentElement.clientHeight == 1152
      ? true : false
    let all1 = (document.documentElement.clientHeight == 578 && document.documentElement.clientWidth != 1280) ?
      true : false
    return (
      <div className={`home w${window.screen.width}`}>
        <img src={bg} width={'100%'} alt="" className='full-bg' />

        <Nav />
        <div className='home-content'>
          <div className={`home-content-left w${window.screen.width}`} style={{ width: 520, }}>
            <div className='mt0' style={{ marginBottom: all ? all1 ? -20 : 0 : -30 }}>
              <Tit text="员工概览"></Tit>
              <div className='home-content-center-preview'>
                <div className='home-content-center-preview-left' style={{ marginLeft: -20 }}>
                  <div >
                    <div className='flex-h-between'>
                      <div className='common-text' style={{ width: 100 }}>
                        <span>在职员工总数</span>
                        <div> <b><CountUp
                          end={overview.onJobEmpNum}
                          duration={2.75}
                          decimals={0}
                          decimal="."
                          suffix=""
                        /></b>
                          <span>人</span></div>
                      </div>
                      <div className='common-text' style={{ width: 100 }}>
                        <span>同比增长</span>
                        <div> <b><CountUp
                          end={overview.yearOverYear}
                          duration={2.75}
                          decimals={1}
                          decimal="."
                          suffix=""
                        /></b>
                          <span>%</span></div>
                      </div>
                    </div>

                    <div className='flex-h-between'>
                      <div className='common-text' style={{ width: 100 }}>
                        <span>平均年龄</span>
                        <div> <b><CountUp
                          end={overview.onJobAvgAge}
                          duration={2.75}
                          decimals={1}
                          decimal="."
                          suffix=""
                        /></b>
                          <span>岁</span></div>
                      </div>
                      <div className='common-h-line' style={{ height: 53, marginLeft: 3, marginRight: 3 }}></div>
                      <div className='common-text' style={{ width: 100 }}>
                        <span>平均司龄</span>
                        <div> <b><CountUp
                          end={overview.onJobAvgCompAge}
                          duration={2.75}
                          decimals={1}
                          decimal="."
                          suffix=""
                        /></b>
                          <span>年</span></div>
                      </div>
                    </div>
                  </div>
                  <div className='common-h-line' style={{ height: 145, marginLeft: 3, marginRight: 3 }}></div>
                  <div>
                    <div className='flex-h-between'>
                      <div className='common-text' style={{ width: 100 }}>
                        <span>管理者人数</span>
                        <div> <b><CountUp
                          end={overview.onJobManageNum}
                          duration={2.75}
                          decimals={0}
                          decimal="."
                          suffix=""
                        /></b>
                          <span>人</span></div>
                      </div>
                      <div className='common-h-line' style={{ height: 53, marginLeft: 3, marginRight: 3 }}></div>
                      <div className='common-text' style={{ width: 100 }}>
                        <span>男女比例</span>
                        <div> <b>{Number(overview.onJobMenRatio).toFixed(1)} : {Number(overview.onJobWomanRatio).toFixed(1)} </b>
                          <span></span></div>
                      </div>
                    </div>
                    <div className='flex-h-between'>
                      <div className='common-text' style={{ width: 100 }}>
                        <span>关键岗位</span>
                        <div> <b><CountUp
                          end={overview.onJobEmphasisNum}
                          duration={2.75}
                          decimals={0}
                          decimal="."
                          suffix=""
                        /></b>
                          <span>人</span></div>
                      </div>
                      <div className='common-h-line' style={{ height: 53, marginLeft: 3, marginRight: 3 }}></div>
                      <div className='common-text' style={{ width: 100 }}>
                        <span>高绩效员工</span>
                        <div> <b><CountUp
                          end={overview.onJobHighPerfNum}
                          duration={2.75}
                          decimals={0}
                          decimal="."
                          suffix=""
                        /></b>
                          <span>人</span></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={`${all ? all1 ? 'mt0' : 'mt20' : 'mt0'}`}>
              <h3 className='colb fz16' style={{ position: 'relative', bottom: -10 }}>在职员工总数趋势</h3>
              {
                onJob && onJob.categoryNames && onJob.categoryNames.length ?
                  <ECharts
                    style={{ height: all ? 240 : 190, width: '100%', marginTop: 10 }}
                    className="OfflineGrowthTrend_charts"
                    option={options.getLineChart1(onJob, onJob.categoryNames.length && onJob.categoryNames[0], true, '人',
                      onJob.categoryValues[0], ProgressChartColors1[0]
                    )}
                  /> : <div style={{ height: all ? 240 : 190, width: '100%' }} className="tc flex-h-center"><img src={empty}></img></div>
              }
            </div>
            <div className={`${all ? 'mt20' : 'mt0'}`} style={{ position: 'relative' }}>
              {/* <h3 className='colb fz16'>在职员工总数趋势</h3> */}
              {/* <h3 className='colb fz16'>在职员工总数趋势</h3> */}
              {
                onJob && onJob.categoryNames && onJob.categoryNames.length ?
                  <div className='flex-h-between' style={{ flexWrap: 'wrap' }}>
                    {onJob.categoryNames.filter((d, index) => index != 0 && index < 5).map((d, index) => <ECharts
                      style={{ height: all ? all1 ? 190 : 200 : 158, width: '47%', marginTop: 0 }}
                      className="OfflineGrowthTrend_charts"
                      option={options.getLineChart1(onJob, d, true, '人',
                        onJob.categoryValues[index], ProgressChartColors1[index])}
                    />)}</div>
                  : <div style={{ height: all ? all1 ? 190 : 200 : 158, width: '100%' }} className="tc flex-h-center"><img src={empty}></img></div>
              }
              {/* {
                onJob && onJob.categoryNames && onJob.categoryNames.length ?
                  <ECharts
                    style={{ height: 200, width: '47%', marginTop: 20 }}
                    className="OfflineGrowthTrend_charts"
                    option={options.getLineChart(onJob, 'CN', true, '人')}
                  /> : <div style={{ height: 200, width: '100%' }} className="tc flex-h-center"><img src={empty}></img></div>
              }
               */}
              {/* <div className='flex-h-between'>
                {
                  onJob && onJob.categoryNames && onJob.categoryNames.length ?
                    <ECharts
                      style={{ height: 200, width: '47%', marginTop: 20 }}
                      className="OfflineGrowthTrend_charts"
                      option={options.getLineChart(onJob, 'CP', true, '人')}
                    /> : <div style={{ height: 200, width: '100%' }} className="tc flex-h-center"><img src={empty}></img></div>
                }
                {
                  onJob && onJob.categoryNames && onJob.categoryNames.length ?
                    <ECharts
                      style={{ height: 200, width: '47%', marginTop: 20 }}
                      className="OfflineGrowthTrend_charts"
                      option={options.getLineChart(onJob, 'MAP', true, '人')}
                    /> : <div style={{ height: 200, width: '100%' }} className="tc flex-h-center"><img src={empty}></img></div>
                }
              </div> */}
              {/* {
                territory.length ?
                  <Map type={1}
                    datas={territory}
                    width={580}
                    height={440}
                    top={1}
                    bottom={40}
                    distance={93} left={-40}
                    right={5}></Map> :
                  <div style={{ height: 440, width: 530 }} className="tc flex-h-center"><img src={empty}></img></div>
              } */}
            </div>

          </div>
          <div className='common-h-line' style={{ height: 824, marginLeft: 30, marginRight: 30 }}></div>
          <div className={`home-content-center w${window.screen.width}`} style={{ width: 690 }}>
            <div className='flex-h-between' style={{ position: 'relative', top: -40, marginBottom: -30 }}>
              <Tab dataQualityInfo={this.state.dataQualityInfo} onChange={(e) => {
                this.setState({
                  level: e,
                }, () => {
                  this._init()
                })
              }} onChangeDate={(e) => {
                this.setState({
                  statisMonth: e,
                }, () => {
                  this._init()
                })
              }}></Tab>
            </div>


            <div className='home-content-center-user'>
              <Tit text="员工基本信息"></Tit>
              <div className='home-content-center-user-list flex-h-between'>
                <div className='tab-switch'>
                  <ul className='flex-h-start'>
                    {
                      threeAge.groupOrg && threeAge.groupOrg.categoryNames.map((d, index) => (
                        <li className={filterList.includes(d) ? '' : 'disabled'} onClick={() => {
                          this.filter(d)
                        }}>
                          <i style={{ background: ProgressChartColors1[index] }}></i>
                          <span>{d}</span>
                        </li>
                      ))
                    }

                  </ul>
                </div>
                <div style={{ width: '50%' }}>
                  <h3>各BU年龄&司龄&工龄对比</h3>
                  <div>
                    {
                      threeAge.groupOrg && threeAge.groupOrg.dimNames.length ?
                        <ECharts
                          style={{ height: all ? 280 : 230, width: '100%' }}
                          className="OfflineGrowthTrend_charts"
                          option={options.getBarCharts(threeAge.groupOrg.dimNames, [
                            {
                              name: threeAge.groupOrg.categoryNames[0],
                              value: threeAge.groupOrg.categoryValues[0]
                            },
                            {
                              name: threeAge.groupOrg.categoryNames[1],
                              value: threeAge.groupOrg.categoryValues[1]
                            },
                            {
                              name: threeAge.groupOrg.categoryNames[2],
                              value: threeAge.groupOrg.categoryValues[2]
                            }
                          ], false, {
                            rotate: this.state.level == 'SGC' ? 0 : -20,
                            filterList
                          })}
                        /> : <div style={{ height: all ? 280 : 230, width: '100%' }} className="tc flex-h-center"><img src={empty}></img></div>
                    }

                  </div>
                </div>
                <div style={{ width: '50%' }}>
                  <h3>各职级年龄&司龄&工龄对比</h3>
                  <div >
                    {
                      threeAge.groupGrades && threeAge.groupGrades.dimNames.length ?
                        <ECharts
                          style={{ height: all ? 280 : 230, width: '100%' }}
                          className="OfflineGrowthTrend_charts"
                          option={options.getBarCharts(threeAge.groupGrades.dimNames, [
                            {
                              name: threeAge.groupGrades.categoryNames[0],
                              value: threeAge.groupGrades.categoryValues[0]
                            },
                            {
                              name: threeAge.groupGrades.categoryNames[1],
                              value: threeAge.groupGrades.categoryValues[1]
                            },
                            {
                              name: threeAge.groupGrades.categoryNames[2],
                              value: threeAge.groupGrades.categoryValues[2]
                            }
                          ], false, {
                            filterList
                          })}
                        /> : <div style={{ height: all ? 280 : 230, width: '100%' }} className="tc flex-h-center"><img src={empty}></img></div>
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className='common-v-line' style={{ width: '100%', marginTop: all ? -10 : -30 }}></div>
            <div className='home-content-center-pie' style={{ marginTop: all ? -10 : -30, marginLeft: -30 }}>
              <div className='flex-h-between'>


                {
                  distribution.agePie && distribution.agePie.length ?
                    <ECharts
                      style={{ height: all ? 260 : 220, width: '50%' }}
                      option={options.getPie(distribution.agePie.filter(d => d.name && (d.name.includes('70') || d.name.includes('80') || d.name.includes('90'))),
                        '年龄分布', ['#00dbff', '#f27132', '#91cc76',], {
                        left: window.screen.width == 1280 || window.screen.width == 1920 || window.screen.width == 1536 ? '18%' : '18%',
                        line: 2,
                        right: window.screen.width == 1280 || window.screen.width == 1920 || window.screen.width == 1536 ? '0' : '-3.5%'
                        // legend:true
                      })}
                    /> : <div style={{ height: all ? 260 : 220, width: '50%' }} className="tc flex-h-center"><img src={empty}></img></div>

                }
                {
                  distribution.compAgePie && distribution.compAgePie.length ?
                    <ECharts
                      style={{ height: all ? 260 : 220, width: '50%', marginLeft: -20 }}
                      option={options.getPie(distribution.compAgePie.filter(d => d.name && (d.name.includes('2-5') || d.name.includes('5-10') || d.name.includes('10-15') || d.name.includes('15-20'))), '司龄分布',
                        ['#00DBFF', '#605BFF', '#FA7531', '#FAC459', '#FAC459', '#91CC76'], {
                        left: window.screen.width == 1280 || window.screen.width == 1920 || window.screen.width == 1536 ? '18%' : '18%',
                        line: 2,
                        right: window.screen.width == 1280 || window.screen.width == 1920 || window.screen.width == 1536 ? '4%' : '0.5%'
                      })}
                    /> : <div style={{ height: all ? 260 : 220, width: '50%' }} className="tc flex-h-center"><img src={empty}></img></div>

                }
              </div>
              <div className='flex-h-between' style={{ marginTop: all ? all1 ? -40 : -30 : -50 }}>


                {
                  distribution.eduPie && distribution.eduPie.length ?
                    <ECharts
                      style={{ height: all ? 260 : 210, width: '50%' }}
                      option={options.getPie(distribution.eduPie.filter(d => d.name && (d.name.includes('本科') || d.name.includes('硕士') || d.name.includes('博士'))), '学历分布',
                        ['#91cc76', '#353e7c', '#fac459'], {
                        left: window.screen.width == 1280 || window.screen.width == 1920 || window.screen.width == 1536 ? '18%' : '18%',
                        line: 2,
                        right: window.screen.width == 1280 || window.screen.width == 1920 || window.screen.width == 1536 ? '0%' : '3%'
                      })}
                    /> : <div style={{ height: all ? 260 : 220, width: '50%' }} className="tc flex-h-center"><img src={empty}></img></div>

                }
                {
                  distribution.typePie && distribution.typePie.length ?
                    <ECharts
                      style={{ height: all ? 260 : 220, width: '50%', marginLeft: -20 }}
                      option={options.getPie(distribution.typePie, '类型分布', '', {
                        left: window.screen.width == 1280 || window.screen.width == 1920 || window.screen.width == 1536 ? '18%' : '18%',
                        line: 2,
                        right: window.screen.width == 1280 || window.screen.width == 1920 || window.screen.width == 1536 ? '-1%' : '-3.5%'
                      })}
                    /> : <div style={{ height: all ? 260 : 220, width: '50%' }} className="tc flex-h-center"><img src={empty}></img></div>

                }
              </div>
            </div>
          </div>
          <div className='common-h-line' style={{ height: 824, marginLeft: 35, marginRight: 35 }}></div>
          <div className={`home-content-right w${window.screen.width}`}>
            <div className='mt0'>
              <Tit text="员工标签"></Tit>
              <div
                style={{ height: all ? all1 ? 260 : 280 : 230, width: 520, marginTop: 10 }} >
                {
                  tagList.length ? <ECharts
                    style={{ height: all ? all1 ? 260 : 280 : 230, width: 520, }}
                    option={options.getPoint(tagList)}
                  /> : <div style={{ height: all ? all1 ? 260 : 280 : 230, width: '100%', paddingTop: 50 }} className="tc"><img src={empty}></img></div>
                }

              </div>
            </div>
            <div className='mt10 home-content-center-user' style={{ marginTop: 10 }}>
              <Tit text="组织管理"></Tit>
              <div className='flex-h-between' style={{ marginTop: all ? -50 : -70 }}>
                <div>
                  <h3 style={{ marginLeft: -20 }}>职级结构</h3>
                  {
                    gradeList && gradeList.length ?
                      <ECharts
                        style={{ height: all ? 200 : 130, width: 320, marginLeft: -30 }}
                        option={options.getFunnel(gradeList, '职级结构')}
                      /> : <div style={{ height: all ? 200 : 130, width: 320 }} className="tc flex-h-center"><img src={empty}></img></div>

                  }

                </div>
                <div className='common-h-line' style={{ height: 158, marginRight: 30, marginLeft: 20 }}></div>
                <div className='home-content-center-user-set'>
                  <h3 style={{ marginLeft: -60, marginBottom: all ? 0 : -30, marginTop: 30 }}>党员</h3>
                  <div className='flex-h-start'>
                    <img src={dy} style={{ width: 43, height: 43, marginRight: 20 }}></img>
                    <div className='common-text' style={{ marginTop: 30 }}>
                      <div ><span>党员数量</span></div>
                      <div>
                        <b>
                          <CountUp
                            end={cpc.cpcNum}
                            duration={2.75}
                            decimals={0}
                            decimal="."
                            suffix=""
                          />
                        </b>
                        <span>人</span>
                      </div>
                    </div>
                  </div>
                  <div className='home-content-center-user-circle' style={{ marginTop: all ? 0 : -30 }}>
                    <div className='fz14'>党员占比</div>
                    <ECharts
                      style={{ height: 200, width: 200, marginLeft: -30 }}
                      option={options.getGauge(cpc.ratio)}
                      onClickEcharts={this.onClickEcharts}
                    />
                  </div>
                </div>
              </div>
              <div className={`flex-h-between home-content-center-user-barlist ww${window.screen.width}`} style={{ marginTop: all ? -50 : -90, width: 520, marginLeft: 0 }}>
                <div>
                  <h3 className='tc' style={{ marginBottom: all ? 20 : 10 }}>职能属性</h3>
                  {
                    empFunc.funcPie && empFunc.funcPie.length ?
                      <ScrollArea
                        smoothScrolling={true}
                        stopScrollPropagation={true}
                        style={{
                          maxHeight: 200,
                          zIndex: 10,
                          marginLeft: -85,
                          width: 580
                        }}
                      >
                        <ul>
                          {
                            empFunc.funcPie && empFunc.funcPie.map(d => (
                              <li>
                                <span style={{ width: '25%' }} className="lispan">{d.name}</span>
                                <div className='bar lidiv' style={{ width: '50%' }}>
                                  <div className='progress' style={{ width: `${d.percent}%` }}></div>
                                </div>
                                <div className='flex-h-center lilastdiv' style={{ width: '25%' }}><span>{d.value}</span><b>人</b><span>{d.percent}%</span></div>
                              </li>
                            ))
                          }
                        </ul>
                      </ScrollArea> :
                      <div style={{ height: 260, width: 520 }} className="tc flex-h-center"><img src={empty}></img></div>
                  }

                </div>
                {/* <div className='common-h-line' style={{ height: 198, marginLeft: 20, marginRight: 30 }}></div>
                <div>
                  <h3></h3>
                  {
                    empFunc.funcManageSonPie && empFunc.funcManageSonPie.length ?
                      <ECharts
                        style={{ height: 200, width: 250, marginLeft: -25 }}
                        option={options.getPie(empFunc.funcManageSonPie, '职能管理', '', {
                          left: '33%',
                          line: 3
                        })}
                        onClickEcharts={this.onClickEcharts}
                      /> : <div style={{ height: 260, width: 250 }} className="tc flex-h-center"><img src={empty}></img></div>
                  }
                </div> */}
              </div>
            </div>
          </div>
        </div>

      </div >
    );
  }
}

export default Index;