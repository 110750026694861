import React, { Component } from 'react';
import bg from '../../assets/img/bg-full.png';
import earth from '../../assets/img/earth.png';
import logo from '../../assets/logo.png';
import chart from '../../assets/img/home/icon_03.png';
import chart1 from '../../assets/img/home/icon_04.png';
import people from '../../assets/img/home/icon_02.png';

import p from '../../assets/img/home/icon_05.png';
import b from '../../assets/img/home/icon_06.png';
import man from '../../assets/img/home/icon_07.png';
import woman from '../../assets/img/home/icon_08.png';
import empty from '../../assets/empty.png';

import Nav from '../nav';
import Tit from '../components/Tit';
import Tab from '../components/Tab';
import ECharts from '../../components/ECharts';
import Map from '../../components/Map';
import options from './options';
import CountUp from 'react-countup';
import { cqRequest } from '../../tools/http';
import _ from 'lodash';
import './index.scss'
import moment from 'moment';
import { Row, Col, Switch } from 'antd';
let dictionary = [{
  name: '同比增长',
  code: '1'
}, {
  name: '同比下降',
  code: '-1'
}, {
  name: '持平',
  code: '0'
}]
const ProgressChartColors = ['#FAC459', '#00DBFF', '#FA7531', '#BB45EE'];
class Index extends Component {
  constructor() {
    super()
    this.state = {
      data: [],
      level: 'SGC',
      overviewStat: [],
      maleToFemaleRatioStat: {},
      distribution: {},
      overview: {},
      entryDimisStat: {},
      dimissionRatioTrendStat: {},
      statistics: {},
      onJobEmpTerritoryStat: [],
      regionOrgFuncEmpStat: [],
      statisMonth: moment().subtract(1, 'month').format('YYYYMM'),
      dataQualityInfo: '',
      dataQuality: '',
      buHighPerfRatioStat: [],
      buHighPerfDimStat: {},
      ytdDimiRatio: {},
      mtdDimiRatio: {},
      show: false,
    }
  }
  _init = () => {
    this.setState({
      show: false
    })
    const { level, statisMonth } = this.state
    cqRequest(`/common/overviewStat?level=${level}&statisMonth=${statisMonth}`).then((res) => {// 员工概览
      this.setState({
        overviewStat: res.data.length ? res.data[0] : {}
      })
    })

    cqRequest(`/common/maleToFemaleRatioStat?level=${level}&statisMonth=${statisMonth}`).then((res) => {// 男女比例
      this.setState({
        maleToFemaleRatioStat: res.data
      })
    })

    cqRequest(`/common/buHighPerfStat?level=${level}&statisMonth=${statisMonth}`).then((res) => {// 在职员工4饼图（年龄、学历等分布）
      this.setState({
        distribution: res.data
      })
    })

    cqRequest(`/common/buHighPerfRatioStat?level=${level}&statisMonth=${statisMonth}`).then((res) => {// 在职员工4饼图（年龄、学历等分布）
      this.setState({
        buHighPerfRatioStat: res.data
      })
    })
    cqRequest(`/common/buHighPerfDimStat?level=${level}&statisMonth=${statisMonth}`).then((res) => {// 在职员工4饼图（年龄、学历等分布）
      this.setState({
        buHighPerfDimStat: res.data
      })
    })

    cqRequest(`/org/onJobEmpOverviewStat?level=${level}&statisMonth=${statisMonth}`).then((res) => {// 员工概览统计
      this.setState({
        overview: res.data
      })
    })

    cqRequest(`/common/entryDimisStat?level=${level}&statisMonth=${statisMonth}`).then((res) => {// 入职和离职
      this.setState({
        entryDimisStat: res.data
      })
    })

    cqRequest(`/common/dimissionRatioTrendStat?level=${level}&statisMonth=${statisMonth}`).then((res) => {// 离职率趋势
      this.setState({
        dimissionRatioTrendStat: res.data
      })
    })

    cqRequest(`/effect/statistics?level1=${level}&statisMonth=${statisMonth}`).then((res) => {// 统计数据
      this.setState({
        statistics: res.data
      })
    })

    cqRequest(`/org/onJobEmpTerritoryStat?level=${level}&statisMonth=${statisMonth}`).then((res) => {// 统计数据
      this.setState({
        onJobEmpTerritoryStat: res.data
      })
    })

    cqRequest(`/common/dataQualityInfo?level=${level}&pageType=SYNOPSIS_PAGE`).then((res) => {// 感叹号数据
      this.setState({
        dataQualityInfo: res.data
      })
    })

    cqRequest(`/common/dataQualityInfo?level=${level}&pageType=SYNOPSIS_DATA_UPDATE_PAGE`).then((res) => {// 数据质量
      this.setState({
        dataQuality: res.data
      })
    })

    cqRequest(`/common/ytdDimiRatioTrendStat?level=${level}&statisMonth=${statisMonth}`).then((res) => {// 数据质量
      this.setState({
        ytdDimiRatio: res.data,
        show: true
      },)

    })

    cqRequest(`/common/mtdDimiRatioTrendStat?level=${level}&statisMonth=${statisMonth}`).then((res) => {// 数据质量
      this.setState({
        mtdDimiRatio: res.data
      })
    })
  }
  changeSize() {
    const ratio = window.screen.width / 1366;
    const dynamicStyle = {
      width: '1366px',
      transform: `scale(${ratio})`,
      transformOrigin: 'left top',
      height: `${document.body.offsetHeight / ratio}px`,
    };
    this.setState({ dynamicStyle });
  }




  componentDidMount() {
    document.body.style.zoom = 1
    this._init()
    setTimeout(() => {
      // this.toggleFullScreen()
    }, 1000)
    let c = document.querySelector('body')
    var size = window.screen.height / 1080;
    var size2 = 100 / size + '%';
    c.style.transform = `scale(${size})`;
    c.style.transformOrigin = '0 0';
    c.style.width = size2;
    c.style.height = size2;
    let designSize = 1080; // 设计图尺寸
    let html = document.documentElement;
    let wW = html.clientWidth;// 窗口宽度
    let rem = wW * 100 / designSize;
    document.documentElement.style.fontSize = rem + 'px';
    console.log(1111111)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', () => {
      this.changeSize();
    });
  }
  getData = (data) => {
    this.setState({ data })
  }

  render() {
    const {
      overviewStat,
      maleToFemaleRatioStat,
      distribution,
      overview,
      entryDimisStat,
      dimissionRatioTrendStat,
      statistics,
      onJobEmpTerritoryStat,
      buHighPerfRatioStat,
      buHighPerfDimStat,
      ytdDimiRatio,
      mtdDimiRatio
    } = this.state
    let all = document.documentElement.clientHeight == 720 ||
      document.documentElement.clientHeight == 864 ||
      document.documentElement.clientHeight == 1080 ||
      document.documentElement.clientHeight == 818 ||
      document.documentElement.clientHeight == 850 ||
      document.documentElement.clientHeight == 960 ||
      document.documentElement.clientHeight == 610 ||
      (document.documentElement.clientHeight == 578 && document.documentElement.clientWidth != 1280) ||
      document.documentElement.clientHeight == 681 ||

      document.documentElement.clientHeight == 781 ||
      document.documentElement.clientHeight == 823 ||
      document.documentElement.clientHeight == 1042 ||

      document.documentElement.clientHeight == 1010 ||
      document.documentElement.clientHeight == 1152
      ? true : false
    const { dynamicStyle } = this.state;
    return (
      <div>

        {/* <Switch checkedChildren="全屏" unCheckedChildren="非全屏" style={{ position: 'absolute', left: 0, top: 0, zIndex: 999 }} /> */}
        <Nav />
        <Row style={{ position: 'absolute', top: '93px', width: '100%' }}>
          <img src={earth} width={'100%'} height={'960px'} alt="" className='full-bg' style={{ position: 'absolute', bottom: 0 }} />
          <Col span={7} style={{ padding: '0 10px' }}>
            <Row style={{ height: '100%' }}>
              <Col span={23}>

                <Row style={{ height: '100%' }}>
                  <Col span={24} style={{ height: '33%', marginTop: '49px' }}>
                    <Tit text="员工分布"></Tit>
                    <div className='common-box' style={{ boxShadow: 'none' }}>
                      <div className={`home-content-left-statistics mt10`}>
                        <div className='flex-h-between'>
                          <div className='common-text'>
                            <span>{overviewStat.orgName || 'SGC'}在职总数</span>
                            <div> <b><CountUp
                              end={overviewStat.onJobEmpNum || 0}
                              duration={2.75}
                              decimals={0}
                              decimal="."
                              suffix=""
                            /></b>
                              <span>人</span></div>
                          </div>
                          <div className={`common-h-line ml20 mr20`} style={{ height: 50 }}></div>
                          <div className='common-text'>

                            <span>同比增长</span>
                            <div> <b><CountUp
                              end={overviewStat.onJobEmpYoy || 0}
                              duration={2.75}
                              decimals={1}
                              decimal="."
                              suffix=""
                            /></b>
                              <span>%</span></div>
                          </div>
                          <div className={`common-h-line ml20 mr20`} style={{ height: 50 }}></div>
                          <div className='common-text'>
                            <span>环比增长</span>
                            <div> <b><CountUp
                              end={overviewStat.onJobEmpQoq || 0}
                              duration={2.75}
                              decimals={1}
                              decimal="."
                              suffix=""
                            /></b>
                              <span>%</span></div>
                          </div>
                        </div>
                        <div className={`common-v-line  mt10 mb10`}></div>
                        <div className='flex-h-between'>
                          <div className='common-text'>
                            <span>{overviewStat.orgName || 'SGC'}直线经理人数</span>
                            <div> <b><CountUp
                              end={overviewStat.onJobManageNum || 0}
                              duration={2.75}
                              decimals={0}
                              decimal="."
                              suffix=""
                            /></b>
                              <span>人</span></div>
                          </div>
                          <div className={`common-h-line ml20 mr20`} style={{ height: 50 }}></div>
                          <div className='common-text'>
                            <span>同比增长</span>
                            <div> <b><CountUp
                              end={overviewStat.onJobManageYoy || 0}
                              duration={2.75}
                              decimals={1}
                              decimal="."
                              suffix=""
                            /></b>
                              <span>%</span></div>
                          </div>
                          <div className={`common-h-line ml20 mr20`} style={{ height: 50 }}></div>
                          <div className='common-text'>
                            <span>占比</span>
                            <div> <b><CountUp
                              end={overviewStat.onJobManageRatio || 0}
                              duration={2.75}
                              decimals={1}
                              decimal="."
                              suffix=""
                            /></b>
                              <span>%</span></div>
                          </div>
                        </div>
                        <div className={`common-v-line  mt10 mb10`}></div>
                        <div className='flex-h-between'>
                          <div className='common-text'>
                            <span>{overviewStat.orgName || 'SGC'}高绩效人数</span>
                            <div> <b><CountUp
                              end={overviewStat.onJobHighPerfNum || 0}
                              duration={2.75}
                              decimals={0}
                              decimal="."
                              suffix=""
                            /></b>
                              <span>人</span></div>
                          </div>
                          <div className={`common-h-line ml20 mr20 `} style={{ height: 50 }}></div>
                          <div className='common-text'>
                            <span>同比增长</span>
                            <div> <b><CountUp
                              end={overviewStat.onJobHighPerfYoy || 0}
                              duration={2.75}
                              decimals={1}
                              decimal="."
                              suffix=""
                            /></b>
                              <span>%</span></div>
                          </div>
                          <div className={`common-h-line ml20 mr20 `} style={{ height: 50 }}></div>
                          <div className='common-text'>
                            <span>占比</span>
                            <div> <b><CountUp
                              end={overviewStat.onJobHighPerfRatio || 0}
                              duration={2.75}
                              decimals={1}
                              decimal="."
                              suffix=""
                            /></b>
                              <span>%</span></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='common-v-line'></div>
                  </Col>
                  <Col span={24} style={{ height: '31%', marginTop: '10px' }}>
                    <h3 className={`colb  mt10`} style={{ fontSize: '18px' }}>各BU高绩效人数VS总人数</h3>
                    {
                      distribution && distribution.brokenLine && distribution.brokenLine.dimNames.length ?
                        <ECharts
                          style={{ height: 260, width: '100%', marginTop: 15 }}
                          className="OfflineGrowthTrend_charts"
                          option={options.getBar(distribution)}
                        /> : <div style={{ height: 190, width: '100%' }} className="tc flex-h-center"><img src={empty}></img></div>
                    }
                  </Col>
                  <Col span={24} style={{ height: '26%', marginTop: '30px' }}>
                    <h3 className={`colb  mt10}`} style={{ fontSize: '18px' }}>各BU高绩效人数占总高绩效人数比例</h3>
                    {
                      buHighPerfRatioStat.length ?
                        <ul className='flex-h-center circle-list' style={{ flexWrap: 'wrap' }}>
                          {
                            buHighPerfRatioStat.map(d => (
                              <li className={all ? 'hight' : ''} style={{ width: buHighPerfRatioStat.length == 4 ? '25%' : '33.33%' }}>
                                <ECharts
                                  className="progress_charts"
                                  style={{ width: 100, height: 110 }}
                                  option={options.getPieFour1((d.value) || 0, '#00DBFF', '', d.name)}
                                />
                              </li>
                            ))
                          }

                        </ul> : <div style={{ height: 210, width: '100%' }} className="tc flex-h-center"><img src={empty}></img></div>
                    }
                  </Col>

                </Row>
              </Col>
              <Col span={1}>
                <div className='common-h-line' style={{ height: 824, marginLeft: 5 }}></div>
              </Col>
            </Row>

          </Col>
          {/* <Col span={1}> */}

          {/* </Col> */}
          <Col span={10}>
            <Row style={{ height: '100%' }}>
              <Col span={24}>
                <div style={{ zIndex: 999 }}>

                  <div className='flex-h-between' style={{ position: 'relative', top: 60, marginBottom: -30, zIndex: 999 }}>
                    <Tab dataQualityInfo={this.state.dataQualityInfo} onChange={(e) => {
                      this.setState({
                        level: e,
                        onJobEmpTerritoryStat: []
                      }, () => {
                        this._init()
                      })
                    }} onChangeDate={(e) => {
                      this.setState({
                        statisMonth: e,
                        onJobEmpTerritoryStat: []
                      }, () => {
                        this._init()
                      })
                    }}></Tab>
                  </div>

                  {/* 地图 */}
                  <div >
                    {
                      onJobEmpTerritoryStat.length ? <Map top={'10%'} type={3} statisMonth={this.state.statisMonth}
                        level={this.state.level} datas={onJobEmpTerritoryStat}></Map> :
                        <div style={{ height: 780, width: '100%' }} className="tc  flex-h-center"><img src={empty}></img></div>
                    }
                  </div>

                  <div style={{ position: 'absolute', bottom: 0, left: 0, width: '100%', height: 60, zIndex: 9999, }}>
                    <img src={require('../../assets/scocal.svg')} height="60" width={'100%'} style={{ position: 'absolute', top: 0, left: 0 }} />
                    <div dangerouslySetInnerHTML={{ __html: this.state.dataQuality }} style={{ fontSize: '18px', color: '#00DBFF', height: 60, display: 'flex', justifyContent: 'center', alignItems: 'center' }}></div>
                    {/* <div dangerouslySetInnerHTML={{ __html: this.state.dataQuality }} style={{ fontSize: 13, color: '#00DBFF', height: 60, textAlign:'center' ,backgroundColor:'red'}}></div> */}
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          {/* <Col span={1}> */}

          {/* </Col> */}
          <Col span={7} style={{ marginTop: '49px' }}>
            <Row style={{ height: '100%' }}>
              <Col span={1}>
                <div className='common-h-line' style={{ height: 824 }}></div>
              </Col>
              <Col span={23}>
                <Row style={{ height: '100%' }}>
                  <Col span={24} style={{ height: '33%' }}>
                    <Tit text="MTD离职率"></Tit>
                    <div style={{ marginTop: '10px' }}>
                      {
                        mtdDimiRatio.dimNames && mtdDimiRatio.dimNames.length ?
                          <ECharts
                            style={{ height: 280, width: '100%', }}
                            className="OfflineGrowthTrend_charts"
                            option={options.getLineChart(mtdDimiRatio, 2, 99)}
                          /> : <div style={{ minHeight: 190, width: '100%' }} className="tc flex-h-center"><img src={empty}></img></div>
                      }
                    </div>
                  </Col>
                  <Col span={24} style={{ height: '30%', marginTop: 20 }}>
                    <Tit text="YTD离职率"></Tit>
                    <div className='mt10'>
                      {
                        ytdDimiRatio.areaBrokenLine && this.state.show ?
                          <ECharts
                            style={{ height: 250, width: '100%', marginTop: 15 }}
                            className="OfflineGrowthTrend_charts"
                            option={options.getBars(ytdDimiRatio, [...ytdDimiRatio.areaBrokenLine.categoryNames, ...ytdDimiRatio.brokenLine.categoryNames])}
                          />
                          : <div style={{ minHeight: 240, width: '100%' }} className="tc flex-h-center"><img src={empty}></img></div>
                      }
                    </div>
                  </Col>
                  <Col span={24} style={{ height: '30%', marginTop: 10 }}>
                    <Tit text="各BU高绩效离职人员占总离职人员比例（YTD）"></Tit>
                    {
                      buHighPerfDimStat && buHighPerfDimStat.brokenLine && buHighPerfDimStat.brokenLine.dimNames.length ?
                        <ECharts
                          style={{ height: 250, width: '100%', marginTop: 10 }}
                          className="OfflineGrowthTrend_charts"
                          option={options.getBar(buHighPerfDimStat)}
                        /> : <div style={{ minHeight: 190, width: '100%' }} className="tc flex-h-center"><img src={empty}></img></div>
                    }
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </div >
    );
  }
}

export default Index;