import * as echarts from 'echarts'
import _ from 'lodash';
const options = {
    getPie(data, name = '占比', cc, config) {
        let color = ['#00DBFF', '#EE6666', '#353e7c', '#605BFF', '#C93ACA', '#91CC76', '#FAC459', '#FA7531', '#344791',]
        let datas = []
        if (config && config.legend) {
            if (cc == 1) {
                datas = [
                    { name: '本科', value: data.filter(d => d.name == '本科')[0].value },
                    { name: '硕士', value: data.filter(d => d.name == '硕士')[0].value },
                    { name: '博士', value: data.filter(d => d.name == '博士')[0].value, },
                    ...data.filter(d =>
                        d.name != '本科' &&
                        d.name != '硕士' &&
                        d.name != '博士')
                ]
            }
            if (cc == 2) {
                datas = [
                    { name: '90后', value: data.filter(d => d.name == '90后').length && data.filter(d => d.name == '90后')[0].value },
                    { name: '80后', value: data.filter(d => d.name == '80后').length && data.filter(d => d.name == '80后')[0].value },
                    { name: '70后', value: data.filter(d => d.name == '70后').length && data.filter(d => d.name == '70后')[0].value },
                    ...data.filter(d =>
                        d.name != '90后' &&
                        d.name != '80后' &&
                        d.name != '70后')
                ]
            }
        }
        return {
            width: config && config.legend ? '75%' : '80%',
            height: config && config.legend ? '75%' : '80%',
            legend: {
                data: config && config.legend ? datas.filter(d =>
                    d.name == '本科' ||
                    d.name == '硕士' ||
                    d.name == '博士' ||
                    d.name == '90后' ||
                    d.name == '80后' ||
                    d.name == '70后'
                ) : data.map(d => d.name),
                show: true,
                itemWidth: 15,
                itemHeight: 12,
                textStyle: {
                    color: '#FFF',
                    fontSize: 16,
                    borderWidth: 0
                },
                itemGap: config && config.legend ? 10 : 13,
                right: config && config.right ? config.right : '0',
                top: config && config.legend ? '76%' : '25%',
                orient: 'vertical',
                formatter: (name) => {
                    let total = 0;
                    let tarValue, percent = 0
                    for (let i = 0; i < data.length; i++) {
                        total += data[i].value;
                        if (data[i].name == name) {
                            tarValue = data[i].value;
                            percent = data[i].percent;
                        }
                    }
                    return `${name.length > 3 && config && !config.title ? `${name.substring(0, 5)}..` : name}   ${config && config.legend ? config && config.title ? tarValue.toFixed(1) : tarValue || 0 : config && config.title ? tarValue.toFixed(1) : tarValue || 0}${config && config.title ? '万' : '人'} ${config && config.legend ? percent.toFixed(1) + '%' : ''}`;
                },
            },
            title: [
                {
                    text: (config && config.title) ? config.title : name,
                    top: (config && config.title) ? '41%' : (config && config.legend) ? '44%' : '46%',
                    left: (config && config.left) || '40%',
                    subtext: (config && config.title) ? '万' : '',
                    textAlign: (config && config.title) ? 'center' : 'auto',
                    subtextStyle: {
                        fontSize: 18,
                        color: '#FEFEFE',
                        align: 'right',
                    },
                    textStyle: {
                        fontSize: (config && config.title) ? 24 : 18,
                        color: '#00DBFF',
                    }
                }
            ],
            tooltip: {
                trigger: 'item',
                formatter: function (params) {
                    let res = `<div class="popTooltip flex-h-start">${params.seriesName}<br/>${params.name} : ${params.value} (${params.percent.toFixed(1)}%)</div>`;
                    return res;
                },
                borderWidth: 0,
                position: (config && config.tooltip) ? config.tooltip : 'right',
                borderColor: 'transparent',
                backgroundColor: 'transparent',
                extraCssText: 'box-shadow: 0px 0px 8px 0px rgba(81, 85, 251, 0);',
            },
            color: cc && cc != 1 && cc != 2 ? cc : color,
            // calculable: true,
            series: [
                {
                    name,
                    type: 'pie',
                    radius: ['55%', '70%'],
                    avoidLabelOverlap: false,
                    left: '-10%',
                    top: '10%',
                    bottom: '0',
                    right: 0,
                    itemStyle: {
                        borderRadius: 0,
                        borderColor: '#18105d',
                        borderWidth: 1
                    },
                    label: {
                        normal: {
                            show: false,
                            position: [-0, 0],
                            formatter: function (params) {
                                let parseName = params.name ? [params.name] : [];
                                if (params.name.length > 6) {
                                    parseName = params.name ? [params.name.substring(0, params.name.length / 2), params.name.substring(params.name.length / 2, params.name.length)] : [];
                                }
                                return [...parseName.map(nm => `{b|${nm}}`), `{d|${parseInt(params.value)}人}${config && config.line == 3 ? '\n' : ' '}{d|${parseInt(params.percent)}%}`].join('\n');
                            },
                            rich: {
                                d: {
                                    fontSize: 16,
                                    color: '#fff',
                                    lineHeight: 20
                                },
                                b: {
                                    color: '#fff',
                                    fontSize: 16,
                                    lineHeight: 20
                                },

                            },
                            textStyle: {
                                fontSize: 16
                            }
                        },
                        emphasis: {
                            show: false,
                            textStyle: {
                                fontSize: 16,
                            },
                        }
                    },
                    emphasis: {
                        label: {
                            show: false,
                            fontSize: '16',
                            fontWeight: 'bold'
                        }
                    },
                    labelLine: {

                        normal: {
                            length: 15,
                            length2: 15,
                            show: (config && config.label) ? true : false,
                        },
                    },
                    data: config && config.legend ? datas : data,
                },
            ],
        };
    },
    getGauge(value, name, color) {
        return {
            series: [
                {
                    type: 'gauge',
                    startAngle: 180,
                    endAngle: 0,
                    min: 0,
                    zlevel: 1,
                    max: 1,
                    splitNumber: 14,
                    progress: {
                        show: false
                    },
                    axisLine: {
                        lineStyle: {
                            width: 0,
                            opacity: 0,
                            color: [
                                [value / 100, color || '#91cc76'],
                                [1, '#191e68']
                            ]
                        }
                    },
                    pointer: {
                        show: false
                    },
                    axisTick: {
                        length: 12,
                        lineStyle: {
                            color: 'auto',
                            width: 2
                        }
                    },
                    splitLine: {
                        show: false
                    },
                    axisLabel: {
                        show: false
                    },
                    title: {
                        show: name ? true : false,
                        offsetCenter: ['0%', '-0%'],
                        fontSize: 16,
                        color: '#fff'
                    },
                    detail: {
                        fontSize: 18,
                        offsetCenter: name ? [0, -25] : [0, 0],
                        valueAnimation: true,
                        formatter: function (value) {
                            return parseFloat(Math.round(value * 100)).toFixed(1) + '%';
                        },
                        color: 'auto'
                    },
                    data: [
                        {
                            value: value / 100,
                            name: name
                        }
                    ]
                }
            ]
        }
    },
    getFunnel(data, name) {
        return {
            tooltip: {
                trigger: 'item',
                formatter: function (params) {
                    let res = `<div class="popTooltip flex-h-start">${params.seriesName}<br/>${params.name} : ${params.value} (${params.percent}%)</div>`;
                    return res;
                },
                borderWidth: 0,
                borderColor: 'transparent',
                backgroundColor: 'transparent',
                extraCssText: 'box-shadow: 0px 0px 8px 0px rgba(81, 85, 251, 0);',
            },
            color: ['#FA7531', '#605BFF', '#91CC76', '#f27132', '00dbff'],
            calculable: true,
            series: [
                {
                    name: '职级结构',
                    type: 'funnel',
                    left: '12%',
                    top: 0,
                    bottom: 0,
                    width: '80%',
                    height: '90%',
                    //   min: 0,
                    //   max:300,
                    minSize: '15%',
                    maxSize: '70%',
                    sort: 'ascending',
                    gap: 2,
                    funnelAlign: 'center',
                    label: {
                        show: true,
                        position: 'right',
                        formatter: function (params) {
                            return `${params.value} ${parseFloat(params.percent).toFixed(1)}%`
                        },
                        color: '#fff'
                    },
                    labelLine: {
                        length: 10,
                        show: false,
                        lineStyle: {
                            width: 1,
                            type: 'solid'
                        }
                    },
                    itemStyle: {
                        borderColor: '#1c0b58',
                        borderWidth: 2
                    },
                    emphasis: {
                        label: {
                            fontSize: 20
                        }
                    },
                    data: data
                },
                {
                    name: '职级结构',
                    type: 'funnel',
                    left: '12%',
                    top: 0,
                    bottom: 0,
                    width: '80%',
                    height: '90%',
                    minSize: '15%',
                    maxSize: '70%',
                    sort: 'ascending',
                    gap: 2,
                    funnelAlign: 'center',
                    label: {
                        show: true,
                        position: 'left',
                        formatter: '{b}',
                        color: '#fff'
                    },
                    labelLine: {
                        length: 10,
                        show: false,
                        lineStyle: {
                            width: 1,
                            type: 'solid'
                        }
                    },
                    itemStyle: {
                        borderColor: '#1c0b58',
                        borderWidth: 2
                    },
                    emphasis: {
                        label: {
                            fontSize: 20
                        }
                    },
                    data: data
                }
            ]
        };
    },
    getSliceData(list = [], inx = 0) {
        return list?.slice(inx * 8, (inx + 1) * 8)
    },
    getBarCharts1(data = []) {
        return {
            grid: {
                top: '20%',
                right: '0%',
                left: '-0%',
                bottom: '9%',
            },

            color: ['#00DBFF', '#0d70a9'],
            legend: {
                right: '0%',
                show: true,
                itemGap: 20,
                textStyle: {
                    color: '#FFF',
                    fontSize: 16,
                },
            },

            tooltip: {
                show: false,
                trigger: 'item',
                formatter: function (params) {
                    let res = `<div class="popTooltip flex-h-start">${params.seriesName}<br/>${params.name} : ${params.value} </div>`;
                    return res;
                },
                borderWidth: 0,
                borderColor: 'transparent',
                backgroundColor: 'transparent',
                extraCssText: 'box-shadow: 0px 0px 8px 0px rgba(81, 85, 251, 0);',
            },
            xAxis: {
                data: data.dimNames,
                name: '',
                // axisLine: { onZero: false },
                splitLine: { show: false },
                splitArea: { show: false },
                axisTick: {
                    show: false
                },
                axisLine: {
                    lineStyle: {
                        color: '#1d0756',
                    },
                },
                axisLabel: {
                    margin: 10,
                    color: '#e2e9ff',
                    textStyle: {
                        fontSize: 16,
                    },
                    interval: 0,
                    rotate: -25,
                },
            },
            yAxis: {
                show: false,
            },
            series: [
                {
                    name: data.categoryNames[0],
                    type: 'bar',
                    stack: 'Total',
                    label: {
                        show: true,
                        position: 'top',
                        textStyle: { //数值样式
                            color: '#fff',
                            fontSize: 16
                        }
                    },
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 0,

                        }
                    },
                    barWidth: 16,

                    itemStyle: {
                        // 柱形图圆角，鼠标移上去效果，如果只是一个数字则说明四个参数全部设置为那么多
                        normal: {
                            // 柱形图圆角，初始化效果
                            barBorderRadius: [6, 6, 0, 0]
                        }
                    },
                    data: data.categoryValues[0]
                },
                {
                    name: data.categoryNames[1],
                    type: 'bar',
                    stack: 'Total',
                    barWidth: 16,
                    itemStyle: {
                        // 柱形图圆角，鼠标移上去效果，如果只是一个数字则说明四个参数全部设置为那么多
                        normal: {
                            // 柱形图圆角，初始化效果
                            barBorderRadius: [0, 0, 6, 6]
                        }
                    },
                    label: {
                        show: true,
                        position: 'bottom',
                        textStyle: { //数值样式
                            color: '#fff',
                            fontSize: 16
                        }
                    },
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 0,
                        }
                    },
                    data: data.categoryValues[1]
                },
            ]
        }
    },
    getBarCharts2(data, level) {
        let legend = [], datas = []
        data.categoryNames.forEach((d, index) => {
            if (d == 'SGC') {
                legend.push({
                    data: [{
                        name: 'SGC',
                    }],
                    right: 330,
                    width: 14,
                    height: 10,
                    itemWidth: 14,
                    itemHeight: 10,
                    icon: `image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAADWklEQVQ4T3WUSW8dRRSFz63u6n49+Hl8xEEC2UkWgZANCFZkE/4AfwCxRuIvICGxz18AsYIFihAoURIrASHhkNgScnBix/PDOIPA8RudHqouut394meGkmrRi/50zj3nFr3c+PDz7NXpt8zM9KxpTEQAEdgCjONHvmlwCUzKqk53393+41e9tnNTZfQDTV34dC6fOXnGNOon4Hs1WAKswP5BewEjQBUwJjYd1epuOM1Hi/7iyl2qf/Tlk/T8mREOa77T6SlkpoRZ+ZuPKyyUEeAosNw4ykGq7T7c2h354rt1Ci792AexVp2+4zxrEyUZKMsBI+oqhYUqUaRKkOuAtQuOQ86nJxLV7beDqz//Sd43q1Yvb5De2oOz3wIdPgelOQqFxpR2BeZUsAKkwTUPHNWQnjvFNgqMf2c5Jf3tOvvzS9Dru3DavRKWZEBuQAITy3KU2HNKVZ7AfNgoQPbaDOx4bN3VHSb9/SYHN+9Crzahen1QfwDLQWK1CGMAE4su2PdKWFzCzOQo3OZjlLAbv8Bb2QZ1+1D9BJSmQJaD8iFlxeBFmcA0bFgDxwHS10/BNMaOYOH129APtqG6PVBPZlaFILBB5wYwGbyvwWGtUJaeOw3TGIf7e6UsvDYP/WALqlPaVM9TIJdERVnVNyqThHYKmzbwpRpI3jgN89IQLLh+u7TZEZsys7SqxzCs6tewsiiUNEtlu0+qmc3dgV4Rm4dVmgkoM+XMik1ggKqiVjMTmxzJzGZhpoaU1W4twFtrFjCIsrRUVqQ56JmsrAQgNqUaYlOqcXYWZrIOt/kIpC+vGX/xPumNXVIHHdChpHm0BVTABsoGMLcsbRwiPTvLth5ab3nTkPfVcuLu7Dl6a89xnu4fwcRi0bMqACmtrFNRWhdWYCOx2DTs6bR2a+GQos+ubvJIOIYkjVWr51KakTT/WGFlA4rdJPBgP7UrG5DxaNxRz9pPgys/PabJ9y9d6198e8ZMjU9TlsVkjKL/ejEqYKGzXHprA6+nWr09797D1ejrG/epceGTj/NXTrzDUfQmK5oBc0iW6d+vY/VWShBElhQlbO02pfmCs38w780v/UYnGx+8y75+zzYmLtrR+nkojBEL7P8PK5KYu+qge0+1enP0V2cuIbv0N14n8wT8aqxKAAAAAElFTkSuQmCC`,
                    textStyle: {
                        color: '#FFF',
                        fontSize: 16,
                    },
                })
            } else {
                legend.push({
                    data: [{
                        name: d,
                        icon: "rect"
                    }],
                    right: level == 'SGC' ? 330 - index * 60 : 0,
                    itemWidth: 14,
                    itemHeight: 10,
                    textStyle: {
                        color: '#FFF',
                        fontSize: 16,
                    },
                })
            }
        })
        data.categoryValues.forEach((d, index) => {
            if (level == 'SGC') {
                if (index != 0) {
                    datas.push({
                        name: data.categoryNames[index],
                        type: 'bar',
                        data: d,
                        barWidth: '16px',
                        zlevel: 1,
                        itemStyle: {
                            normal: {
                                barBorderRadius: [2, 2, 0, 0]
                            }
                        },
                    })
                }
            } else {
                datas.push({
                    name: data.categoryNames[index],
                    type: 'bar',
                    data: d,
                    barWidth: '16px',
                    zlevel: 1,
                    itemStyle: {
                        normal: {
                            barBorderRadius: [2, 2, 0, 0]
                        }
                    },
                })
            }
        })

        return {
            color: ['#00DBFF', '#91CC76', '#605BFF', '#F9B52E', '#C93ACA', '#00DBFF'],
            legend: legend,
            tooltip: {
                trigger: 'item',
                formatter: function (params) {
                    let res = ''
                    if (level != 'SGC') {
                        res = `<div class="popTooltip flex-h-start common-text"><ul>${data.categoryNames.filter(d => d == level).map((d, index) => `<li><div>${d}</div><span>${data.categoryValues[index][params.dataIndex]}</span></li>`)}</ul></div>`;
                    } else {
                        res = `<div class="popTooltip flex-h-start common-text"><ul>${data.categoryNames.map((d, index) => `<li><div>${d}</div><span>${data.categoryValues[index][params.dataIndex]}</span></li>`)}</ul></div>`;
                    }
                    return res.replace(/,/g, '')
                },
                borderWidth: 0,
                borderColor: 'transparent',
                backgroundColor: 'transparent',
                extraCssText: 'box-shadow: 0px 0px 8px 0px rgba(81, 85, 251, 0);',
            },
            xAxis: {
                data: data.dimNames,
                axisLine: {
                    lineStyle: {
                        color: 'rgba(255,255,255,.5)',
                    },
                },
                axisLabel: {
                    margin: 10,
                    color: '#e2e9ff',
                    textStyle: {
                        fontSize: 16,
                    },
                    interval: 0,
                    rotate: -25,
                },
                axisTick: {
                    show: false,
                },
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: 'rgba(255,255,255,0.12)',
                    },
                },
            },
            yAxis: {
                name: '万元/人',

                axisLabel: {
                    formatter: '{value}',
                    color: '#e2e9ff',
                    fontSize: 16,
                },
                // min: 0, // 刻度最小值
                // max: 100, // 刻度最大值（需要动态获取最大值,并且能被3整除（向下取整再乘回来））
                // interval: 33, // 刻度间隔
                nameTextStyle: {
                    color: '#fff',
                    fontSize: 16,
                    fontFamily: "'Microsoft Yahei', sans-serif",
                    padding: [0, 35, 0, 0],
                },

                axisLine: {
                    show: false,
                    lineStyle: {
                        color: 'rgba(0,186,255,.6)',
                    },
                },
                axisTick: {
                    show: false,
                },
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#686681',
                        type: 'dashed' //设置网格线类型 dotted：虚线 solid:实线
                    },
                },
            },
            series: [
                {
                    name: 'SGC',
                    type: 'pictorialBar',
                    data: data.categoryValues[0],
                    barWidth: `${14 * data.categoryNames.length}px`,
                    zlevel: 0,
                    // symbol: `image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAAtUlEQVQ4T82UzQ6CQAyEpwE9k8jVm/ou+ma8onrzqgl3d1NT00JVIIIQ3NP+5ct0OruUHwpOTxck1xJ0j6gGAWBd+bld0D1eJIirDGG3Bv0xbF9weh6rzDZY7R7Q5Jmev3rmYSHWpntYx3wa2Fa6KcokGrcS9Iuy0WB5hrCxnB1VmQ9tH88GwRq6ykt9Ab2UCUiGPTFbzg9r8fD7MjuSb+xnaKsG+Jy9d3MWmPxnH6EdqmwK2ANkTOgZhYw9MAAAAABJRU5ErkJggg==`,
                    symbol: `image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAABCklEQVQ4T+2UTUoDQRBGX/dMDxnzM4aAG4/gRoJLQW8jcektcgzjzjOIMDvBuPEOokgwqCDjZLpKekxQyMasx1pVf939KKr6a7M1vtL44RnzUWC8sBZmqej6FgbUWjRtUe3uYLpnl9q6ucfO3zGLClaXAiTkK1hg/V4vz2kSI/0unwd7mN7oQtN8ip29Ycpys8oCP3HIIKM43MdkJ+eaXt9iZ6/YUNmGIS5CBtsUR8PGwPqnE+3kd8jjC4lKPTDxShRbqkqI4zB/6ty5oCk2+tHiTkKZ9RrVs/939idjNdJOo4m28yn6NMepgCoi1JbxldZ2UgXvg7Us3ivGfv+YvhKitmORZRTHQ74AGIv8oSpDWDAAAAAASUVORK5CYII=`,
                    symbolSize: [14 * data.categoryNames.length, '100%'],
                    symbolPosition: 'start',
                    symbolRepeat: false,
                    smooth: true,
                    itemStyle: {
                        normal: {
                            barBorderRadius: [2, 2, 0, 0],
                        }
                    },
                },
                ...datas
            ]
        }
    },
    getBarCharts(x = [], data = [], legend, config) {
        let datas = [], arr = x.filter((d, index) => index < 6)
        data.forEach((d, index) => {
            datas.push({
                name: d.name,
                type: 'bar',
                data: d.value,
                barWidth: '11px',
                itemStyle: {
                    normal: {
                        barBorderRadius: [2, 2, 0, 0]
                    }
                },
            })
        })
        let obj = config && config.filterList ? {
            selected: {
                '平均年龄': config && config.filterList && config.filterList.includes('平均年龄') ? true : false,
                '平均司龄': config && config.filterList && config.filterList.includes('平均司龄') ? true : false,
                '平均工龄': config && config.filterList && config.filterList.includes('平均工龄') ? true : false
            }
        } : {}
        return {
            color: config && config.color || ['#00DBFF', '#91CC76', '#FAC459', '#F9B52E', '#C93ACA', '#C93ACA'],
            legend:
            {
                data: data.map(d => d.name),
                top: '0%',
                right: '8%',
                itemWidth: 10,
                itemHeight: 10,
                icon: 'rect',
                textStyle: {
                    color: '#FFF',
                    fontSize: 16,
                },
                itemGap: 25,
                show: legend ? true : false,
                ...obj
            },
            tooltip: {
                trigger: 'item',
                formatter: function (params) {
                    let res = `<div class="popTooltip flex-h-start common-text"><ul>${data.map((d, index) => `<li><div>${d.name}</div><span>${d.value[params.dataIndex]}</span> 天</li>`)}</ul></div>`;
                    return res.replace(/,/g, '')
                },
                borderWidth: 0,
                borderColor: 'transparent',
                backgroundColor: 'transparent',
                extraCssText: 'box-shadow: 0px 0px 8px 0px rgba(81, 85, 251, 0);',
            },
            xAxis: {
                data: arr,
                axisLine: {
                    lineStyle: {
                        color: 'rgba(255,255,255,.5)',
                    },
                },
                axisLabel: {
                    margin: 10,
                    color: '#e2e9ff',
                    textStyle: {
                        fontSize: 16,
                    },
                    interval: 0,
                    rotate: config && config.rotate != undefined ? config.rotate : -25,
                },
                axisTick: {
                    show: false,
                },
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: 'rgba(255,255,255,0.12)',
                    },
                },
            },
            yAxis: {
                name: (config && config.y) || '',
                axisLabel: {
                    formatter: '{value}',
                    color: '#e2e9ff',
                    fontSize: 16,
                },
                min: 0, // 刻度最小值
                // max: 100, // 刻度最大值（需要动态获取最大值,并且能被3整除（向下取整再乘回来））
                // interval: 33, // 刻度间隔
                nameTextStyle: {
                    color: '#fff',
                    fontSize: 16,
                    fontFamily: "'Microsoft Yahei', sans-serif",
                    padding: [0, 35, 0, 0],
                },

                axisLine: {
                    show: false,
                    lineStyle: {
                        color: 'rgba(0,186,255,.6)',
                    },
                },
                axisTick: {
                    show: false,
                },
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#686681',
                        type: 'dashed' //设置网格线类型 dotted：虚线 solid:实线
                    },
                },
            },
            series: datas
        }
    },
    getBarChartsAll(data = []) {
        let xData = [];
        let datas = [[], []];
        data?.length && data[0] && (data[0]?.value || []).forEach((element) => {
            xData.push(element.name);
            datas[0].push(element.value);
        });
        data?.length && data[1] && (data[1]?.value || []).forEach((element) => {
            datas[1].push(element.value);
        });
        let dataFinal = datas
        datas = datas.map(ds => _.sumBy(ds) || 100);
        return {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow',
                },
                formatter: function (params) {
                    return params[0].name + '<br />' + params[0].seriesName + '占比：' + ((params[0].value / datas[params[0].seriesIndex]) * 100).toFixed(2) + '%' + (params[1] ? ('<br />' + '<br />' + params[1].seriesName + '占比：' + ((params[1].value / datas[params[1].seriesIndex]) * 100).toFixed(2) + '%') : '');
                },
                position: (point) => point && point.length ? point.map((p, i) => p + (i == 0 ? 20 : -70)) : null,
                textStyle: {
                    color: '#FFF',
                    fontSize: 16,
                },
                borderWidth: 1.7,
                borderColor: '#51B9FB',
                backgroundColor: '#00333ecc',
                extraCssText: 'box-shadow: 0px 0px 8px 0px rgba(81, 85, 251, 1);',
            },
            grid: {
                top: '30%',
                right: '11%',
                left: '11%',
                bottom: '14%',
            },
            legend: {
                data: data.map(d => d.name),
                top: '16%',
                right: '10%',
                itemWidth: 19,
                itemHeight: 19,
                icon: 'rect',
                textStyle: {
                    color: '#FFF',
                    fontSize: 16,
                },
                itemGap: 50
            },
            xAxis: [
                {
                    type: 'category',
                    data: xData,
                    axisLine: {
                        lineStyle: {
                            color: 'rgba(0,186,255,.6)',
                        },
                    },
                    axisLabel: {
                        margin: 10,
                        color: '#e2e9ff',
                        textStyle: {
                            fontSize: 16,
                        },
                        interval: 0,
                    },
                    axisTick: {
                        show: false,
                    },
                    splitLine: {
                        show: false,
                        lineStyle: {
                            color: 'rgba(255,255,255,0.12)',
                        },
                    },
                },
            ],
            yAxis: [
                {
                    axisLabel: {
                        formatter: '{value}',
                        color: '#e2e9ff',
                        fontSize: 16
                    },
                    name: '(个)',
                    min: 0,
                    max: Math.round(Math.max(...dataFinal[0])) + 20,
                    minInterval: 1,
                    nameTextStyle: {
                        color: '#fff',
                        fontSize: 16,
                        fontFamily: "'Microsoft Yahei', sans-serif",
                        padding: [0, 35, 0, 0],
                    },
                    axisLine: {
                        show: false,
                        lineStyle: {
                            color: 'rgba(0,186,255,.6)',
                        },
                    },
                    axisTick: {
                        show: false,
                    },
                    splitLine: {
                        show: false,
                        lineStyle: {
                            color: 'rgba(255,255,255,0.12)',
                        },
                    },
                },
                {
                    position: 'right',
                    axisLabel: {
                        formatter: '{value}',
                        color: '#e2e9ff',
                        fontSize: 16
                    },
                    name: '(亿)',
                    min: 0,
                    max: Math.round(Math.max(...dataFinal[1])) + 100,
                    nameTextStyle: {
                        color: '#fff',
                        fontSize: 16,
                        fontFamily: "'Microsoft Yahei', sans-serif",
                        padding: [0, 0, 0, 35],
                    },
                    axisLine: {
                        show: false,
                        lineStyle: {
                            color: 'rgba(0,186,255,.6)',
                        },
                    },
                    axisTick: {
                        show: false,
                    },
                    splitLine: {
                        show: false,
                        lineStyle: {
                            color: 'rgba(255,255,255,0.12)',
                        },
                    },
                }
            ],
            series: [
                {
                    name: data[0] ? data[0].name : '项目总数量',
                    type: 'bar',
                    data: data[0] ? data[0].value : [],
                    yAxisIndex: 0,
                    cursor: 'auto',
                    barWidth: '28px',
                    itemStyle: {
                        normal: {
                            color: new echarts.graphic.LinearGradient(
                                0,
                                0,
                                0,
                                1,
                                [
                                    {
                                        offset: 0,
                                        color: '#1897FF', // 0% 处的颜色
                                    },
                                    {
                                        offset: 1,
                                        color: '#0035AC', // 100% 处的颜色
                                    },
                                ],
                                false
                            ),
                            // shadowColor: 'rgba(0,160,141,1)',
                            // shadowBlur: 4,
                            label: {
                                show: true, //开启显示
                                position: 'top', //在上方显示
                                textStyle: { //数值样式
                                    color: '#fff',
                                    fontSize: 16
                                }
                            }
                        },
                    },
                },
                {
                    name: data[1] ? data[1].name : '项目总投资',
                    type: 'line',
                    data: data[1] ? data[1].value : [],
                    yAxisIndex: 1,
                    symbol: 'circle',
                    symbolSize: 8,

                    itemStyle: {
                        normal: {
                            color: '#FFDD39',
                            lineStyle: {
                                color: '#FFDD39',
                                width: 3,
                            },
                        },
                    },
                },
            ],
        };
    },
    getLineChart(data, name, type, num) {
        //console.log(6666,window.level)
        let datas = [], legs = {}, arr = ['SGC', 'CS', 'CN', 'CP', 'MAP']
        for (let i in data.categoryValues) {
            if (!arr.includes(name)) {
                if (i < 3) {
                    legs = { ...legs, [`${data.categoryNames[i]}`]: true }
                } else {
                    legs = { ...legs, [`${data.categoryNames[i]}`]: false }
                }
                if (i < 6) {
                    datas.push({
                        name: data.categoryNames[i],
                        values: data.categoryValues[i]
                    })
                }
            } else {
                datas.push({
                    name: name,
                    values: name == 'SGC' ? data.categoryValues[0] : name == 'CS' ? data.categoryValues[1] :
                        name == 'CN' ? data.categoryValues[2] : name == 'CP' ? data.categoryValues[3] : name == 'MAP' ? data.categoryValues[4] : []
                })
            }

        }
        let colors = arr.includes(name) ? name == 'SGC' ? ['#00DBFF'] : name == 'CS' ? ['#91CC76'] :
            name == 'CN' ? ['#605BFF'] : name == 'CP' ? ['#F9B52E'] : name == 'MAP' ? ['#C93ACA'] : [] :
            ['#00DBFF', '#91CC76', '#605BFF', '#F9B52E', '#C93ACA', '#7F7F7F', '#7F7F7F']
        let obj = arr.includes(name) ? { max: Math.max(...datas[0].values), min: Math.min(...datas[0].values) } : {}
        return {
            grid: {
                left: '0%',
                right: '2%',
                top: '20%',
                bottom: '0%',
                containLabel: true,
            },
            title: [
                {
                    text: name && name != 1 && name != 2 && !arr.includes(name) ? name : '',
                    top: '0%',
                    left: '0%',
                    textStyle: { fontSize: 16, color: '#00DBFF' }
                }
            ],
            color: colors,
            tooltip: {
                show: true,
                trigger: 'axis',
                formatter: function (params) {
                    console.log(22, params)
                    let res = ''
                    if (arr.includes(name)) {
                        let obj = params.filter(d => d.seriesName == name)[0]
                        res = `<div class="popTooltip flex-h-start"><ul><li><div style='margin-right:5px'>${obj.name}</div> <span>${obj.value.toFixed(1)}</span></li></ul></div>`;
                        return res.replace(/,/g, '') || ' ';
                    } else {
                        if (window.location.pathname.includes('/p5')) {
                            if (true) {
                                res = `<div class="popTooltip flex-h-start"><ul>${params.map(d => `<li><div style='margin-right:5px'>${d.seriesName}</div> <span>${d.value.toFixed(1)}${num || '%'}</span></li>`)}</ul></div>`;
                            } else {
                                res = `<div class="popTooltip flex-h-start"><ul>${params.filter(d => d.seriesName == window.level).map(d => `<li><div style='margin-right:5px'>${d.seriesName}</div> <span>${d.value.toFixed(1)}${num || '%'}</span></li>`)}</ul></div>`;
                            }
                        } else {
                            res =
                                `<div class="popTooltip"><div style='margin-bottom:5px;text-align:center'>${params[0].name}</div><ul>${params.map(d => `<li><div style='margin-right:5px'>${d.seriesName}</div> <span>${name == 1 ? d.value.toFixed(0) : d.value.toFixed(1)}${name == 1 ? '人' : num || '%'}</span></li>`)}</ul></div>`;
                            // `<div class="popTooltip"><div style='margin-bottom:5px;text-align:center'>${params[0].name}</div><ul><li><div style='margin-right:5px'>${params[0].seriesName}</div><span>${params[0].value.toFixed(1)}%</span></li><li><div style='margin-right:5px'>${params[1].seriesName}</div><span>${params[1].value.toFixed(1)}%</span></li><li><div style='margin-right:5px'>${params[2].seriesName}</div><span>${((params[0].value.toFixed(1)) - (params[1].value.toFixed(1))).toFixed(1)}%</span></li></ul></div>`;
                        }
                        return res.replace(/,/g, '') || ' ';
                    }
                },
                borderWidth: 0,
                borderColor: 'transparent',
                backgroundColor: 'transparent',
                extraCssText: 'box-shadow: 0px 0px 8px 0px rgba(81, 85, 251, 0);',
            },
            legend: {
                data: !arr.includes(name) ?
                    data.categoryNames : data.categoryNames.filter(d => arr.includes(name)),
                top: '0%',
                right: '0',
                // itemWidth: 10,
                // itemHeight: 10,
                textStyle: {
                    color: '#FFF',
                    fontSize: 13,
                },
                itemGap: 20,
                selected: !arr.includes(name) ? legs : { [`${name}`]: true }
            },
            xAxis: [
                {
                    type: 'category',
                    boundaryGap: true,
                    axisLabel: {
                        color: '#FFFFFF',
                        interval: arr.includes(name) || type == 99 ? 1 : 0,
                        fontSize: 16,
                        rotate: -25,
                        margin: 20,
                    },
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#397cbc',
                        },
                    },
                    axisTick: {
                        show: false,
                    },
                    splitLine: {
                        show: false,
                        lineStyle: {
                            color: '#195384',
                        },
                    },
                    data: data.dimNames,
                },
            ],
            yAxis: [
                {
                    type: 'value',
                    name: name == 1 ? '人' : name == 2 ? '' : num || '%',
                    // minInterval: 1,
                    // min: 0,
                    ...obj,
                    nameTextStyle: {
                        color: '#fff',
                        fontSize: 16,
                        fontFamily: "'Microsoft Yahei', sans-serif",
                        padding: [0, 18, 0, 0],
                    },
                    axisLabel: {
                        formatter: `{value}${name == 2 ? '%' : ''}`,
                        show: type == undefined || type ? true : false,
                        textStyle: {
                            color: '#FFFFFF',
                            fontSize: 16
                        },
                    },
                    axisLine: {
                        show: false,
                        lineStyle: {
                            color: '#01E4F7',
                        },
                    },
                    axisTick: {
                        show: false,
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: '#686681',
                            type: 'dashed' //设置网格线类型 dotted：虚线 solid:实线
                        },
                    },
                },
            ],
            series: datas.map((d) => ({
                name: d.name,
                type: 'line',
                symbol: 'circle',
                symbolSize: 8,
                cursor: 'auto',
                itemStyle: {
                    normal: {
                        // color: '#01E4F7',
                        lineStyle: {
                            // color: '#01E4F7',
                            width: 1,
                        },
                    },
                },
                data: d.values,
            }))
        };
    },
    getLineChart1(data, name, type, num, values, colors) {
        //console.log(6666,window.level)
        let datas = [], legs = {}, arr = ['SGC', 'CS', 'CN', 'CP', 'MAP']
        for (let i in data.categoryValues) {
            datas.push({
                name: name,
                values: values
            })

        }
        // let colors = arr.includes(name) ? name == 'SGC' ? ['#00DBFF','#91CC76',] : name == 'CS' ? ['#91CC76'] :
        //     name == 'CN' ? ['#605BFF'] : name == 'CP' ? ['#F9B52E'] : name == 'MAP' ? ['#C93ACA'] : [] :
        //     ['#00DBFF', '#91CC76', '#605BFF', '#F9B52E', '#C93ACA', '#7F7F7F', '#7F7F7F']
        let obj = { max: Math.max(...datas[0].values), min: Math.min(...datas[0].values) }
        return {
            grid: {
                left: '0%',
                right: '5%',
                top: '20%',
                bottom: '0%',
                containLabel: true,
            },
            title: [
                {
                    text: '',
                    top: '0%',
                    left: '0%',
                    textStyle: { fontSize: 16, color: '#00DBFF' }
                }
            ],
            color: colors,
            tooltip: {
                show: true,
                trigger: 'axis',
                formatter: function (params) {
                    //console.log(4444, params)
                    let res = ''
                    let obj = params.filter(d => d.seriesName == name)[0]
                    res = `<div class="popTooltip flex-h-start"><ul><li><div style='margin-right:5px'>${obj.name}</div> <span>${obj.value}</span></li></ul></div>`;
                    return res.replace(/,/g, '') || ' ';
                },
                borderWidth: 0,
                borderColor: 'transparent',
                backgroundColor: 'transparent',
                extraCssText: 'box-shadow: 0px 0px 8px 0px rgba(81, 85, 251, 0);',
            },
            legend: {
                data: [name],
                top: '0%',
                right: '0',
                textStyle: {
                    color: '#FFF',
                    fontSize: 16,
                },
                itemGap: 20,
                selected: { [`${name}`]: true }
            },
            xAxis: [
                {
                    type: 'category',
                    boundaryGap: true,
                    axisLabel: {
                        color: '#FFFFFF',
                        interval: 1,
                        fontSize: 16,
                        rotate: -25,
                        margin: 20,
                    },
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#397cbc',
                        },
                    },
                    axisTick: {
                        show: false,
                    },
                    splitLine: {
                        show: false,
                        lineStyle: {
                            color: '#195384',
                        },
                    },
                    data: data.dimNames,
                },
            ],
            yAxis: [
                {
                    type: 'value',
                    name: '人',
                    // minInterval: 1,
                    // min: 0,
                    ...obj,
                    nameTextStyle: {
                        color: '#fff',
                        fontSize: 16,
                        fontFamily: "'Microsoft Yahei', sans-serif",
                        padding: [0, 18, 0, 0],
                    },
                    axisLabel: {
                        formatter: '{value}',
                        show: type == undefined || type ? true : false,
                        textStyle: {
                            color: '#FFFFFF',
                            fontSize: 16
                        },
                    },
                    axisLine: {
                        show: false,
                        lineStyle: {
                            color: '#01E4F7',
                        },
                    },
                    axisTick: {
                        show: false,
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: '#686681',
                            type: 'dashed' //设置网格线类型 dotted：虚线 solid:实线
                        },
                    },
                },
            ],
            series: datas.map((d) => ({
                name: d.name,
                type: 'line',
                symbol: 'circle',
                symbolSize: 8,
                cursor: 'auto',
                itemStyle: {
                    normal: {
                        // color: '#01E4F7',
                        lineStyle: {
                            // color: '#01E4F7',
                            width: 1,
                        },
                    },
                },
                data: d.values,
            }))
        };
    },
    getManyBarChart(data) {
        let xData = ['0-1年', '1-2年', '2-3年', '3-4年', '4-5年', '5年以上'];
        let datas = [];
        data.forEach((e) => {
            //xData.push(e.time);
            datas.push(e.itemNumber);
        });
        return {
            grid: {
                left: '17%',
                right: '10%',
                bottom: '9%',
                top: '35%',
            },
            xAxis: {
                show: true,
                data: xData,
                boundaryGap: ['5%', '5%'],
                axisLabel: {
                    color: '#fff',
                    interval: 0,
                    fontSize: 20,
                    fontFamily: "'Microsoft Yahei', sans-serif",
                },
                axisLine: {
                    lineStyle: {
                        color: '#56C6FF',
                    },
                },
                axisTick: {
                    show: false,
                },
            },
            yAxis: {
                show: true,
                name: '（个）',
                nameTextStyle: {
                    color: '#fff',
                    fontSize: 16,
                    fontFamily: "'Microsoft Yahei', sans-serif",
                    padding: [0, 30, 0, 0],
                },
                axisLine: {
                    show: false,
                    lineStyle: {
                        show: false,
                        color: '#56C6FF',
                    },
                },
                axisLabel: {
                    color: '#fff',
                    fontSize: 16,
                },
                splitLine: {
                    show: false,
                },
                axisTick: {
                    show: false,
                    inside: true,
                },
            },
            animationEasing: 'elasticOut',
            series: [
                {
                    name: '',
                    type: 'pictorialBar',
                    symbol: 'rect',
                    symbolRepeat: true,
                    symbolSize: [42, 7],
                    symbolMargin: 2,
                    color: '#05ACF6',
                    z: 1,
                    data: datas,
                    cursor: 'auto',
                    label: {
                        normal: {
                            show: true,
                            lineHeight: 30,
                            formatter: '{c}',
                            position: 'top',
                            textStyle: {
                                color: '#fff',
                                fontSize: 16,
                            },
                        },
                    },
                },
            ],
        };
    },
    getPieFour(data, color, type, name) {
        return {
            title: [
                {
                    show: name ? true : data == 0 && !type ? true : false,
                    text: `${data == 0 ? '0.0' : name}%` || '',
                    textAlign: 'center',
                    top: '36%',
                    left: '43%',
                    textStyle: {
                        fontSize: 12,
                        color: '#fff',
                    }
                }
            ],
            series: [
                {
                    name: '已访问',
                    cursor: 'auto',
                    itemStyle: {
                        color,
                        borderWidth: 3,
                    },
                    type: 'pie',
                    clockWise: true,
                    z: 2,
                    hoverAnimation: false,
                    radius: ['80.2%', type ? '72%' : '68%'],
                    center: ['50%', '50.3%'],
                    label: {
                        show: false,
                        formatter: '{d}%',
                        color: 'RGB(246,175,101)',
                        fontSize: 25,
                    },

                    data: [
                        {
                            value: 100 - data * 100,
                            name: '未访问',
                            itemStyle: {
                                borderType: 'dotted',//dotted 虚线
                                color: type ? 'rgb(115, 192, 142, 0)' : 'rgb(115, 192, 142, 0.3)',
                                // borderColor: color,// 虚线颜色
                                //         borderWidth: 2,// 虚线宽度
                            },
                        },
                        {
                            value: data * 100,
                            name: '已访问',
                            itemStyle: {
                                color,
                                // borderColor: color,// 虚线颜色
                                //         borderWidth: 5,// 虚线宽度
                                //         borderType: 'dotted',//dotted 虚线
                            },
                            hoverAnimation: false,
                        },
                    ],
                },
            ],
        };
    },
    getPieFour1(data, color, type, name) {
        return {
            title: [
                {
                    show: name ? true : data == 0 && !type ? true : false,
                    text: `${name}\n${data.toFixed(1)}%` || '',
                    textAlign: 'center',
                    top: '30%',
                    left: '43%',
                    textStyle: {
                        fontSize: 16,
                        color: '#fff',
                        lineHeight: 22
                    }
                }
            ],
            series: [
                {
                    name: '已访问',
                    cursor: 'auto',
                    itemStyle: {
                        color,
                        borderWidth: 3,
                    },
                    type: 'pie',
                    clockWise: true,
                    z: 2,
                    hoverAnimation: false,
                    radius: ['75.2%', type ? '72%' : '68%'],
                    center: ['50%', '50.3%'],
                    label: {
                        show: false,
                        formatter: '{d}%',
                        color: 'RGB(246,175,101)',
                        fontSize: 18,
                    },

                    data: [
                        {
                            value: 100 - data,
                            name: '未访问',
                            itemStyle: {
                                borderType: 'dotted',//dotted 虚线
                                color: type ? 'rgb(115, 192, 142, 0)' : 'rgb(115, 192, 142, 0.3)',
                                // borderColor: color,// 虚线颜色
                                //         borderWidth: 2,// 虚线宽度
                            },
                        },
                        {
                            value: data,
                            name: '已访问',
                            itemStyle: {
                                color,
                                // borderColor: color,// 虚线颜色
                                //         borderWidth: 5,// 虚线宽度
                                //         borderType: 'dotted',//dotted 虚线
                            },
                            hoverAnimation: false,
                        },
                    ],
                },
            ],
        };
    },
    getSmallBar(data) {
        let datas = []
        data.forEach(d => {
            datas.push({
                name: d.name,
                type: 'bar',
                tooltip: {
                    valueFormatter: function (value) {
                        return value + ' 人';
                    }
                },
                barWidth: 35,

                itemStyle: {
                    // 柱形图圆角，鼠标移上去效果，如果只是一个数字则说明四个参数全部设置为那么多
                    normal: {
                        // 柱形图圆角，初始化效果
                        barBorderRadius: [4, 4, 0, 0]
                    }
                },
                data: [
                    d.value
                ]
            })
        })
        return {
            grid: {
                top: '10%',
                right: '0%',
                left: '0%',
                bottom: '9%',
            },
            color: ['#02C7E8', '#605BFF', '#85BA6C', '#E36B2E', '#F9B52E', '#C93ACA', '#00DBFF', '#91CC76', '#605BFF', '#EE6666',],
            legend: {
                data: data.map(d => d.name),
                right: '-1%',
                show: true,
                itemGap: 10,
                itemWidth: 15,
                itemHeight: 12,
                textStyle: {
                    color: '#FFF',
                    fontSize: 16,
                },
                orient: 'vertical',
                formatter: (name) => {
                    let total = 0;
                    let tarValue, percent = 0
                    for (let i = 0; i < data.length; i++) {
                        total += data[i].value;
                        if (data[i].name == name) {
                            tarValue = data[i].value;
                            percent = data[i].percent;
                        }
                    }
                    return `${name}   ${tarValue}${'人'}`;
                },
            },
            tooltip: {
                trigger: 'item',
                formatter: function (params) {
                    let res = `<div class="popTooltip flex-h-start common-text"><ul>
                    <li><div>${params.seriesName}</div><span>${params.value}人</span></li>
                    </ul></div>`;
                    return res.replace(/,/g, '')
                },
                borderWidth: 0,
                borderColor: 'transparent',
                backgroundColor: 'transparent',
                extraCssText: 'box-shadow: 0px 0px 8px 0px rgba(81, 85, 251, 0);',
            },
            xAxis: {
                data: [1, 2],
                axisLine: {
                    show: false,
                    lineStyle: {
                        color: 'rgba(255,255,255,.5)',
                    },
                },
                axisLabel: {
                    show: false,
                    margin: 10,
                    color: '#e2e9ff',
                    textStyle: {
                        fontSize: 16,
                    },
                    interval: 0,
                    rotate: -25,
                },
                axisTick: {
                    show: false,
                },
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: 'rgba(255,255,255,0.12)',
                    },
                },
            },
            yAxis: [
                {
                    type: 'value',
                    name: '',
                    axisLabel: {
                        show: false,
                        formatter: '{value}',
                        color: '#e2e9ff',
                        fontSize: 16,
                    },
                    nameTextStyle: {
                        color: '#fff',
                        fontSize: 16,
                        fontFamily: "'Microsoft Yahei', sans-serif",
                        padding: [0, 35, 0, 0],
                    },

                    axisLine: {
                        show: false,
                        lineStyle: {
                            color: 'rgba(0,186,255,.6)',
                        },
                    },
                    axisTick: {
                        show: false,
                    },
                    splitLine: {
                        show: false,
                        lineStyle: {
                            color: '#686681',
                            type: 'dashed' //设置网格线类型 dotted：虚线 solid:实线
                        },
                    },
                    min: 0,
                    // max: 250,
                    interval: 50,
                },
            ],
            series: datas
        }
    },
    getBar(data, type, name, tips, tips0) {
        let barDatas = [], lineDatas = [], legs = {}, obj = type ? { color: ['#00DBFF'] } : {}
        data.columnar.categoryValues.forEach((d, index) => {

            if (index < 6) {
                legs = { ...legs, [`${data.columnar.categoryNames[index]}`]: true }
            } else {
                legs = { ...legs, [`${data.columnar.categoryNames[index]}`]: false }
            }

            if (index < 6) {
                barDatas.push({
                    name: data.columnar.categoryNames[index],
                    type: 'bar',
                    barWidth: type ? 10 : 15,
                    ...obj,
                    tooltip: {
                        valueFormatter: function (value) {
                            return value + ' ml';
                        }
                    },
                    data: d
                })
            }
        })
        data.brokenLine.categoryValues.forEach((d, index) => {
            //console.log(4444,d)
            lineDatas.push({
                name: data.brokenLine.categoryNames[index],
                type: 'line',
                symbol: 'circle',
                yAxisIndex: 1,
                label: {
                    show: true,
                    position: 'top',
                    formatter: function (data) {
                        return data.data.toFixed(1) + '%'
                    },
                    rotate: 45,
                    textStyle: {
                        fontSize: 14,
                        color: '#fff'
                    }
                },
                tooltip: {
                    valueFormatter: function (value) {
                        return value + ' °C';
                    }
                },
                data: d
            })
        })

        return {
            color: ['#F9B52E', '#F3AA15', '#00DBFF', '#91CC76', '#605BFF', '#EE6666',],
            grid: {
                top: '25%',
                right: type ? '2%' : '10%',
                left: type ? '9%' : '12%',
                bottom: type ? '20%' : '14%',
            },
            legend:
            {
                // data: [...data.brokenLine.categoryNames, ...data.columnar.categoryNames],
                data: [{
                    name: data.brokenLine.categoryNames[0],
                    // icon:'circle'
                }, {
                    name: data.columnar.categoryNames[0],
                    icon: 'rect'
                }, {
                    name: data.columnar.categoryNames[1],
                    icon: 'rect'
                }],
                top: '0%',
                right: '0%',
                itemWidth: 14,
                itemHeight: 10,

                textStyle: {
                    color: '#FFF',
                    fontSize: 16,
                },
                itemGap: 10,
                show: true,
                selected: legs
            },
            tooltip: {
                trigger: 'item',
                formatter: function (params) {
                    let res = `<div class="popTooltip common-text"><div style='margin-bottom:5px;text-align:center'>${params.name}</div><ul>
                    ${data.brokenLine.categoryNames.map((d, index) => `<li><div>${d}</div><span>${data.brokenLine.categoryValues[index][params.dataIndex].toFixed(1)}${'%'}</span></li>`)}
                    ${data.columnar.categoryNames.map((d, index) => `<li><div>${d}</div><span>${data.columnar.categoryValues[index][params.dataIndex].toFixed(tips == '亿元' && tips0 != '万元' ? 2 : tips0 ? 1 : tips ? 1 : 0)}${tips0 ? tips0 : tips ? tips : '人'}</span></li>`)}
                    ${data.center ? data.center.categoryNames.map((d, index) => `<li><div>${d}</div><span>${data.center.categoryValues[index][params.dataIndex].toFixed(tips == '亿元' && tips0 != '万元' ? 2 : tips0 ? 1 : tips ? 1 : 0)}${tips0 ? tips0 : tips ? tips : '人'}</span></li>`) : ''}
                    </ul></div>`;
                    return res.replace(/,/g, '')
                },
                borderWidth: 0,
                borderColor: 'transparent',
                backgroundColor: 'transparent',
                extraCssText: 'box-shadow: 0px 0px 8px 0px rgba(81, 85, 251, 0);',
            },
            xAxis: {
                data: data.brokenLine.dimNames,
                axisLine: {
                    lineStyle: {
                        color: 'rgba(255,255,255,.5)',
                    },
                },
                axisLabel: {
                    margin: 10,
                    color: '#e2e9ff',
                    textStyle: {
                        fontSize: 16,
                    },
                    interval: data.brokenLine.dimNames.length > 5 ? 1 : 0,
                    rotate: data.brokenLine.dimNames.length > 5 ? -25 : 0,
                },
                axisTick: {
                    show: false,
                },
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: 'rgba(255,255,255,0.12)',
                    },
                },
            },
            yAxis: [
                {
                    type: 'value',
                    name: name || '人',
                    axisLabel: {
                        formatter: '{value}',
                        color: '#e2e9ff',
                        fontSize: 16,
                    },
                    nameTextStyle: {
                        color: '#fff',
                        fontSize: 16,
                        fontFamily: "'Microsoft Yahei', sans-serif",
                        padding: [0, 35, 0, 0],
                    },

                    axisLine: {
                        show: false,
                        lineStyle: {
                            color: 'rgba(0,186,255,.6)',
                        },
                    },
                    axisTick: {
                        show: false,
                    },
                    splitLine: {
                        show: false,
                        lineStyle: {
                            color: '#686681',
                            type: 'dashed' //设置网格线类型 dotted：虚线 solid:实线
                        },
                    },
                    min: 0,
                    // max: 250,
                    // interval: 50,
                    minInterval: 1
                },
                {
                    type: 'value',
                    show: type ? false : true,
                    name: '',
                    axisLabel: {
                        formatter: '{value}%',
                        color: '#e2e9ff',
                        fontSize: 16,
                    },
                    nameTextStyle: {
                        color: '#fff',
                        fontSize: 16,
                        fontFamily: "'Microsoft Yahei', sans-serif",
                        padding: [0, 0, 0, 30],
                    },

                    axisLine: {
                        show: false,
                        lineStyle: {
                            color: 'rgba(0,186,255,.6)',
                        },
                    },
                    axisTick: {
                        show: false,
                    },
                    splitLine: {
                        show: false,
                        lineStyle: {
                            color: '#686681',
                            type: 'dashed' //设置网格线类型 dotted：虚线 solid:实线
                        },
                    },
                    min: 0,
                    // max: 25,
                    // interval: 5,
                    minInterval: 1
                }
            ],
            series: [
                ...lineDatas,
                ...barDatas,
            ]
        }
    },
    getBars(data, a, b, config) {
        console.log(111, data, config)
        return {
            tooltip: {
                show: true,
                trigger: 'axis',
                formatter: function (params) {
                    console.log(params)
                    console.log(data.brokenLine.categoryNames[0])
                    let res =
                        data.areaBrokenLine.categoryNames[0] === '2021总离职' ? `<div class="popTooltip"><div style='margin-bottom:5px;text-align:center'>${params[0].value && params[0].name}</div>
                    <ul>
                    <li>
                   <span style='display:inline-block;width:100px;textAlign:right'>总离职率：</span>
                    <span style='display:inline-block;width:100px;textAlign:center'>${params.findIndex(obj => obj.seriesName === '2022总离职') !== -1 ? `${params[params.findIndex(obj => obj.seriesName === '2022总离职')].value.toFixed(1)}%（2022）` : '-'}</span>&nbsp;&nbsp;|&nbsp;&nbsp;<span style='display:inline-block;width:100px;textAlign:center'>
                    ${params.findIndex(obj => obj.seriesName === '2021总离职') !== -1 ? `${params[params.findIndex(obj => obj.seriesName === '2021总离职')].value.toFixed(1)}%（2021） ` : '-'}</span> 
                    </li >
                    <li>
                    <span style='display:inline-block;width:100px;textAlign:right'>自愿离职率：</span>
                    <span style='display:inline-block;width:100px;textAlign:center'>${params.findIndex(obj => obj.seriesName === '2022自愿离职') !== -1 ? `${params[params.findIndex(obj => obj.seriesName === '2022自愿离职')].value.toFixed(1)}%（2022）` : '-'}</span>&nbsp;&nbsp;|&nbsp;&nbsp;<span style='display:inline-block;width:100px;textAlign:center'>${params.findIndex(obj => obj.seriesName === '2021自愿离职') !== -1 ? `${params[params.findIndex(obj => obj.seriesName === '2021自愿离职')].value.toFixed(1)}%（2021）` : '-'}</span>
                    </li>
                    <li>
                    <span style='display:inline-block;width:100px;textAlign:right'>非自愿离职率：</span>
                    <span style='display:inline-block;width:100px;textAlign:center'>${params.findIndex(obj => obj.seriesName === '2022非自愿离职率') !== -1 ? `${params[params.findIndex(obj => obj.seriesName === '2022非自愿离职率')].value.toFixed(1)}%（2022）` : '-'}</span>&nbsp;&nbsp;|&nbsp;&nbsp;<span style='display:inline-block;width:100px;textAlign:center'>${params.findIndex(obj => obj.seriesName === '2021非自愿离职率') !== -1 ? `${params[params.findIndex(obj => obj.seriesName === '2021非自愿离职率')].value.toFixed(1)}%（2021）` : '-'}</span>
                    </li>
                    </ul >
                    </div > `:

                            `<div class="popTooltip"><div style='margin-bottom:5px;text-align:center'>${params[0].value && params[0].name}</div>
                    <ul>
                    <li>
                   <span style='display:inline-block;width:100px;textAlign:right'>总离职率：</span>
                    <span style='display:inline-block;width:100px;textAlign:center'>${params.findIndex(obj => obj.seriesName === '2023总离职') !== -1 ? `${params[params.findIndex(obj => obj.seriesName === '2023总离职')].value.toFixed(1)}%（2023）` : '-'}</span>&nbsp;&nbsp;|&nbsp;&nbsp;<span style='display:inline-block;width:100px;textAlign:center'>
                    ${params.findIndex(obj => obj.seriesName === '2022总离职') !== -1 ? `${params[params.findIndex(obj => obj.seriesName === '2022总离职')].value.toFixed(1)}%（2022） ` : '-'}</span> 
                    </li >
                    <li>
                    <span style='display:inline-block;width:100px;textAlign:right'>自愿离职率：</span>
                    <span style='display:inline-block;width:100px;textAlign:center'>${params.findIndex(obj => obj.seriesName === '2023自愿离职') !== -1 ? `${params[params.findIndex(obj => obj.seriesName === '2023自愿离职')].value.toFixed(1)}%（2023）` : '-'}</span>&nbsp;&nbsp;|&nbsp;&nbsp;<span style='display:inline-block;width:100px;textAlign:center'>${params.findIndex(obj => obj.seriesName === '2022自愿离职') !== -1 ? `${params[params.findIndex(obj => obj.seriesName === '2022自愿离职')].value.toFixed(1)}%（2022）` : '-'}</span>
                    </li>
                    <li>
                    <span style='display:inline-block;width:100px;textAlign:right'>非自愿离职率：</span>
                    <span style='display:inline-block;width:100px;textAlign:center'>${params.findIndex(obj => obj.seriesName === '2023非自愿离职率') !== -1 ? `${params[params.findIndex(obj => obj.seriesName === '2023非自愿离职率')].value.toFixed(1)}%（2023）` : '-'}</span>&nbsp;&nbsp;|&nbsp;&nbsp;<span style='display:inline-block;width:100px;textAlign:center'>${params.findIndex(obj => obj.seriesName === '2022非自愿离职率') !== -1 ? `${params[params.findIndex(obj => obj.seriesName === '2022非自愿离职率')].value.toFixed(1)}%（2022）` : '-'}</span>
                    </li>
                    </ul >
                    </div > `

                    // `< div class="popTooltip common-text" ><div style='margin-bottom:5px;text-align:center'>${params[0].name}</div><ul>
                    // ${params.map((item, index) => `<li><div>${item.seriesName}</div><span>${item.value}%</span></li>`)}
                    // </ul></div > `;

                    return res.replace(/,/g, '')
                },
                borderWidth: 0,
                borderColor: 'transparent',
                backgroundColor: 'transparent',
                extraCssText: 'box-shadow: 0px 0px 8px 0px rgba(81, 85, 251, 0);',
                // axisPointer: {
                //     type: 'cross',
                //     label: {
                //         backgroundColor: '#6a7985'
                //     }
                // }
                // trigger: 'item',


            },
            color: ['#00DBFF', '#89F300', '#E93D0C', '#00DBFF', "#89F300", "#E93D0C"],
            legend: {
                // data: [...data.areaBrokenLine.categoryNames, ...data.brokenLine.categoryNames],
                // selectedMode: "single",
                data: [
                    {
                        name: data.areaBrokenLine.categoryNames[0],
                        icon: 'rect',

                    },
                    {
                        name: data.areaBrokenLine.categoryNames[1],
                        icon: 'rect',

                    },
                    {
                        name: data.areaBrokenLine.categoryNames[2],
                        icon: 'rect',

                    },
                    {
                        name: data.brokenLine.categoryNames[0],


                    },
                    {
                        name: data.brokenLine.categoryNames[1],

                    },
                    {
                        name: data.brokenLine.categoryNames[2],

                    },
                ],
                selected: {
                    '2022总离职': true,
                    '2023总离职': true,
                    '2021总离职': true,
                    '2022自愿离职': false,
                    '2023自愿离职': false,
                    '2021自愿离职': false,
                    '2022非自愿离职率': false,
                    '2023非自愿离职率': false,
                    '2021非自愿离职率': false,

                    // '平均年龄': config && config.filterList && config.filterList.includes('2022总离职') ? true : false,
                    // '平均司龄': config && config.filterList && config.filterList.includes('2023总离职') ? true : false,
                    // '平均工龄': config && config.filterList && config.filterList.includes('2021总离职') ? true : false

                },
                top: '0%',
                right: '0%',
                itemWidth: 14,
                itemHeight: 10,
                textStyle: {
                    color: '#FFF',
                    fontSize: 17,
                },
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            xAxis: [
                {
                    type: 'category',
                    boundaryGap: false,
                    data: data.areaBrokenLine.dimNames,
                    axisLabel: {
                        color: '#fff',
                        fontSize: 16
                    }
                }
            ],
            yAxis: [
                {
                    type: 'value',
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: '#686681',
                            type: 'dashed' //设置网格线类型 dotted：虚线 solid:实线
                        },
                    },
                    axisLabel: {
                        formatter: '{value}%',
                        color: '#fff',
                        fontSize: 16
                    },
                },

            ],
            series: [
                {
                    name: data.areaBrokenLine.categoryNames[0],
                    type: 'line',
                    // stack: 'Total',
                    showSymbol: false,//折线点设置为实心点
                    label: {
                        show: true,
                        position: 'top'
                    },
                    areaStyle: {
                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            {
                                offset: 0,
                                color: 'rgba(0, 219, 255, 1)'
                            },
                            {
                                offset: 1,
                                color: 'rgba(0, 219, 255, 0.50)'
                            }
                        ]),
                        opacity: 0.3
                    },
                    lineStyle: {
                        color: '#00DBFF',
                        width: 0.5
                    },


                    emphasis: {
                        focus: 'series'
                    },
                    data: data.areaBrokenLine.categoryValues[0]
                },
                {
                    name: data.areaBrokenLine.categoryNames[1],
                    type: 'line',
                    stack: 'Total',
                    showSymbol: false,
                    areaStyle: {
                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            {
                                offset: 0,
                                color: 'rgba(137, 243, 0, 1)'

                            },
                            {
                                offset: 1,
                                color: 'rgba(79, 138, 3, 0.81)'
                            }
                        ]),
                        opacity: 0.3,
                    },
                    lineStyle: {
                        color: '#89F300',
                        width: 0.5
                    },
                    itemStyle: {
                        normal: {
                            // color: "#FAC459", //折线点的颜色
                            borderWidth: 2, //拐点边框大小
                        },
                    },
                    emphasis: {
                        focus: 'series'
                    },
                    data: data.areaBrokenLine.categoryValues[1]
                },
                {
                    name: data.areaBrokenLine.categoryNames[2],
                    type: 'line',
                    stack: 'Total',
                    showSymbol: false,
                    areaStyle: {
                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            {
                                offset: 0,
                                color: 'rgba(233, 61, 12, 1)'

                            },
                            {
                                offset: 1,
                                color: 'rgba(233, 61, 12, 0.50)'
                            }
                        ]),
                        opacity: 0.3,
                    },
                    lineStyle: {
                        color: '#E93D0C',
                        width: 0.5
                    },
                    itemStyle: {
                        normal: {
                            // color: "#FAC459", //折线点的颜色
                            borderWidth: 2, //拐点边框大小
                        },
                    },
                    emphasis: {
                        focus: 'series'
                    },
                    data: data.areaBrokenLine.categoryValues[2]
                },
                {
                    name: data.brokenLine.categoryNames[0],
                    type: 'line',
                    symbol: 'circle',
                    lineStyle: {
                        color: '#00DBFF',
                    },
                    itemStyle: {
                        normal: {
                            color: "#00DBFF", //折线点的颜色
                            borderWidth: 2, //拐点边框大小
                        },
                    },
                    data: data.brokenLine.categoryValues[0]
                },
                {
                    name: data.brokenLine.categoryNames[1],
                    type: 'line',
                    symbol: 'circle',
                    lineStyle: {
                        color: '#89F300',
                    },
                    itemStyle: {
                        normal: {
                            color: "#89F300", //折线点的颜色
                            borderWidth: 2, //拐点边框大小
                        },
                    },
                    data: data.brokenLine.categoryValues[1]
                },
                {
                    name: data.brokenLine.categoryNames[2],
                    type: 'line',
                    symbol: 'circle',
                    lineStyle: {
                        color: '#E93D0C',
                    },
                    itemStyle: {
                        normal: {
                            color: "#E93D0C", //折线点的颜色
                            borderWidth: 2, //拐点边框大小
                        },
                    },
                    data: data.brokenLine.categoryValues[2]
                },
            ]
        }
    },
    getScatter(data) {

        return {
            grid: {
                top: '20%',
                right: '0%',
                left: '25%',
                bottom: '20%',
            },
            color: ['#00DBFF'],
            tooltip: {
                trigger: 'item',
                show: true,
                formatter: function (params) {
                    let res = `< div class="popTooltip flex-h-start common-text" > <ul><li><div>年龄</div><span>${params.value[0]}</span></li><li><div>司龄</div><span>${params.value[1]}</span></li><li><div>人数</div><span>${params.value[2]}</span></li></ul></div > `;
                    return res
                },
                borderWidth: 0,
                borderColor: 'transparent',
                backgroundColor: 'transparent',
                extraCssText: 'box-shadow: 0px 0px 8px 0px rgba(81, 85, 251, 0);',
            },
            xAxis: {
                data: ['70后及以下', '80后', '90后', '00后及以上'],
                // min: 20,
                name: '年龄',
                margin: 20,
                axisLine: {
                    lineStyle: {
                        color: 'rgba(255,255,255,.5)',
                    },
                },
                nameTextStyle: {
                    color: '#fff',
                    fontSize: 16,
                    fontFamily: "'Microsoft Yahei', sans-serif",
                    padding: [0, 35, 0, 0],
                },
                axisLabel: {
                    margin: 10,
                    color: '#e2e9ff',
                    textStyle: {
                        fontSize: 16,
                    },
                    interval: 0,
                    rotate: 0,
                },
                axisTick: {
                    show: false,
                },
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: 'rgba(255,255,255,0.12)',
                    },
                },
            },
            yAxis: {
                name: '司龄',
                data: ['0-2', '2-5（不含2）', '5-10（不含5）', '10-15（不含10）', '15-20（不含15）', '大于20（不含20）'],
                axisLabel: {
                    formatter: '{value}',
                    color: '#e2e9ff',
                    fontSize: 16,
                    margin: 10
                },
                nameTextStyle: {
                    color: '#fff',
                    fontSize: 16,
                    fontFamily: "'Microsoft Yahei', sans-serif",
                    padding: [0, 20, 0, 20],
                },

                axisLine: {
                    show: false,
                    lineStyle: {
                        color: 'rgba(0,186,255,.6)',
                    },
                },
                axisTick: {
                    show: false,
                },
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#686681',
                        type: 'dashed' //设置网格线类型 dotted：虚线 solid:实线
                    },
                },
            },
            series: [
                {
                    symbolSize: function (data) {
                        if (Number(data[2]) > 38 && Number(data[2]) < 50) {
                            return 20
                        }
                        if (Number(data[2]) < 38) {
                            return 10
                        }
                        if (Number(data[2]) > 50 && Number(data[2]) < 60) {
                            return 25
                        }
                        if (Number(data[2]) > 60 && Number(data[2]) < 80) {
                            return 30
                        }
                        if (Number(data[2]) > 80 && Number(data[2]) < 100) {
                            return 35
                        }
                        if (Number(data[2]) > 100 && Number(data[2]) < 300) {
                            return 40
                        }
                        if (Number(data[2]) > 300) {
                            return 45
                        }
                    },
                    symbolOffset: function (data) {
                        return Number(data[2]) > 39 ? ['-40%', '-40%'] : [`${data[2]}% `, `${data[2]}% `];
                    },
                    itemStyle: {
                        shadowBlur: 5,
                        shadowColor: 'rgba(120, 36, 50, 0.5)',
                        shadowOffsetY: 5,
                        color: new echarts.graphic.RadialGradient(0.4, 0.3, 1, [
                            {
                                offset: 0,
                                color: 'rgb(0, 219, 255)'
                            },
                            {
                                offset: 1,
                                color: 'rgb(24, 216, 248)'
                            }
                        ])
                    },
                    data: data,
                    type: 'scatter'
                }
            ]
        }
    },
    getPoint(data) {
        let datas = []
        let colors = [[{
            offset: 0,
            color: '#2C26E8'
        },
        {
            offset: 1,
            color: '#605BFF'
        }],
        [{
            offset: 0,
            color: '#AD6E00'
        },
        {
            offset: 1,
            color: '#FFAC00'
        }],
        [{
            offset: 0,
            color: '#509A2E'
        },
        {
            offset: 1,
            color: '#91CC76'
        }],
        [{
            offset: 0,
            color: '#CA3A3A'
        },

        {
            offset: 1,
            color: '#99189A'
        }],
        [{
            offset: 0,
            color: '#99189A'
        },
        {
            offset: 1,
            color: '#CAA93A'
        }],
        [{
            offset: 0,
            color: '#99189A'
        },
        {
            offset: 1,
            color: '#C93ACA'
        }],
        [{
            offset: 0,
            color: '#02B4D2'
        },
        {
            offset: 1,
            color: '#00EFFF'
        }],
        [{
            offset: 0,
            color: '#3AA5CA'
        },
        {
            offset: 1,
            color: '#99189A'
        }],
        [{
            offset: 0,
            color: '#02B4D2'
        },
        {
            offset: 1,
            color: '#00EFFF'
        }]]
        // let max = _.sum(data.map(d=>d.value))
        data.forEach((d, index) => {
            datas.push({
                "name": d.name,
                "value": d.value,
                itemStyle: {
                    shadowBlur: 0,
                    color: new echarts.graphic.RadialGradient(0.4, 1, 1, [
                        {
                            offset: 0,
                            color: colors[index][0].color
                        },
                        {
                            offset: 1,
                            color: colors[index][1].color
                        }
                    ])
                },
                "symbolSize": d.value > 1500 ? 90 : d.value > 1000 ? 70 : d.value > 500 ? 60 : 50,
                "draggable": true,
            })
        })
        return {
            //提示框
            tooltip: {
                show: false
            },
            // animationDurationUpdate: function (idx) {
            //     return idx * 100;
            // },
            animationEasingUpdate: 'bounceIn',
            series: [{
                type: 'graph',
                layout: 'force',
                force: {
                    repulsion: 120,
                    edgeLength: 5,
                },
                label: {
                    show: true,
                    formatter: function (params) {
                        let parseName = params.name ? [params.name] : [];
                        if (params.name.length > 6) {
                            parseName = params.name ? [params.name.substring(0, params.name.length / 2), params.name.substring(params.name.length / 2, params.name.length)] : [];
                        }
                        return [`{ b | ${parseInt(params.value)} } `, `{ d | ${params.name} } `].join('\n');
                    },
                    rich: {
                        d: {
                            fontSize: 16,
                            color: '#fff',
                            align: 'center'
                        },
                        b: {
                            color: '#fff',
                            fontSize: 22,
                            fontWeight: 'bold',
                            align: 'center',
                            lineHeight: 30,
                        },

                    },
                },
                data: datas
            }]

        }
    },
    getScatter1(data, filterList) {
        let points = [], lines = [], arr = []
        let xAxis = [], x = []
        let color = ['#00DBFF', '#91CC76', '#605BFF', '#FAC459', '#C93ACA', '#FA7531']
        data.forEach((d, index) => {
            arr.push(d.value)
            let list = !filterList.includes('去年同期') ? [d.value[1]] : d.value
            list.forEach((v, i) => {
                x.push(d.salaryCost[i])
                if (filterList.includes(d.name)) {
                    points.push({
                        name: d.name,
                        symbol: "circle",
                        remark: i == 0 ? '同期' : '',
                        symbolSize: 25,
                        symbolOffset: d.value[0] > d.value[1] ? i == 0 ? ['-55%', '0%'] : ['48%', '-0%'] : i == 0 ? ['48%', '0%'] : ['-55%', '0%'],
                        label: {
                            normal: {
                                show: true,
                                rich: {
                                    a: {
                                        color: "#ff0000"
                                    }
                                }
                            },
                            emphasis: {
                                show: false
                            }
                        },

                        xAxis: JSON.stringify(d.salaryCost[i]),
                        yAxis: v,
                        itemStyle: i == 1 ? {
                            color: 'transparent',
                            borderColor: color[index],
                        } : {
                            color: color[index],
                        }
                    })
                }
            })


        })

        x = [...new Set(x)];
        x = _.sortBy(x, function (n) {
            return n;
        })
        x.forEach(d => {
            d = JSON.stringify(d)
            xAxis.push(d)
        })
        data.forEach((d, index) => {
            if (filterList.includes(d.name)) {
                lines.push([
                    {
                        name: '',
                        symbolSize: 0,
                        xAxis: JSON.stringify(d.salaryCost[1]),
                        yAxis: d.value[1],

                        itemStyle: {
                            color: color[index]
                        }
                    },
                    {
                        xAxis: JSON.stringify(d.salaryCost[0]),
                        yAxis: d.value[0],
                        symbol: 'arrow',
                        itemStyle: {
                            color: color[index]
                        }
                    }
                ])
            }
        })
        lines.push([{
            symbolSize: 0,
            xAxis: xAxis[parseInt((xAxis.length) / 2)],
            yAxis: 0,
            lineStyle: {
                type: 'dashed',
                with: 2
            },
            itemStyle: {
                color: '#979797'
            }
        },
        {
            xAxis: xAxis[parseInt((xAxis.length) / 2)],
            yAxis: 18,
            lineStyle: {
                type: 'dashed',
                with: 0
            },
            itemStyle: {
                color: '#ff0000'
            }
        }])
        return {
            xAxis: {
                type: 'category',
                data: xAxis,
                name: '',
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: 'rgba(255,255,255,.5)',
                        type: 'dashed',
                    },
                },
                nameTextStyle: {
                    color: '#fff',
                    fontSize: 16,
                    fontFamily: "'Microsoft Yahei', sans-serif",
                    padding: [0, 35, 0, 0],
                },
                axisLabel: {
                    margin: 10,
                    color: '#e2e9ff',
                    textStyle: {
                        fontSize: 16,
                    },
                    interval: 0,
                    rotate: 0,
                },
                axisTick: {
                    show: false,
                },
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: 'rgba(255,255,255,0.12)',
                    },
                },
            },
            yAxis: {
                type: 'value',
                name: '%',
                rotate: 90,
                axisLabel: {
                    formatter: '{value}',
                    color: '#e2e9ff',
                    fontSize: 16,
                },
                nameTextStyle: {
                    color: '#fff',
                    fontSize: 16,
                    fontFamily: "'Microsoft Yahei', sans-serif",
                    padding: [0, 35, 0, 0],
                },

                axisLine: {
                    show: false,
                    lineStyle: {
                        color: 'rgba(0,186,255,.6)',
                    },
                },
                axisTick: {
                    show: false,
                },
                splitLine: {
                    show: false,
                    lineStyle: {
                        color: '#686681',
                        type: 'dashed' //设置网格线类型 dotted：虚线 solid:实线
                    },
                },
            },
            series: [{
                data: arr,
                symbolSize: 0,
                type: 'scatter',
                markLine: {
                    symbol: 'none',
                    lineStyle: {
                        type: 'solid',
                    },
                    data: !filterList.includes('去年同期') ? [] : [...lines,]
                },
                markPoint: {
                    data: points,
                    label: {
                        show: true,
                        position: [30, -0],
                        formatter: function (params) {
                            return `{ b | ${params.name}${params.data.remark} } \n{ d | ${params.data.yAxis}%} `;
                        },
                        rich: {
                            d: {
                                fontSize: 16,
                                color: '#fff',
                                lineHeight: 20
                            },
                            b: {
                                color: '#fff',
                                fontSize: 16,
                                lineHeight: 20
                            },

                        },
                        textStyle: {
                            fontSize: 16,
                        }
                    },

                }
            }]
        }
    }

};
export default options;
