import React, { Component } from 'react';
import bg from '../../assets/img/bg-full.png';
import Nav from '../nav';
import Tit from '../components/Tit';
import Tab from '../components/Tab';
import ECharts from '../../components/ECharts';
import options from './options';
import CountUp from 'react-countup';
import chart from './chart';
import './index.scss'
import man from '../../assets/img/home/icon_07.png';
import woman from '../../assets/img/home/icon_08.png';
import empty from '../../assets/empty.png';
import { Chord, WordCloud } from '@ant-design/plots';
import { cqRequest } from '../../tools/http';
import moment from 'moment';
class Index extends Component {

  constructor() {
    super()
    this.state = {
      data: [],
      level: 'SGC',
      onJobCleanPlusMinusStat: {},
      dimissionReasonEduStat: {},
      flowStat: {},
      overviewStat: {},
      entryEmpPropertyStat: {},
      entryYearStat: {},
      dimissionYearStat: {},
      dimissionAgeCompStat: [],
      statisMonth: moment().subtract(1, 'month').format('YYYYMM'),
      dataQualityInfo: ''
    }
  }
  _init = () => {
    const { level, statisMonth } = this.state
    cqRequest(`/emp/onJobCleanPlusMinusStat?level=${level}&statisMonth=${statisMonth}`).then((res) => {// 员工净增减趋势
      this.setState({
        onJobCleanPlusMinusStat: res.data
      })
    })

    cqRequest(`/emp/dimissionReasonEduStat?level=${level}&statisMonth=${statisMonth}`).then((res) => {// 离职原因
      this.setState({
        dimissionReasonEduStat: res.data
      })
    })

    cqRequest(`/emp/flowStat?level=${level}&statisMonth=${statisMonth}`).then((res) => {// 人员流动整合
      this.setState({
        flowStat: res.data
      })
    })

    cqRequest(`/emp/overviewStat?level=${level}&statisMonth=${statisMonth}`).then((res) => {// 人员流动概况
      this.setState({
        overviewStat: res.data
      })
    })

    cqRequest(`/emp/entryEmpPropertyStat?level=${level}&statisMonth=${statisMonth}`).then((res) => {// 近一年新进新进员工各属性分布
      res.data.eduPie.forEach(element => {
        if (!element.name) {
          //数组删除某一项
          var index = res.data.eduPie.indexOf(element);
          if (index > -1) {
            res.data.eduPie.splice(index, 1);
          }
        }
      });
      this.setState({
        entryEmpPropertyStat: res.data
      })
    })

    cqRequest(`/emp/entryYearStat?level=${level}&statisMonth=${statisMonth}`).then((res) => {// 近一年员工新进情况
      this.setState({
        entryYearStat: res.data
      })
    })

    cqRequest(`/emp/dimissionYearStat?level=${level}&statisMonth=${statisMonth}`).then((res) => {// 近一年员工新进情况
      this.setState({
        dimissionYearStat: res.data
      })
    })

    cqRequest(`/emp/dimissionAgeCompStat?level=${level}&statisMonth=${statisMonth}`).then((res) => {// 近一年员工新进情况
      this.setState({
        dimissionAgeCompStat: res.data
      })
    })
    cqRequest(`/common/dataQualityInfo?level=${level}&pageType=EMP_PAGE`).then((res) => {// 感叹号数据
      this.setState({
        dataQualityInfo: res.data
      })
    })
  }
  componentDidMount() {
    this._init()
  }
  getData = (data) => {
    this.setState({ data })
  }
  render() {
    const {
      onJobCleanPlusMinusStat,
      dimissionReasonEduStat,
      flowStat,
      overviewStat,
      entryEmpPropertyStat,
      entryYearStat,
      dimissionYearStat,
      dimissionAgeCompStat
    } = this.state
    let all = document.documentElement.clientHeight == 720 ||
      document.documentElement.clientHeight == 864 ||
      document.documentElement.clientHeight == 1080 ||
      document.documentElement.clientHeight == 818 ||
      document.documentElement.clientHeight == 850 ||
      document.documentElement.clientHeight == 960 ||
      document.documentElement.clientHeight == 610 ||
      document.documentElement.clientHeight == 681 ||
      (document.documentElement.clientHeight == 578 && document.documentElement.clientWidth != 1280) ||
      document.documentElement.clientHeight == 781 ||
      document.documentElement.clientHeight == 823 ||
      document.documentElement.clientHeight == 1042 ||

      document.documentElement.clientHeight == 1010 ||
      document.documentElement.clientHeight == 1152
      ? true : false
    let all1 = (document.documentElement.clientHeight == 578 && document.documentElement.clientWidth != 1280) ?
      true : false
    return (
      <div className={`home w${window.screen.width}`}>
        <img src={bg} width={'100%'} alt="" className='full-bg' />

        <Nav />
        <div className='home-content'>
          <div className={`home-content-left w${window.screen.width}`} style={{ width: 520 }}>
            <div className='mt0'>
              <Tit text="员工入职情况"></Tit>
              {
                entryYearStat && entryYearStat.brokenLine && entryYearStat.brokenLine.dimNames.length ?
                  <ECharts
                    style={{ height: all ? 320 : 230, width: 540, marginTop: all ? 30 : 20 }}
                    className="OfflineGrowthTrend_charts"
                    option={options.getBar(entryYearStat)}
                  /> : <div style={{ height: all ? 320 : 230, width: 520 }} className="tc flex-h-center"><img src={empty}></img></div>
              }

            </div>
            <div className='common-v-line' style={{ width: 530, marginBottom: 20, marginTop: 10 }}></div>
            <div style={{ marginTop: -30 }}>
              <div className='flex-h-between' >


                {
                  entryEmpPropertyStat.eduPie && entryEmpPropertyStat.eduPie.length ?
                    <ECharts
                      style={{ height: all ? 240 : 220, width: 400, marginLeft: -30 }}
                      option={options.getPie(entryEmpPropertyStat.eduPie.filter(d => d.name.includes('本科') || d.name.includes('硕士') || d.name.includes('博士')), '学历分布',
                        ['#91cc76', '#00dbff', '#fac459'], {
                        left: window.screen.width == 1280 || window.screen.width == 1920 || window.screen.width == 1536 ? '16%' : '16%',
                        line: 3,
                        right: all ? '-3%' : '-3%'
                      })}

                    /> : <div style={{ height: 240, width: 330, }} className="tc  flex-h-center"><img src={empty}></img></div>
                }
                {
                  entryEmpPropertyStat.agePie && entryEmpPropertyStat.agePie.length ?
                    <ECharts
                      style={{ height: all ? 240 : 220, width: 400, marginLeft: -20 }}
                      option={options.getPie(entryEmpPropertyStat.agePie.filter(d => d.name.includes('70') || d.name.includes('80') || d.name.includes('90')),
                        '年龄分布', ['#00dbff', '#91cc76', '#fac459'], {
                        left: window.screen.width == 1280
                          || window.screen.width == 1920 || window.screen.width == 1536 ? '16%' : '16%',
                        right: all ? '-14%' : '-10%',
                      })}

                    /> : <div style={{ height: 240, width: 320 }} className="tc  flex-h-center"><img src={empty}></img></div>
                }
              </div>
              <div className='common-v-line' style={{ width: 530, marginBottom: -0, marginTop: all ? 30 : 0 }}></div>
              <div className='flex-h-between' >
                {
                  entryEmpPropertyStat.funcPie && entryEmpPropertyStat.funcPie.length ?
                    <ECharts
                      style={{ height: all ? 240 : 210, width: 330, marginLeft: -40, }}
                      option={options.getPie(entryEmpPropertyStat.funcPie, '职能管理', '', {
                        left: '18%',
                        right: '5%',
                      })}

                    /> : <div style={{ height: all ? 240 : 210, width: 320 }} className="tc  flex-h-center"><img src={empty}></img></div>
                }

                <div className='home-content-center-statistics-right mr40'>
                  {
                    entryEmpPropertyStat.menWomenNum ?
                      <div>
                        <div className='flex-h-center'>
                          <img className='mr10' src={man}></img>
                          <div className="common-text">
                            <span><b className='man'>{((Number(entryEmpPropertyStat.menWomenNum.menNum) / (
                              Number(entryEmpPropertyStat.menWomenNum.menNum) + Number(entryEmpPropertyStat.menWomenNum.womenNum)
                            )) * 100).toFixed(1)}</b>%</span>
                            <span><CountUp
                              end={entryEmpPropertyStat.menWomenNum.menNum}
                              duration={2.75}
                              decimals={0}
                              decimal="."
                              suffix=""
                            />人</span>
                            <div className='bar'>
                              <div className='progress man' style={{
                                width: `${(Number(entryEmpPropertyStat.menWomenNum.menNum) / (
                                  Number(entryEmpPropertyStat.menWomenNum.menNum) + Number(entryEmpPropertyStat.menWomenNum.womenNum)
                                )).toFixed(1) * 100}%`
                              }}></div>
                            </div>
                          </div>
                        </div>
                        <div className='flex-h-center mt20'>
                          <img className='mr10' src={woman}></img>
                          <div className="common-text">
                            <span><b className='woman'>{((Number(entryEmpPropertyStat.menWomenNum.womenNum) / (
                              Number(entryEmpPropertyStat.menWomenNum.menNum) + Number(entryEmpPropertyStat.menWomenNum.womenNum)
                            )) * 100).toFixed(1)}</b>%</span>
                            <span><CountUp
                              end={entryEmpPropertyStat.menWomenNum.womenNum}
                              duration={2.75}
                              decimals={0}
                              decimal="."
                              suffix=""
                            />人</span>
                            <div className='bar'>
                              <div className='progress woman' style={{
                                width: `${(Number(entryEmpPropertyStat.menWomenNum.womenNum) / (
                                  Number(entryEmpPropertyStat.menWomenNum.menNum) + Number(entryEmpPropertyStat.menWomenNum.womenNum)
                                )).toFixed(1) * 100}%`
                              }}></div>
                            </div>
                          </div>
                        </div>
                      </div> : <div style={{ height: 230, width: 320 }} className="tc  flex-h-center"><img src={empty}></img></div>
                  }

                </div>
              </div>
            </div>
          </div>
          <div className='common-h-line' style={{ height: 824, marginLeft: 30, marginRight: 30 }}></div>
          <div className={`home-content-center w${window.screen.width}`} style={{ width: 710 }}>
            <div className='flex-h-between' style={{ position: 'relative', top: -40, marginBottom: -30 }}>
              <Tab dataQualityInfo={this.state.dataQualityInfo} onChange={(e) => {
                this.setState({
                  level: e,
                }, () => {
                  this._init()
                })
              }} onChangeDate={(e) => {
                this.setState({
                  statisMonth: e,
                }, () => {
                  this._init()
                })
              }}></Tab>
            </div>


            <div className='home-content-center-user'>
              <div className='home-content-center-liu'>
                <div className='home-content-center-liu-left'>
                  <div style={{ marginTop: all ? all1 ? -50 : -30 : -70 }}>
                    <div className='flex-h-start'>
                      <div className={`common-text w${window.screen.width}`}>
                        <span>入职总员工</span>
                        <div> <b><CountUp
                          end={overviewStat.newNum}
                          duration={2.75}
                          decimals={0}
                          decimal="."
                          suffix=""
                          className='colg'
                        /></b>
                          <span>人</span></div>
                      </div>
                      <div className={`common-h-line w${window.screen.width}`} style={{ height: 53, marginLeft: 10, marginRight: 10 }}></div>
                      <ECharts
                        style={{ height: 220, width: 220, marginRight: -30, marginLeft: -30, position: 'relative', top: 20 }}
                        option={options.getGauge(overviewStat.newRatio, '新进率')}

                      />
                    </div>

                    <div className='flex-h-start' style={{ marginTop: -40 }}>
                      <div className={`common-text w${window.screen.width}`}>
                        <span>入职平均年龄</span>
                        <div> <b><CountUp
                          end={overviewStat.newAvgAge}
                          duration={2.75}
                          decimals={1}
                          decimal="."
                          suffix=""
                          className='colg'
                        /></b>
                          <span>岁</span></div>
                      </div>
                      <div className={`common-h-line w${window.screen.width}`} style={{ height: 53, marginLeft: 10, marginRight: 10 }}></div>
                      <div className={`common-text w${window.screen.width}`} style={{ marginLeft: 12 }}>
                        <span>入职985/211</span>
                        <div> <b><CountUp
                          end={overviewStat.new211985Num}
                          duration={2.75}
                          decimals={0}
                          decimal="."
                          suffix=""
                          className='colg'
                        /></b>
                          <span>人</span></div>
                      </div>
                    </div>
                  </div>
                  <div className={`common-h-line w${window.screen.width}`} style={{ height: 190, marginLeft: 10, marginRight: 20 }}></div>
                  <div style={{ marginTop: all ? all1 ? -40 : -20 : -60 }}>
                    <div className='flex-h-start'>
                      <div className={`common-text w${window.screen.width}`}>
                        <span>离职总员工</span>
                        <div> <b><CountUp
                          end={overviewStat.dimisNum}
                          duration={2.75}
                          decimals={0}
                          decimal="."
                          suffix=""
                          className='colp'
                        /></b>
                          <span>人</span></div>
                      </div>
                      <div className={`common-h-line w${window.screen.width}`} style={{ height: 53, marginLeft: 10, marginRight: 10 }}></div>
                      <ECharts
                        style={{ height: 220, width: 220, marginRight: -30, marginLeft: -30, position: 'relative', top: 20 }}
                        option={options.getGauge(overviewStat.dimisRatio, '离职率', '#EE6666')}

                      />
                    </div>
                    <div className='flex-h-start' style={{ marginTop: -40 }}>
                      <div className={`common-text w${window.screen.width}`}>
                        <span>离职平均司龄</span>
                        <div> <b><CountUp
                          end={overviewStat.dimisCompAge}
                          duration={2.75}
                          decimals={1}
                          decimal="."
                          className='colp'
                          suffix=""
                        /></b>
                          <span>年</span></div>
                      </div>
                      <div className={`common-h-line w${window.screen.width}`} style={{ height: 53, marginLeft: 10, marginRight: 20 }}></div>
                      <div className={`common-text w${window.screen.width}`}>
                        <span>离职高绩效员工</span>
                        <div> <b><CountUp
                          end={overviewStat.dimisHighPerfNum}
                          duration={2.75}
                          decimals={0}
                          decimal="."
                          className='colp'
                          suffix=""
                        /></b>
                          <span>人</span></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div className='common-v-line' style={{ width: '100%', marginBottom: 20, marginTop: -10 }}></div>
            <div className='home-content-center-pie' style={{ marginTop: 0 }}>
              <Tit text="员工流动情况"></Tit>

              <div className='flex-h-between mt10'>
                {
                  flowStat.orgFlow && flowStat.orgFlow.length ?
                    <Chord {...chart.getChort(flowStat.orgFlow)} width={all ? 190 : 120} height={all ? 190 : 120} autoFit={true}></Chord> :
                    <div style={{ height: all ? 190 : 120, width: '50%', paddingTop: 50 }} className="tc  flex-h-center"><img src={empty}></img></div>
                }
                {
                  flowStat.funcFlow && flowStat.funcFlow.length ?
                    <Chord {...chart.getChort(flowStat.funcFlow)} width={all ? 190 : 120} height={all ? 190 : 120}></Chord> :
                    <div style={{ height: all ? 190 : 120, width: '50%', paddingTop: 50 }} className="tc  flex-h-center"><img src={empty}></img></div>
                }


              </div>

              <div className='common-v-line' style={{ width: '100%', marginBottom: -20, marginTop: 20 }}></div>
              <div className='mt30'>
                <h3 className='common-title'>员工净增减趋势</h3>
                <div className='mt10'>
                  {
                    onJobCleanPlusMinusStat.dimNames && onJobCleanPlusMinusStat.dimNames.length ?
                      <ECharts
                        style={{ height: all ? 250 : 190, with: 460 }}
                        className="OfflineGrowthTrend_charts"
                        option={options.getLineChart(onJobCleanPlusMinusStat, 1)}
                      /> : <div style={{ height: all ? 250 : 190, width: 460 }} className="tc  flex-h-center"><img src={empty}></img></div>
                  }
                </div>

              </div>

            </div>
          </div>
          <div className='common-h-line' style={{ height: 824, marginLeft: 0, marginRight: 25 }}></div>
          <div className={`home-content-right w${window.screen.width}`}>
            <div className='mt0'>
              <Tit text="员工离职情况"></Tit>
              <div className='mt10'>
                {
                  dimissionYearStat && dimissionYearStat.brokenLine && dimissionYearStat.brokenLine.dimNames.length ?
                    <ECharts
                      style={{ height: all ? 320 : 220, width: 560, marginTop: all ? 20 : 10 }}
                      className="OfflineGrowthTrend_charts"
                      option={options.getBar(dimissionYearStat)}
                    /> : <div style={{ height: all ? 320 : 220, width: 560 }} className="tc  flex-h-center"><img src={empty}></img></div>
                }
              </div>
            </div>
            <div className='mt10 home-content-center-user' style={{ marginTop: 0, height: all1 ? 270 : 290 }}>
              <Tit text="自愿离职原因"></Tit>
              <div className='flex-h-between mt20'>
                {
                  dimissionReasonEduStat.reasons && dimissionReasonEduStat.reasons.length ?
                    <WordCloud {...chart.getWord(dimissionReasonEduStat.reasons)} width={250} height={all ? all1 ? 220 : 240 : 200} /> :
                    <div style={{ height: all ? all1 ? 220 : 240 : 200, width: 240 }} className="tc  flex-h-center"><img src={empty}></img></div>
                }

                {
                  dimissionReasonEduStat.eduPie && dimissionReasonEduStat.eduPie.length ?
                    <ECharts
                      style={{ height: all ? all1 ? 240 : 260 : 220, width: 380, marginLeft: -10, marginTop: -40 }}
                      option={options.getPie(dimissionReasonEduStat.eduPie.filter(d => d.name.includes('本科') || d.name.includes('硕士') || d.name.includes('博士')), '学历分布', ['#00dbff', '#f27132', '#91cc76', '#353e7c', '#fac459'], {
                        left: '20%',
                        tooltip: 'left',
                        right: all ? '15%' : '15%',
                        line: 3
                      })}
                    /> : <div style={{ height: all ? all1 ? 240 : 260 : 220, width: 380 }} className="tc  flex-h-center"><img src={empty}></img></div>
                }
              </div>
            </div>
            <div className='common-v-line' style={{ width: 560, marginBottom: -10, marginTop: all ? 0 : -30 }}></div>
            <div className='mt10 home-content-center-user' style={{ marginTop: all ? 20 : 0, paddingRight: 15 }}>
              <h3 className='common-title'>年龄&司龄分布</h3>
              {
                dimissionAgeCompStat.scatters && dimissionAgeCompStat.scatters.length ?
                  <ECharts
                    style={{ height: all ? all1 ? 240 : 260 : 190, width: '100%', marginTop: -30 }}
                    className="OfflineGrowthTrend_charts"
                    option={options.getScatter(dimissionAgeCompStat.scatters)}
                  /> : <div style={{ height: all ? all1 ? 240 : 260 : 190, width: '100%' }} className="tc  flex-h-center"><img src={empty}></img></div>
              }

            </div>
          </div>
        </div>

      </div >
    );
  }
}

export default Index;