import React, { Component } from 'react';
import moment from 'moment';
import './common/scss/nav-title.scss';
let pages = []
export default class nav extends Component {
  constructor(props) {
    super();
    this.state = {
      route: props.type == 1 ? [] : [
        { name: '员工概况', path: '/p2' },
        { name: '招聘', path: '/p3' },
        { name: '总览', path: '/p1' },
        
        { name: '人效', path: '/p5' },
        { name: '人员流动', path: '/p4' },
      ],
      time1: moment().format('YYYY/MM/DD')
    };
  }

  componentDidMount() {
    pages = localStorage.pages ? this.state.route.filter(d => JSON.parse(localStorage.pages).map(v => v.url).includes(d.path)) : this.state.route
    setTimeout(() => {
      if (window.location.pathname != '/' && !pages.map(d => d.path).includes(window.location.pathname)) {
        window.history.back()
      }
    }, 100)
  }
  componentWillUnmount() {

  }


  render() {

    return (
      <div className={`nav w${window.screen.width}`}>
        <div className="nav-link-box">
          {this.state.route.map((item, index) => {
            return (
              <a
                className={`nav-link ${!pages.map(d => d.path).includes(item.path) ? 'disabled' : ''} ${window.location.pathname == item.path ? 'nav-link-active' : 'nav-link-normal'}`}
                key={index}
                onClick={() => {
                  window.location.href = item.path;
                }}>{item.name}</a>
            );
          })}
        </div>
      </div>
    );
  }
}
