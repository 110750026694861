import React, { Component, Fragment } from 'react';
import * as echarts from 'echarts'
export default class ECharts extends Component {
  componentDidMount() {
    this.charts = echarts.init(this.n);
    this.charts.setOption(this.props.option);
    if (this.props.onClickEcharts) {
      this.charts.on("click", this.props.onClickEcharts);
    }
    this.charts.on("legendselectchanged", function (obj) {
      //  legend点击事件
      if (obj.name == '入职人数' || obj.name == '离职人数') {
        let option = this.getOption(), arr = [];
        for (let i in obj.selected) {
          arr.push(obj.selected[i])
        }
        if (arr.filter(v => v).length != 2) {
          option.series[0].itemStyle.barBorderRadius = [6, 6, 6, 6]
          option.series[0].itemStyle.borderRadius = [6, 6, 6, 6]
          option.series[1].itemStyle.barBorderRadius = [6, 6, 6, 6]
          option.series[1].itemStyle.borderRadius = [6, 6, 6, 6]
        } else {
          option.series[0].itemStyle.barBorderRadius = [6, 6, 0, 0]
          option.series[0].itemStyle.borderRadius = [6, 6, 0, 0]
          option.series[1].itemStyle.barBorderRadius = [0, 0, 6, 6]
          option.series[1].itemStyle.borderRadius = [0, 0, 6, 6]
        }
        this.setOption(option);
      }
    });
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.option) {
      this.charts.setOption(nextProps.option)
    }
  }
  render() {
    const { option, style } = this.props;
    const series = option ? option.series : {};
    const hasData = series && ((series[0] && series[0].data && series[0].data.length) || (series.data && series.data.length));

    return (
      <Fragment>
        <div
          className={this.props.className}
          style={{ ...style, ...(!hasData ? { display: 'none' } : {}) }}
          ref={(n) => (this.n = n)}
        />
        {/* {!hasData ? (
          <div className={this.props.className} style={{ position: 'absolute', background: 'transparent', textAlign: 'center', color: '#FFF', ...style }}>
            <img src={require('../assets/img/empty.png')} style={{ height: '60%', marginTop: '12%' }} alt="" />
          </div>
        ) : null} */}
      </Fragment>
    );
  }
}
