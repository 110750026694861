import { Pie, G2 } from '@ant-design/plots';
import shan from '../../assets/shan.png'
const options = {
    getPie(data, name) {
        return {
            appendPadding: 10,
            data,
            angleField: 'value',
            colorField: 'type',
            radius: 1,
            innerRadius: 0.8,
            label: {
                type: 'outer',
                content: '{name} {percentage}',
            },
            // pieStyle: {
            //     stroke: '#1b0e5a',
            //     lineWidth: 2,
            //     lineDash: [0, 0],
            //     strokeOpacity: 0.3,
            // },
            interactions: [
                {
                    type: 'pie-legend-active',
                },
                {
                    type: 'element-active',
                },
            ],
        }
        // return {
        //     appendPadding: 0,
        //     padding:50,
        //     data,
        //     angleField: 'value',
        //     colorField: 'type',
        //     radius: 1,
        //     legend: false,
        //     innerRadius: 0.8,
        //     meta: {
        //         value: {
        //             formatter: (v) => `${v} ¥`,
        //         },
        //     },
        //     pieStyle: {
        //         stroke: '#1b0e5a',
        //         lineWidth: 2,
        //         lineDash: [0, 0],
        //         strokeOpacity: 0.3,
        //     },
        //     label: {
        //         type: 'outer',
        //         content: '{name} {percentage}',
        //     },
        //     statistic: {
        //         title: {
        //             offsetY: 5,
        //             customHtml: (container, view, datum) => {
        //                 return `<div style="color:#00DBFF;font-size:16px;font-weight:bold">${name}</div>`
        //             },
        //         },
        //         content: {
        //             offsetY: 4,
        //             style: {
        //                 fontSize: '1px',
        //             },
        //             customHtml: (container, view, datum, data) => {
        //                 return ''
        //             },
        //         },
        //     },
        //     // 添加 中心统计文本 交互
        //     interactions: [
        //         {
        //             type: 'pie-legend-active',
        //           },
        //           {
        //             type: 'element-active',
        //           },
        //     ],
        // };
    },
    getChort(data) {
        const G = G2.getEngine('canvas');

        return {
            data: data,
            padding:[5, 5, 5, 5],
            
            // theme: {
            //     colors10: ['#FF6B3B', '#626681', '#FFC100', '#9FB40F', '#76523B', '#DAD5B5', '#0E8E89', '#E19348', '#F383A2', '#247FEA']
            //   },
            renderer:'svg',
            sourceField: 'source',
            targetField: 'target',
            weightField: 'value',
        }
    },
    getWord(data) {
        return {
            data,
            wordField: 'name',
            weightField: 'value',
            colorField: 'name',
            // imageMask: shan,
            wordStyle: {
                fontFamily: 'Verdana',
                fontSize: [12, 32],
            },
        }
    }
}

export default options;