import React, { Component } from 'react';
import "./map.scss";
import _ from 'lodash';
import * as echarts from 'echarts';
import 'echarts-gl';
import geoJson from "./100000_full.json"
import { cqRequest } from '../tools/http';
let myChart = null
let count = 0
export default class SmartSearch extends Component {
  constructor() {
    super();

    this.state = {
      filterList: ['0人', '1~100人', '100~500人', '500~1000人', '1000~5000人'],
      currentData: {
        remark: []
      },
      datas: [],
      region: '全国',
      allDatas: [],
      visible: false,
      regionOrgFunc: false,
      regionOrgFuncEmpStat: {},
      total1: 0,
      ratio1: 0,
      total: 0,
      ratio: 0,
      level: 'SGC',
      loading: false
    };
  }
  componentDidMount() {
    this._start()

    if (this.props.type == 3) {
      setTimeout(() => {
        this._region()
      }, 100)
    }
  }
  _start = () => {
    const { datas, type } = this.props
    let tempArr = [], newArr = []
    if (type == 2) {
      for (let i = 0; i < datas.length; i++) {
        if (tempArr.indexOf(datas[i].regionName) === -1) {
          newArr.push({
            regionName: datas[i].regionName,
            list: [datas[i]]
          })
          tempArr.push(datas[i].regionName);
        } else {
          for (let j = 0; j < newArr.length; j++) {
            if (newArr[j].regionName == datas[i].regionName) {
              newArr[j].list.push(datas[i])
            }
          }
        }
      }
    }
    newArr.forEach(d => {
      d.value = Number(_.sum(d.list.map(d => d.waitNum)))
      d.name = d.regionName
    })
    let list = type == 2 ? newArr : datas

    list.forEach(d => {
      let _value = d.value;
      d._type = _value < 5000 && _value >= 1000 ? '1000~5000人' :
        _value < 1000 && _value >= 500 ? '500~1000人' :
          _value < 500 && _value >= 100 ? '100~500人' :
            _value < 100 && _value >= 1 ? '1~100人' : '0人'
    })
    this.setState({
      datas: list,
      allDatas: list,
      regionOrgFunc: type == 3 ? true : false
    }, () => {
      this._init(list)
    })
  }
  _init = (datas) => {
    const { type } = this.props
    myChart = echarts.init(document.getElementById('container'));
    // JSON文件(地图数据)路径
    var loadedDataURL = '';
    let cityCode = '';

    loadedDataURL = `./100000_full.json`;
    cityCode = 'china';
    // 显示加载动画效果,可以在加载数据前手动调用该接口显示加载动画，在数据加载完成后调用 hideLoading 隐藏加载动画。
    myChart.showLoading();
    let mapName = cityCode;
    const data = geoJson.features.map((item, index) => {
      const geoAreaName = item.properties.name;
      let _value = 0, _remark = []
      datas.forEach(d => {
        if (d.name.includes(geoAreaName)) {
          _value = d.value
          _remark = d.list || []
        }
      })
      // geo文件中的地理名称
      return {
        name: geoAreaName,
        value: _value,
        remark: _remark,
        label: {
          show: true,
          formatter: function (params) {
            return params.name.replace(/省/g, '') || ' ';
          },
          position: geoAreaName.includes('北京') || geoAreaName.includes('天津')
            || geoAreaName.includes('香港') ? 'right' : geoAreaName.includes('河北') ||
              geoAreaName.includes('澳门') ? 'left' : 'center',

          textStyle: {
            color: '#fff',
            fontWeight: 'normal',
            fontSize: geoAreaName.includes('北京') || geoAreaName.includes('天津') || geoAreaName.includes('澳门')
              || geoAreaName.includes('香港') ? 12 : 14,
          },


          emphasis: {//对应的鼠标悬浮效果
            show: true,
            textStyle: {
              color: "#000", fontWeight: 'bold',
              fontSize: geoAreaName.includes('北京') || geoAreaName.includes('天津') || geoAreaName.includes('澳门')
                || geoAreaName.includes('香港') ? 12 : 14,
            },
            position: geoAreaName.includes('北京') || geoAreaName.includes('天津')
              || geoAreaName.includes('香港') ? 'right' : geoAreaName.includes('河北') || geoAreaName.includes('澳门') ? 'left' : 'center',
          }

          // show: true, //是否显示市
        },
        colors: {
          color: _value < 5000 && _value >= 1000 ? '#920AC0' :
            _value < 1000 && _value >= 500 ? '#450AC0' :
              _value < 500 && _value >= 100 ? '#0A4AC0' :
                _value < 100 && _value >= 1 ? '#0A8DC0' : '#1C0B58'
        },
        itemStyle: {                // 单个区域的样式设置
          color:

            _value < 5000 && _value >= 1000 ? '#920AC0' :
              _value < 1000 && _value >= 500 ? '#450AC0' :
                _value < 500 && _value >= 100 ? '#0A4AC0' :
                  _value < 100 && _value >= 1 ? '#0A8DC0' : '#1C0B58'
          ,
        },
      }
    })
    // 引入JSON文件
    echarts.registerMap(mapName, geoJson);

    // 隐藏动画加载效果。
    myChart.hideLoading();

    // 图表配置项
    var option = {

      tooltip: { // 提示框
        show: type == 2 || type == 3 ? false : true,
        trigger: 'item',
        formatter: function (params) {
          let res = '';
          if (type == 1) {
            return `<div class="popTooltip flex-h-start common-text"><ul><li><span>${params.name}：</span><b>${params.value || 0}</b><span>人</span></li></ul></div>`
          } else {
            return res
          }

        },
        borderWidth: 0,
        borderColor: 'transparent',
        backgroundColor: 'transparent',
        extraCssText: 'box-shadow: 0px 0px 8px 0px rgba(81, 85, 251, 0);',
      },

      series: [{
        name: 'map3D',
        type: "map3D", // map3D / map
        zoom: 1.5,
        map: mapName,
        select: {
          areaColor: "#FFF",
          shadowOffsetY: 5
        },
        boxDepth: 100, //地图倾斜度
        // regionHeight: 3, //地图厚度
        left: this.props.left || 0,
        top: this.props.top || 0,
        light: {
          main: {
            intensity: 1.5
          }
        },
        viewControl: {
          //X轴绕
          alpha: 70,
          //y轴绕

          distance: this.props.distance || 108,
          panMouseButton: 'left', //平移操作使用的鼠标按键
          rotateMouseButton: 'right', //旋转操作使用的鼠标按键
        },



        itemStyle: {
          opacity: 1, // 透明度
          borderWidth: 1, //分界线宽度
          borderColor: "#1B125C", //分界线颜色
          color: "#ffffff"
        },
        emphasis: {
          itemStyle: {
            color: "#FAC459",
            borderWidth: 5,
            borderColor: '#ff0000'
          },
        },
        // shading: 'realistic',
        data: data,
      }],
    };

    // 设置图表实例的配置项以及数据，万能接口，所有参数和数据的修改都可以通过setOption完成，ECharts 会合并新的参数和数据，然后刷新图表。
    myChart.setOption(option);

    myChart.on('click', (params) => {
      if (count == 1) return
      count = 1
      this.setState({
        region: params.name == this.state.region ? '全国' : params.name,
        regionOrgFunc1: params.name == this.state.region ? false : this.state.regionOrgFunc1
      }, () => {
        if (type == 2) {
          this.setState({
            currentData: params.data,
            visible: true,
            offsetX: params.event.offsetX,
            offsetY: params.event.offsetY
          })
        }
        for (let i = 0; i < data.length; i++) {
          data[i].itemStyle = {
            color: data[i].colors.color,
          }
          data[i].label = {
            color: '#fff',
            show: true,
            formatter: function (params) {
              return data[i].name.replace(/省/g, '') || ' ';
            },
            position: data[i].name.includes('北京') || data[i].name.includes('天津')
              || data[i].name.includes('香港') ? 'right' : data[i].name.includes('河北') || data[i].name.includes('澳门') ? 'left' : 'center',

            textStyle: {
              color: '#fff',
              fontWeight: 'normal',
              fontSize: data[i].name.includes('北京') || data[i].name.includes('天津') || data[i].name.includes('澳门')
                || data[i].name.includes('香港') ? 12 : 14,
            },
            emphasis: {//对应的鼠标悬浮效果
              show: true,
              textStyle: {
                color: "#000", fontWeight: 'bold',
                fontSize: data[i].name.includes('北京') || data[i].name.includes('天津') || data[i].name.includes('澳门')
                  || data[i].name.includes('香港') ? 12 : 14,
              },
              position: data[i].name.includes('北京') || data[i].name.includes('天津')
                || data[i].name.includes('香港') ? 'right' : data[i].name.includes('河北') || data[i].name.includes('澳门') ? 'left' : 'center',
              offsetY: data[i].name.includes('河北') ? 90 : 0,
            }
          }

          if (params.name === data[i].name && this.state.region == params.name) {
            data[i].itemStyle = {
              color: '#FAC459',
              borderWidth: 5, //分界线宽度
              borderColor: '#fff'
            }
            data[i].label = {
              show: true,
              formatter: function (params) {
                return params.name.replace(/省/g, '') || ' ';
              },
              position: params.name.includes('北京') || params.name.includes('天津')
                || params.name.includes('香港') ? 'right' : params.name.includes('河北') || params.name.includes('澳门') ? 'left' : 'center',

              textStyle: {
                color: '#000',
                fontWeight: 'bold',
                fontSize: params.name.includes('北京') || params.name.includes('天津') || params.name.includes('澳门')
                  || params.name.includes('香港') ? 12 : 14,
              },

              emphasis: {//对应的鼠标悬浮效果
                show: true,
                textStyle: {
                  color: "#000", fontWeight: 'bold',
                  fontSize: params.name.includes('北京') || params.name.includes('天津') || params.name.includes('澳门')
                    || params.name.includes('香港') ? 12 : 14,
                },
                position: params.name.includes('北京') || params.name.includes('天津')
                  || params.name.includes('香港') ? 'right' : params.name.includes('河北') || params.name.includes('澳门') ? 'left' : 'center',
                offsetY: params.name.includes('河北') ? 90 : 0,
              }
            }
          }
        }

        myChart.setOption(option);
        if (type == 3) {
          this._region()
        }
      })
      setTimeout(() => {
        count = 0
      }, 500)
    });

  }
  _region = () => {
    const { region } = this.state
    let str = region != '全国' ? `&region=${region}` : ''
    // cqRequest(`/common/regionOrgFuncEmpStat?level=${this.props.level}&statisMonth=${this.props.statisMonth}`).then((res) => {// 统计数据
    //   if (res.data.orgGroup.length) {
    //     this.setState({
    //       regionOrgFuncEmpStat: res.data,
    //       regionOrgFunc: true,
    //       total: res.data.total,
    //       ratio: res.data.ratio
    //     })
    //   } else {
    //     this.setState({
    //       regionOrgFunc: false
    //     })
    //   }
    // })
    // if (region != '全国') {

    // }
    cqRequest(`/common/regionOrgFuncEmpStat?level=${this.props.level}${str}&statisMonth=${this.props.statisMonth}`).then((res) => {// 统计数据
      if (res.data.orgGroup.length) {
        this.setState({
          regionOrgFuncEmpStat1: res.data,
          regionOrgFunc1: true,
          total1: res.data.total,
          ratio1: res.data.ratio
        })
      } else {
        this.setState({
          regionOrgFunc1: false,
        })
      }
    })
  }
  filter = (d) => {
    const { allDatas, filterList } = this.state
    if (filterList.includes(d)) {
      this.setState({
        filterList: filterList.filter(e => e != d),
      })
    } else {
      this.setState({
        filterList: [...filterList, d]
      })
    }
    setTimeout(() => {
      this.setState({
        datas: allDatas.filter(d => this.state.filterList.includes(d._type))
      }, () => {
        this._init(this.state.datas)
      })
    }, 100)
  }


  render() {
    const { width, height, top, bottom, right, type } = this.props
    const { visible, currentData, regionOrgFunc, regionOrgFuncEmpStat, regionOrgFuncEmpStat1, regionOrgFunc1 } = this.state
    let all = document.documentElement.clientHeight == 720 ||
      document.documentElement.clientHeight == 864 ||
      document.documentElement.clientHeight == 1080 ||
      document.documentElement.clientHeight == 818 ||
      document.documentElement.clientHeight == 850 ||
      document.documentElement.clientHeight == 960 ||
      document.documentElement.clientHeight == 610 ||
      (document.documentElement.clientHeight == 578 && document.documentElement.clientWidth != 1280) ||
      document.documentElement.clientHeight == 681 ||

      document.documentElement.clientHeight == 781 ||
      document.documentElement.clientHeight == 823 ||
      document.documentElement.clientHeight == 1042 ||

      document.documentElement.clientHeight == 1010 ||
      document.documentElement.clientHeight == 1152
      ? true : false
    let all1 = (document.documentElement.clientHeight == 578 && document.documentElement.clientWidth != 1280) ?
      true : false
    return (
      <div className="map">
        {/* <div className="bg"></div> */}

        <div id='container' ref={n => this.n = n}
          style={{ width: width || '100%', height: height || all ? 800 : 770, marginTop: top || -70 }}></div>
        <div className="filter" style={{ bottom: "-30px", right: right || 10 }}>
          {
            type == 2 ? <h3 className='fz14 colb mb10'>待招需求</h3> : null
          }
          <div>
            <span className={`dot color0`}></span>
            选中状态
          </div>
          {
            ['0人', '1~100人', '100~500人', '500~1000人', '1000~5000人'].map((d, index) => (
              <div onClick={() => {
                this.filter(d)
              }} className={`${!this.state.filterList.includes(d) ? 'hidden' : ''}`}>
                <span className={`dot color${index + 1}`}></span>
                {d}
              </div>
            ))
          }
        </div>
        {
          visible ? <div style={{
            left: this.state.offsetX || 'auto',
            top: this.state.offsetY || 170,
          }} class="popcontenthr flex-h-start common-text" onClick={() => this.setState({ visible: false })}>
            <h3>{currentData.name}</h3>
            <h4>招聘需求 / <b>已完成</b></h4>
            <ul>
              {
                currentData.remark.map(d => (
                  <li >
                    <span>{d.orgName}：</span>
                    <div>
                      <span>{d.needNum} /</span>
                      <b>{d.offerNum}</b>
                    </div>
                  </li>
                ))
              }
            </ul>
          </div> : null
        }
        {/* {
          regionOrgFunc ? <div style={{
            position: 'absolute',
            left: '-10px',
            top: '450px'
          }} className="popcontent flex-h-start common-text" onClick={() => this.setState({ regionOrgFunc: false })}>

            <ul>
              {
                regionOrgFuncEmpStat.funcGroup && regionOrgFuncEmpStat.funcGroup.length && regionOrgFuncEmpStat.funcGroup.map(d => (
                  <li >
                    <span>{d.name}：</span>
                    <b>{d.value}</b>
                    <span>人</span>
                  </li>
                ))
              }
            </ul>
            <div className="common-h-line" style={{ height: 130, marginLeft: 20, marginRight: 20 }}></div>
            <ul>
              {
                regionOrgFuncEmpStat.orgGroup && regionOrgFuncEmpStat.orgGroup.length && regionOrgFuncEmpStat.orgGroup.map(d => (
                  <li >
                    <span>{d.name}：</span>
                    <b>{d.value}</b>
                    <span>人</span>
                  </li>
                ))
              }
            </ul>
            <h3><span>全国:</span><b>{this.state.total}</b><span>人</span></h3>
          </div> : null
        } */}
        {
          regionOrgFunc1 ? <div style={{
            position: 'absolute',
            left: '0px',
            bottom: '-30px'
          }} className="popcontent flex-h-start common-text" onClick={() => this.setState({ regionOrgFunc1: false })}>

            {/* <ul>
              {
                regionOrgFuncEmpStat1.funcGroup && regionOrgFuncEmpStat1.funcGroup.length && regionOrgFuncEmpStat1.funcGroup.map(d => (
                  <li >
                    <span>{d.name}：</span>
                    <b>{d.value}</b>
                    <span>人</span>
                  </li>
                ))
              }
            </ul>
            <div className="common-h-line" style={{ height: 130, marginLeft: 20, marginRight: 20 }}></div> */}
            <ul>
              {
                regionOrgFuncEmpStat1.orgGroup && regionOrgFuncEmpStat1.orgGroup.length && regionOrgFuncEmpStat1.orgGroup.map(d => (
                  <li className='flex-h-center'>
                    <div style={{ width: '75%', display: 'flex' }}>
                      <span style={{ width: 200 }}>{d.name === 'HQ' ? 'HQ(含RRP&KA)' : d.name}：</span>
                      <b style={{ textAlign: 'center' }}>{d.value}</b>
                      <span>人</span>
                    </div>
                    <span style={{ width: 100, textAlign: 'right' }}>{parseFloat(((d.value / this.state.total1) * 100)).toFixed(1)}%</span>
                  </li>
                ))
              }
            </ul>
            <h3><span>{this.state.region}:</span><b>{this.state.total1}</b><span>人</span>
              {
                this.state.region != '全国' ?
                  <><span className='ml10'>占比</span><b>{this.state.ratio1}</b><span>%</span></> : null
              }
            </h3>
          </div> : null
        }
      </div>
    );
  }
}
