import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import Main from './pages/home/index.js';
import Renxiao from './pages/home/rx.js';
import Zuzhiguanli from './pages/home/zz.js';
import Liudong from './pages/home/ld.js';
import Zhaoping from './pages/home/zp.js';
import Login from './pages/Login/index';
import  DevicePixelRatio  from './tools/devicePixelRatio'
new DevicePixelRatio().init();
const BasicRoute = () => (
  <BrowserRouter>
    <Route exact path="/p1" component={Main} />
    <Route exact path="/p2" component={Zuzhiguanli} />
    <Route exact path="/p3" component={Zhaoping} />
    <Route exact path="/p4" component={Liudong} />
    <Route exact path="/p5" component={Renxiao} />
    <Route exact path="/" component={Login} />
  </BrowserRouter>
);

export default BasicRoute;
