import { cqRequest } from './http';
import api from './config';

// 设置token
export function setToken(token) {
  localStorage.setItem("token", token);
}

// 获取token
export function getToken() {
  return localStorage.getItem("token") ? localStorage.getItem("token") : "";
}


export function getTokenItme(key) {
  return localStorage.getItem("tokenData") ? JSON.parse(localStorage.getItem("tokenData"))[key] : '';
}

//缓存
const cache = {};

export const getUrlBase64 = (url, ext, callback) => {
  var canvas = document.createElement("canvas");   //创建canvas DOM元素
  var ctx = canvas.getContext("2d");
  var img = new Image;
  img.crossOrigin = 'Anonymous';
  img.src = url;
  img.width = 19;
  img.height = 19;
  img.onload = function () {
    canvas.height = img.width; //指定画板的高度,自定义
    canvas.width = img.height; //指定画板的宽度，自定义
    ctx.drawImage(img, 0, 0, img.width, img.height); //参数可自定义
    var dataURL = canvas.toDataURL("image/" + ext);
    callback.call(this, dataURL); //回掉函数获取Base64编码
    canvas = null;
  };
}

export const sizeFunction = (x) => {
  var y = Math.sqrt(x / 7000) + 0.1;
  return y * 80;
};

