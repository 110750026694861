import React, { Component } from 'react';
import bg from '../../assets/img/bg-full.png';
import empty from '../../assets/empty.png';
import Nav from '../nav';
import Tit from '../components/Tit';
import Tab from '../components/Tab';
import ECharts from '../../components/ECharts';
import options from './options';
import CountUp from 'react-countup';
import { cqRequest } from '../../tools/http';
import './index.scss'
import _ from 'lodash';
import moment from 'moment';
const ProgressChartColors = ['#FAC459', '#00DBFF', '#FA7531', '#BB45EE', '#C93ACA'];
let dictionary = [{
  name: '同比增长',
  code: '1'
}, {
  name: '同比下降',
  code: '-1'
}, {
  name: '持平',
  code: '0'
}]
class Index extends Component {

  constructor() {
    super()
    this.state = {
      data: [],
      level: 'SGC',
      LABORPRODUCTIVITY: {},
      PERCAPITAEBITDA: {},
      TOTALALABORCOST: {},
      LABORCOSTPERCAPITA: {},
      LABORCOSTPROFITRATE: {},
      LABORCOSTEBITDARATE: {},
      COMPENSATIONCOSTPROFIT: {},
      statistics: {},
      salarycost: [],
      filterList: [],
      statisMonth: moment().subtract(1, 'month').format('YYYYMM'),
      dataQualityInfo: '',
      LABORPRODUCTIVITY1: {}
    }
  }
  _init = () => {
    const { level, statisMonth } = this.state

    // LABORPRODUCTIVITY：累计企业劳动生产率
    // PERCAPITAEBITDA：累计人均EBITDA
    // LABORCOSTPROFITRATE：人工成本利润率
    // TOTALALABORCOST：累计企业人工成本总额
    // LABORCOSTPERCAPITA：累计人均人工成本
    // LABORCOSTEBITDARATE：人工成本EBITDA
    // COMPENSATIONCOSTPROFIT：人均薪酬成本&薪酬成本利润占比
    let str = level == 'SGC' ? `?level1=${level}` : `?level2=${level}`

    cqRequest(`/effect/statistics${str}&statisMonth=${statisMonth}`).then((res) => {// 统计数据
      this.setState({
        statistics: res.data
      })
    })

    cqRequest(`/effect/salarycost${str}&statisMonth=${statisMonth}`).then((res) => {// 人均薪酬成本
      res.data = _.sortBy(res.data, function (item) {
        return item.salaryCost;
      });
      let list = res.data.map(d => d.name)
      this.setState({
        salarycost: res.data,
        filterList: ['去年同期', ...list]
      })
    })

    cqRequest(`/effect/chart${str}&effectType=LABORPRODUCTIVITY&statisMonth=${statisMonth}`).then((res) => {// 柱状图

      this.setState({
        LABORPRODUCTIVITY: res.data,
        LABORPRODUCTIVITY1: {
          "center": {
            "dimNames": res.data.dimNames,
            "categoryNames": [res.data.categoryNames[1]],
            "categoryValues": [res.data.categoryValues[1]],
          },
          "brokenLine": {
            "dimNames": res.data.dimNames,
            "categoryNames": [res.data.categoryNames[2]],
            "categoryValues": [res.data.categoryValues[2]],
          },
          "columnar": {
            "dimNames": res.data.dimNames,
            "categoryNames": [res.data.categoryNames[0]],
            "categoryValues": [res.data.categoryValues[0]],
          }
        }
      })
    })

    cqRequest(`/effect/chart${str}&effectType=PERCAPITAEBITDA&statisMonth=${statisMonth}`).then((res) => {// 柱状图
      this.setState({
        PERCAPITAEBITDA: res.data,
        PERCAPITAEBITDA1: {
          "center": {
            "dimNames": res.data.dimNames,
            "categoryNames": [res.data.categoryNames[1]],
            "categoryValues": [res.data.categoryValues[1]],
          },
          "brokenLine": {
            "dimNames": res.data.dimNames,
            "categoryNames": [res.data.categoryNames[2]],
            "categoryValues": [res.data.categoryValues[2]],
          },
          "columnar": {
            "dimNames": res.data.dimNames,
            "categoryNames": [res.data.categoryNames[0]],
            "categoryValues": [res.data.categoryValues[0]],
          }
        }
      })
    })

    cqRequest(`/effect/chart${str}&effectType=TOTALALABORCOST&statisMonth=${statisMonth}`).then((res) => {// 柱状图
      this.setState({
        TOTALALABORCOST: res.data,
        TOTALALABORCOST1: {
          "center": {
            "dimNames": res.data.dimNames,
            "categoryNames": [res.data.categoryNames[1]],
            "categoryValues": [res.data.categoryValues[1]],
          },
          "brokenLine": {
            "dimNames": res.data.dimNames,
            "categoryNames": [res.data.categoryNames[2]],
            "categoryValues": [res.data.categoryValues[2]],
          },
          "columnar": {
            "dimNames": res.data.dimNames,
            "categoryNames": [res.data.categoryNames[0]],
            "categoryValues": [res.data.categoryValues[0]],
          }
        }
      })
    })

    cqRequest(`/effect/chart${str}&effectType=LABORCOSTPERCAPITA&statisMonth=${statisMonth}`).then((res) => {// 柱状图
      this.setState({
        LABORCOSTPERCAPITA: res.data,
        LABORCOSTPERCAPITA1: {
          "center": {
            "dimNames": res.data.dimNames,
            "categoryNames": [res.data.categoryNames[1]],
            "categoryValues": [res.data.categoryValues[1]],
          },
          "brokenLine": {
            "dimNames": res.data.dimNames,
            "categoryNames": [res.data.categoryNames[2]],
            "categoryValues": [res.data.categoryValues[2]],
          },
          "columnar": {
            "dimNames": res.data.dimNames,
            "categoryNames": [res.data.categoryNames[0]],
            "categoryValues": [res.data.categoryValues[0]],
          }
        }
      })
    })

    cqRequest(`/effect/chart${str}&effectType=LABORCOSTEBITDARATE&statisMonth=${statisMonth}`).then((res) => {// 折线图
      this.setState({
        LABORCOSTEBITDARATE: res.data
      })
    })

    cqRequest(`/effect/chart${str}&effectType=LABORCOSTPROFITRATE&statisMonth=${statisMonth}`).then((res) => {// 折线图
      this.setState({
        LABORCOSTPROFITRATE: res.data
      })
    })

    cqRequest(`/common/dataQualityInfo?level=${level}&pageType=EFFECT_PAGE`).then((res) => {// 感叹号数据
      this.setState({
        dataQualityInfo: res.data
      })
    })
  }
  componentDidMount() {
    cqRequest(`/effect/lastdate`).then((res) => {// 统计数据
      this.setState({
        statisMonth: res.data || moment().format('YYYYMM')
      }, () => {
        this._init()
      })
    })

  }
  getData = (data) => {
    this.setState({ data })
  }
  filter = (d) => {
    const { filterList } = this.state
    if (filterList.includes(d)) {
      this.setState({
        filterList: filterList.filter(e => e != d),
      })
    } else {
      this.setState({
        filterList: [...filterList, d]
      })
    }
  }
  render() {
    const {
      LABORPRODUCTIVITY,
      LABORPRODUCTIVITY1,
      PERCAPITAEBITDA,
      PERCAPITAEBITDA1,
      TOTALALABORCOST,
      TOTALALABORCOST1,
      LABORCOSTPERCAPITA,
      LABORCOSTPERCAPITA1,
      LABORCOSTPROFITRATE,
      LABORCOSTEBITDARATE,
      statistics,
      salarycost,
      filterList
    } = this.state

    let all = document.documentElement.clientHeight == 720 ||
      document.documentElement.clientHeight == 864 ||
      document.documentElement.clientHeight == 1080 ||
      document.documentElement.clientHeight == 818 ||
      document.documentElement.clientHeight == 850 ||
      document.documentElement.clientHeight == 960 ||
      document.documentElement.clientHeight == 610 ||
      (document.documentElement.clientHeight == 578 && document.documentElement.clientWidth != 1280) ||
      document.documentElement.clientHeight == 681 ||

      document.documentElement.clientHeight == 781 ||
      document.documentElement.clientHeight == 823 ||
      document.documentElement.clientHeight == 1042 ||

      document.documentElement.clientHeight == 1010 ||
      document.documentElement.clientHeight == 1152
      ? true : false
    let all1 = (document.documentElement.clientHeight == 578 && document.documentElement.clientWidth != 1280) ?
      true : false
    return (
      <div className={`home w${window.screen.width}`}>
        <img src={bg} width={'100%'} alt="" className='full-bg' />

        <Nav />
        <div className='home-content'>
          <div className={`home-content-left w${window.screen.width} ww${window.screen.width}`}>
            <div className={`mt0`}>
              <Tit text="累计企业劳动生产率">
                {
                  LABORPRODUCTIVITY.extProperty && LABORPRODUCTIVITY.extProperty.type != null ?
                    <div className='common-text '>
                      <span>{dictionary.filter(d => d.code == LABORPRODUCTIVITY.extProperty.type || 0)[0].name}</span>
                      <b>{parseFloat(LABORPRODUCTIVITY.extProperty.rate).toFixed(1)}</b><span>%</span>
                    </div> : null
                }
              </Tit>
              {
                LABORPRODUCTIVITY1 && LABORPRODUCTIVITY1.brokenLine && LABORPRODUCTIVITY1.brokenLine.dimNames.length ?
                  <ECharts
                    style={{ height: all ? 235 : 180, width: '100%', marginTop: all ? 20 : 10 }}
                    className="OfflineGrowthTrend_charts"
                    option={options.getBar(LABORPRODUCTIVITY1, 1, '万元/人', '万元')}
                  /> : <div style={{ height: all ? 235 : 180, width: '100%' }} className="tc  flex-h-center"><img src={empty}></img></div>
              }
            </div>
            <div className='mt20'>
              <Tit text="累计人均EBITDA">
                {
                  PERCAPITAEBITDA.extProperty && PERCAPITAEBITDA.extProperty.type != null ?
                    <div className='common-text '>
                      <span>{dictionary.filter(d => d.code == PERCAPITAEBITDA.extProperty.type || 0)[0].name}</span>
                      <b>{parseFloat(PERCAPITAEBITDA.extProperty.rate).toFixed(1)}</b><span>%</span>
                    </div> : null
                }
              </Tit>
              {
                PERCAPITAEBITDA1 && PERCAPITAEBITDA1.brokenLine && PERCAPITAEBITDA1.brokenLine.dimNames.length ?
                  <ECharts
                    style={{ height: all ? 235 : 180, width: '100%', marginTop: all ? 20 : 10 }}
                    className="OfflineGrowthTrend_charts"
                    option={options.getBar(PERCAPITAEBITDA1, 1, '万元/人', '万元')}
                  /> : <div style={{ height: all ? 235 : 180, width: '100%' }} className="tc  flex-h-center"><img src={empty}></img></div>
              }
            </div>

          </div>
          <div className='common-h-line' style={{ height: 524, marginLeft: 25, marginRight: 25 }}></div>
          <div className={`home-content-center w${window.screen.width} ww${window.screen.width}`}>
            <div className='flex-h-between' style={{ position: 'relative', top: -40, marginBottom: -30 }}>
              <Tab date={this.state.statisMonth} dataQualityInfo={this.state.dataQualityInfo} onChange={(e) => {
                this.setState({
                  level: e,
                  LABORPRODUCTIVITY: {},
                  PERCAPITAEBITDA: {},
                  TOTALALABORCOST: {},
                  LABORCOSTPERCAPITA: {},
                  LABORCOSTPROFITRATE: {},
                  LABORCOSTEBITDARATE: {},
                  COMPENSATIONCOSTPROFIT: {},
                  statistics: {},
                  salarycost: [],
                  filterList: []
                }, () => {
                  this._init()
                })
              }} onChangeDate={(e) => {
                this.setState({
                  LABORPRODUCTIVITY: {},
                  PERCAPITAEBITDA: {},
                  TOTALALABORCOST: {},
                  LABORCOSTPERCAPITA: {},
                  LABORCOSTPROFITRATE: {},
                  LABORCOSTEBITDARATE: {},
                  COMPENSATIONCOSTPROFIT: {},
                  statistics: {},
                  salarycost: [],
                  filterList: [],
                  statisMonth: e,
                }, () => {
                  this._init()
                })
              }}></Tab>
            </div>

            <div className='home-content-center-num' style={{ marginTop: all ? -40 : -90 }}>
              <div className='home-content-center-num-left'>
                <div className='flex-h-between'>
                  <div>
                    <div className='common-text'>
                      <span>累计EBITDA</span>
                      <div> <b><CountUp
                        end={statistics.totalEbitda}
                        duration={2.75}
                        decimals={2}
                        decimal="."
                        suffix=""
                      /></b>
                        <span>亿</span></div>
                    </div>
                    <div className='common-text'>
                      <span>{
                        statistics.ebitdaType ?
                          dictionary.filter(d => d.code == statistics.ebitdaType)[0].name : '同比增长'
                      }</span>
                      <div> <b><CountUp
                        end={statistics.ebitdaRate}
                        duration={2.75}
                        decimals={1}
                        decimal="."
                        suffix=""
                      /></b>
                        <span>%</span></div>
                    </div>
                  </div>
                  <div>
                    <div className='common-text'>
                      <span>人均EBITDA</span>
                      <div> <b><CountUp
                        end={statistics.totalPerEbitda}
                        duration={2.75}
                        decimals={1}
                        decimal="."
                        suffix=""
                      /></b>
                        <span>万</span></div>
                    </div>
                    <div className='common-text'>
                      <span>{
                        statistics.perEbitdaType ?
                          dictionary.filter(d => d.code == statistics.perEbitdaType)[0].name : '同比增长'
                      }
                      </span>
                      <div> <b><CountUp
                        end={statistics.perEbitdaRate}
                        duration={2.75}
                        decimals={1}
                        decimal="."
                        suffix=""
                      /></b>
                        <span>%</span></div>
                    </div>
                  </div>
                  <div>
                    <ul className="chartsMany">
                      {[{
                        value: statistics.ebitdaReachRate,
                        name: 'YTD EBITDA达成率'
                      }].map(
                        (item, i) => {
                          return (
                            <li key={i} style={{ borderColor: ProgressChartColors[0] }}>
                              <ECharts
                                key={i}
                                className="progress_charts"
                                option={options.getPieFour(item.value / 100, ProgressChartColors[0], 1)}
                              />
                              <div className='circle' style={{ borderColor: ProgressChartColors[0] }}></div>
                              <div className="txt_num">
                                <div className="num" style={{ borderColor: ProgressChartColors[0] }}>
                                  <CountUp
                                    end={item.value}
                                    duration={2.75}
                                    //separator=","
                                    decimals={1}
                                    decimal="."
                                    suffix=""
                                  />
                                  <i>%</i>
                                </div>
                                <div className="txt common-text" style={{ top: -20 }}>
                                  <span>{item.name}</span>
                                </div>
                              </div>
                            </li>
                          );
                        }
                      )}
                    </ul>
                  </div>
                </div>
                <div className='common-v-line' style={{ width: 486, marginTop: all ? 0 : -30, marginBottom: all ? 0 : -20 }}></div>
                <div className='flex-h-between' >
                  <div>
                    <div className='common-text'>
                      <span>累计企业人工成本总额</span>
                      <div> <b><CountUp
                        end={statistics.totalLaborCost}
                        duration={2.75}
                        decimals={2}
                        decimal="."
                        suffix=""
                      /></b>
                        <span>亿</span></div>
                    </div>
                    <div className='common-text'>
                      <span>{
                        statistics.laborCostType ?
                          dictionary.filter(d => d.code == statistics.laborCostType)[0].name : '同比增长'
                      }</span>
                      <div> <b><CountUp
                        end={statistics.laborCostRate}
                        duration={2.75}
                        decimals={1}
                        decimal="."
                        suffix=""
                      /></b>
                        <span>%</span></div>
                    </div>
                  </div>
                  <div>
                    <div className='common-text'>
                      <span>累计人均人工成本</span>
                      <div> <b><CountUp
                        end={statistics.totalCostPerCapita}
                        duration={2.75}
                        decimals={1}
                        decimal="."
                        suffix=""
                      /></b>
                        <span>万</span></div>
                    </div>
                    <div className='common-text'>
                      <span>{
                        statistics.costPerCapitaType ?
                          dictionary.filter(d => d.code == statistics.costPerCapitaType)[0].name : '同比增长'
                      }</span>
                      <div> <b><CountUp
                        end={statistics.costPerCapitaRate}
                        duration={2.75}
                        decimals={1}
                        decimal="."
                        suffix=""
                      /></b>
                        <span>%</span></div>
                    </div>
                  </div>
                  <div>
                    <ul className="chartsMany">
                      {[{
                        value: statistics.capitaUseRate,
                        name: 'YTD  人工成本使用率'
                      }].map(
                        (item, i) => {
                          return (
                            <li key={i} style={{ borderColor: ProgressChartColors[1] }}>
                              <ECharts
                                key={i}
                                className="progress_charts"
                                option={options.getPieFour(item.value / 100, ProgressChartColors[1], 1)}
                              />
                              <div className='circle' style={{ borderColor: ProgressChartColors[1] }}></div>
                              <div className="txt_num">
                                <div className="num" style={{ borderColor: ProgressChartColors[1] }}>
                                  <CountUp
                                    end={item.value}
                                    duration={2.75}
                                    //separator=","
                                    decimals={1}
                                    decimal="."
                                    suffix=""
                                  />
                                  <i>%</i>
                                </div>
                                <div className="txt common-text" style={{ top: -20 }}>
                                  <span>{item.name}</span>
                                </div>
                              </div>
                            </li>
                          );
                        }
                      )}
                    </ul>
                  </div>
                </div>
              </div>
              <div className='common-h-line' style={{ height: 360 }}></div>
              <div className='home-content-center-num-right'>
                <div >
                  <div className='common-text' style={{ marginTop: 52 }}>
                    <span>从业人员平均数</span>
                    <div> <b><CountUp
                      end={statistics.practitionersAvgNum}
                      duration={2.75}
                      //separator=","
                      decimals={0}
                      decimal="."
                      suffix=""
                    /></b>
                      <span>人</span></div>
                  </div>
                  <div className='common-text'>
                    <span>{
                      statistics.avgNumType ?
                        dictionary.filter(d => d.code == statistics.avgNumType)[0].name : '同比增长'
                    }</span>
                    <div> <b><CountUp
                      end={statistics.avgNumRate}
                      duration={2.75}
                      //separator=","
                      decimals={1}
                      decimal="."
                      suffix=""
                    /></b>
                      <span>%</span></div>
                  </div>
                </div>
                <div style={{ marginLeft: -20 }}>
                  <ul className="chartsMany">
                    {[{
                      value: statistics.practitionersUseRate,
                      name: '人员使用率'
                    }].map(
                      (item, i) => {
                        return (
                          <li key={i} style={{ borderColor: ProgressChartColors[2] }}>
                            <ECharts
                              key={i}
                              className="progress_charts"
                              option={options.getPieFour(item.value / 100, ProgressChartColors[2], 1)}
                            />
                            <div className='circle' style={{ borderColor: ProgressChartColors[2] }}></div>
                            <div className="txt_num">
                              <div className="num" style={{ borderColor: ProgressChartColors[2] }}>
                                <CountUp
                                  end={item.value}
                                  duration={2.75}
                                  //separator=","
                                  decimals={1}
                                  decimal="."
                                  suffix=""
                                />
                                <i>%</i>
                              </div>
                              <div className="txt common-text" style={{ top: -20 }}>
                                <span>{item.name}</span>
                              </div>
                            </div>
                          </li>
                        );
                      }
                    )}
                  </ul>
                </div>
              </div>
            </div>

            {/* <div className='home-content-center-statistics' style={{ marginTop: -10 }}>
              <Tit text="人均薪酬成本&薪酬成本利润占比"></Tit>
              {
                salarycost.length ?
                  <div className='tab-switch'>
                    <ul className='flex-h-start'>
                      <li className={filterList.includes('去年同期') ? '' : 'disabled'} onClick={() => {
                        this.filter('去年同期')
                      }}>
                        <i></i>
                        <span>去年同期</span>
                      </li>
                      {
                        salarycost.map((d, index) => (
                          <li className={filterList.includes(d.name) ? '' : 'disabled'} onClick={() => {
                            this.filter(d.name)
                          }}>
                            <i style={{ background: ProgressChartColors1[index] }}></i>
                            <span>{d.name}</span>
                          </li>
                        ))
                      }

                    </ul>
                  </div> : null
              }

              <div className='fz16' style={{ position: 'absolute', bottom: 180, left: -75, transform: 'rotate(-90deg)', width: 184 }}>累计薪酬成本利润占比，薪酬/(薪酬+利润)%</div>
              {
                salarycost.length ? <ECharts
                  style={{ height: 370, width: 850, marginTop: 0 }}
                  className="OfflineGrowthTrend_charts"
                  option={options.getScatter1(salarycost, filterList)}
                /> : <div style={{ height: 370, width: 850 }} className="tc  flex-h-center"><img src={empty}></img></div>
              }

              <div className='fz16' style={{ position: 'absolute', bottom: 10, right: 0 }}>累计人均薪酬成本   薪酬/人数(万元)</div>
            </div> */}
          </div>
          <div className='common-h-line' style={{ height: 524, marginLeft: 40, marginRight: 40 }}></div>
          <div className={`home-content-right w${window.screen.width}`} style={{ marginRight: 10 }}>
            <div className='m10' style={{ width: '100%' }}>
              <Tit text="累计企业人工成本总额">
                {
                  TOTALALABORCOST.extProperty && TOTALALABORCOST.extProperty.type != null ?
                    <div className='common-text '>
                      <span>{dictionary.filter(d => d.code == TOTALALABORCOST.extProperty.type || 0)[0].name}</span>
                      <b>{parseFloat(TOTALALABORCOST.extProperty.rate).toFixed(1)}</b><span>%</span>
                    </div> : null
                }
              </Tit>
              {
                TOTALALABORCOST1 && TOTALALABORCOST1.brokenLine && TOTALALABORCOST1.brokenLine.dimNames.length ?
                  <ECharts
                    style={{ height: all ? 235 : 180, width: '100%', marginTop: all ? 20 : 10 }}
                    className="OfflineGrowthTrend_charts"
                    option={options.getBar(TOTALALABORCOST1, 1, '万元', '万元')}
                  /> : <div style={{ height: all ? 235 : 180, width: '100%' }} className="tc  flex-h-center"><img src={empty}></img></div>
              }
            </div>
            <div className='mt20' style={{ width: '100%' }}>
              <Tit text="累计人均人工成本">
                {
                  LABORCOSTPERCAPITA.extProperty && LABORCOSTPERCAPITA.extProperty.type != null ?
                    <div className='common-text '>
                      <span>{dictionary.filter(d => d.code == LABORCOSTPERCAPITA.extProperty.type || 0)[0].name}</span>
                      <b>{parseFloat(LABORCOSTPERCAPITA.extProperty.rate).toFixed(1)}</b><span>%</span>
                    </div> : null
                }
              </Tit>
              {
                LABORCOSTPERCAPITA1 && LABORCOSTPERCAPITA1.brokenLine && LABORCOSTPERCAPITA1.brokenLine.dimNames.length ?
                  <ECharts
                    style={{ height: all ? 235 : 180, width: '100%', marginTop: all ? 20 : 10 }}
                    className="OfflineGrowthTrend_charts"
                    option={options.getBar(LABORCOSTPERCAPITA1, 1, '万元', '万元', '万元')}
                  /> : <div style={{ height: all ? 235 : 180, width: '100%' }} className="tc  flex-h-center"><img src={empty}></img></div>
              }
            </div>
            <div>

            </div>
          </div>
        </div>
        <div className={`home-content1 home w${window.screen.width}`} style={{ marginTop: all ? 0 : -40, bottom: all ? all1 ? 55 : 40 : 210 }}>

          <div style={{ width: '48%' }}>
            <Tit text="人工成本利润率">{
              LABORCOSTPROFITRATE.extProperty && LABORCOSTPROFITRATE.extProperty.type != null ?
                <div className='common-text '>
                  <span>{dictionary.filter(d => d.code == LABORCOSTPROFITRATE.extProperty.type || 0)[0].name}</span>
                  <b>{parseFloat(LABORCOSTPROFITRATE.extProperty.rate).toFixed(1)}</b><span>%</span>
                </div> : null
            }
            </Tit>
            {
              LABORCOSTPROFITRATE.dimNames && LABORCOSTPROFITRATE.dimNames.length ?
                <ECharts
                  style={{ height: all ? 230 : 210, width: '100%', marginTop: all ? 20 : 10 }}
                  className="OfflineGrowthTrend_charts"
                  option={options.getLineChart(LABORCOSTPROFITRATE, '')}
                /> : <div style={{ height: all ? 230 : 210, width: '100%' }} className="tc  flex-h-center"><img src={empty}></img></div>
            }
          </div>
          <div style={{ width: '48%' }}>
            <Tit text="人工成本EBITDA率">
              {
                LABORCOSTEBITDARATE.extProperty && LABORCOSTEBITDARATE.extProperty.type != null ?
                  <div className='common-text '>
                    <span>{dictionary.filter(d => d.code == LABORCOSTEBITDARATE.extProperty.type || 0)[0].name}</span>
                    <b>{parseFloat(LABORCOSTEBITDARATE.extProperty.rate).toFixed(1)}</b><span>%</span>
                  </div> : null
              }
            </Tit>

            {
              LABORCOSTEBITDARATE.dimNames && LABORCOSTEBITDARATE.dimNames.length ?
                <ECharts
                  style={{ height: all ? 230 : 210, width: '100%', marginTop: all ? 20 : 10 }}
                  className="OfflineGrowthTrend_charts"
                  option={options.getLineChart(LABORCOSTEBITDARATE, '')}
                /> : <div style={{ height: all ? 230 : 210, width: '100%' }} className="tc  flex-h-center"><img src={empty}></img></div>
            }
          </div>
        </div>
      </div >
    );
  }
}

export default Index;