import React, { Component } from 'react';
import bg from '../../assets/img/login.png';
import { Tabs, Button, Checkbox, Form, Input, message } from 'antd';
import Nav from '../nav';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { setToken } from '../../tools/utils';
import { get } from '../../tools/http';
const { TabPane } = Tabs;
class Index extends Component {
  constructor() {
    super()
    this.state = {
      visible: false
    }
  }

  componentDidMount() {
    const params = new URLSearchParams(this.props.location.search);
    const token = params.get('token');
    if (token) {
      setToken(token)
      get(`/accounts/menuTree`).then((res) => {
        if (res.data.length) {
          localStorage.pages = JSON.stringify(res.data)
          window.location.href = './p1'
        }
      })
      get(`/roleLevel/levels`).then((res) => {
        if (res.data.length) {
          localStorage.tabs = JSON.stringify(res.data)
        }
      })
    } else {
      message.error('没有权限访问')
      // this.setState({
      //   visible: true
      // })
    }
  }


  onChange = (key) => {
    console.log(key);
  };
  onFinish = (values) => {
    let is = false
    for (let i in values) {
      if (i == '用户名/手机号' && values[i] != 'SGC_HR_D') {
        is = true
      }
      if (i == '密码' && values[i] != 'SGC_0831S') {
        is = true
      }
    }
    // if (!is) {
    //   //window.location.href = '/p1'
    //   console.log(44444,values)

    // } else {
    //   message.error('用户名或密码错误')
    // }
  };
  render() {
    const { visible } = this.state
    return (
      <div className="home">
        <img src={bg} width={'100%'} alt="" />
        <Nav type={1}></Nav>
        {
          visible ?
            <div className='login'>
              <Tabs defaultActiveKey="1" onChange={this.onChange}>
                <TabPane tab="密码登录" key="1">

                </TabPane>
                <TabPane tab="验证码登录" key="2">

                </TabPane>
              </Tabs>
              <div className='mt20'></div>
              <Form
                name="normal_login"
                className="login-form"
                initialValues={{ remember: true }}
                onFinish={this.onFinish}
              >
                <Form.Item
                  name="用户名/手机号"
                  rules={[{ required: true, message: '用户名/手机号不能为空!' }]}
                >
                  <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="用户名/手机号" />
                </Form.Item>
                <Form.Item
                  name="密码"
                  rules={[{ required: true, message: '密码不能为空!' }]}
                >
                  <Input
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder="密码"
                  />
                </Form.Item>
                <Form.Item>
                  <div className='flex-h-between form-text'>
                    <Form.Item name="记住密码" valuePropName="checked" noStyle>
                      <Checkbox>记住密码</Checkbox>
                    </Form.Item>

                    <a className="login-form-forgot" href="">
                      忘记密码
                    </a>
                  </div>
                </Form.Item>

                <Form.Item>
                  <Button type="primary" htmlType="submit" className="login-form-button" style={{ width: '100%' }}>
                    登录
                  </Button>
                </Form.Item>
              </Form>
            </div> : null
        }
      </div>
    );
  }
}

export default Index;