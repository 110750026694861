import React from 'react';
import { useState, useEffect } from 'react';
import { DatePicker } from 'antd';
import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';
import moment from 'moment';
import icon_tips from '../../assets/icon_tips.png';
import close from '../../assets/icon_close.png';
const Tab = (props) => {
    const [value, setValue] = useState('SGC');
    const [key, setKey] = useState(moment().subtract(1, 'month'));
    const [visibleTips, setVisibleTips] = useState(false);
    const list = ['SGC', 'CS', 'CN', 'CP', 'MAP']
    const tabs = localStorage.tabs ? JSON.parse(localStorage.tabs) : ['SGC', 'CS', 'CN', 'CP', 'MAP']
    function onChange(date, dateString) {
        setKey(date)
        setTimeout(() => {
            props.onChangeDate(moment(date).format('YYYYMM'))
        }, 100)
    };
    useEffect(() => {
        window.onresize = function () {
            // var isFull = document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement || document.msFullscreenElement;
            // if (isFull) {
            //     console.log('全屏')
            // } else {
            //     console.log('没有全屏')
            // }
            // window.location.replace(window.location.href)
            window.location.reload();
        }
    })
    function disabledDate(current) {
        const disabledEndDate = '2021-12-31';
        const disabledEndDay = new Date(disabledEndDate);
        if (props.date) {
            return current && current > moment().subtract(3, 'month').endOf('day') || current && current < disabledEndDay;

        } else {
            return current && current > moment().subtract(1, 'month').endOf('day') || current && current < disabledEndDay;
        }
    }
    // console.log(5555, props.date)
    return (
        <>
            {true ? <div className={`${!window.location.pathname.includes('p5') ? 'tips' : 'none'}`} style={{ position: "absolute", top: 20, fontSize: 18 }}>
                <DatePicker onChange={onChange}
                    picker="month"
                    allowClear={false}
                    locale={locale}
                    disabledDate={disabledDate}
                    s
                    value={props.date ?
                        moment(`${String(props.date).slice(0, 4)}-${String(props.date).slice(-2)}`, 'YYYY-MM') :
                        moment(moment(key).format('YYYY-MM'), 'YYYY-MM')}

                />
                {!window.location.pathname.includes('p5') ?
                    // <img style={{
                    //     position: 'absolute',
                    //     right: 7,
                    //     top: 10,
                    //     cursor: 'pointer'
                    // }} src={icon_tips} onClick={() => setVisibleTips(true)}></img> 
                    null : <div style={{ height: 40 }}></div>}
            </div> : null
            }
            <div className={`content-center-tab w${window.screen.width}`} style={props.style}>
                <ul>
                    {
                        list.map((d) => (
                            <li onClick={() => {
                                if (tabs.includes(d)) {
                                    window.level = d
                                    setValue(d)
                                    setTimeout(() => {
                                        props.onChange(d)
                                    }, 100)
                                }
                            }} className={`${value == d ? 'active' : ''} ${tabs.includes(d) ? '' : 'disabled'}`}>{d}</li>
                        ))
                    }
                </ul>
            </div>


            {/* {
                visibleTips ? <div className='hover-tips'>
                    <img src={close} onClick={() => setVisibleTips(false)}></img>
                    <>
                        <h3>数据现状：</h3>
                        <div dangerouslySetInnerHTML={{ __html: props.dataQualityInfo }}></div>
                    </>
                </div> : null
            } */}
        </>
    )
};
export default Tab