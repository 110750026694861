import React from "react";
import ReactDOM from "react-dom";
import zhCN from "antd/es/locale/zh_CN";
import { ConfigProvider } from "antd";
import "antd/dist/antd.css";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import Router from "./Router";
import DevicePixelRatio from './tools/devicePixelRatio'
const RootNode = () => {
  const containerRef = React.useRef<HTMLDivElement | null>(null);
  new DevicePixelRatio().init();
  console.log(1111)
  return (
    <ConfigProvider
      locale={zhCN}
      getPopupContainer={() => {
        if (containerRef.current) {
          return containerRef.current;
        }
        return document.body;
      }}
    >
      <React.StrictMode>
        <Router />
        <div className="custom-popup-container" ref={containerRef} />
      </React.StrictMode>
    </ConfigProvider>
  );
};

ReactDOM.render(<RootNode />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
