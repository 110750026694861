import * as R from 'ramda';
import Axios from 'axios';
import { getToken, setToken } from './utils';
import Base64 from 'base-64'
import { message } from 'antd';
// let url = 'http://222.177.213.242:15008'
let url = 'https://ads.syngentagroup.cn'
// let url ='http://alb-sgc-odw-dev-cnnw1-api-1199986327.cn-northwest-1.elb.amazonaws.com.cn'
const serivce = Axios.create({
  baseURL: url + '/api/bi',
  timeout: 500000000,
  headers: {
    'Content-Type': 'application/json;',
    Accept: 'application/json',
  },
});
process.env.GENERATE_SOURCEMAP = "false";
// http request 拦截器
serivce.interceptors.request.use(
  (config) => {
    const access_token = getToken();
    if (config.url.includes('/oauth/token')) {
      config.headers.Authorization = 'Basic ' + Base64.encode('web:123456');
      // config.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
    } else {
      config.headers.Authorization = `Bearer ${access_token}`;
    }
    if (config.url.includes('accounts/menuTree') || config.url.includes('roleLevel/levels')) {
      config.baseURL = url + '/api/upms'
    }
    return config;
  },
  (err) => {
    console.error(err, 'errorerror');
    return Promise.reject(err);
  }
);

// http响应拦截器
serivce.interceptors.response.use(
  (res) => {
    return res;
  },
  (error) => {
    const status = error.response?.status;
    if (status === 401) {
      try {
        message.destroy();
      } catch (e) {
        console.log("can't find message")
      }
      message.config({
        top: 300,
        duration: 2,
      });

      message.error('登录过期，自动跳转中...', 1)
      setTimeout(() => {
        window.location.href = "https://bi.syngentachina.com/portalReportCenter/#/reportCenter?Id=168&Name=SGC-HR%E7%BB%BC%E5%90%88%E4%BF%A1%E6%81%AF%E7%9C%8B%E6%9D%BF"
      }, 2000)
    }
    return Promise.reject(error);
  }
);

//自定义请求方式
const request = async (_options) => {
  // 默认GET方法
  const method = _options.method || 'GET';
  const options = R.merge(
    { ..._options },
    {
      method,
    }
  );
  return serivce(options);
};

/**
 * 封装get请求
 * @param { String } url 请求路径
 * @param params
 * @param _options
 *  params GET请求参数
 */
const get = (url, params, _options) => {
  return request({
    ..._options,
    params,
    url,
  });
};
/**
 * 封装post请求
 *  data POST请求请求参数，对象形式
 * @param url
 * @param data
 * @param _options
 */
const post = (url, data, _options) => {
  return request({
    ..._options,
    data,
    url,
    method: 'POST',
  });
};

let methods = 'GET';
const cqRequest = (url, data, _options) => {
  return request({
    ..._options,
    params: data,
    data,
    url,
    method: methods,
  });
};

export { get, post, cqRequest };
export default request;
