import React, { Component } from 'react';
import bg from '../../assets/img/bg-full.png';
import { cqRequest } from '../../tools/http';
import Nav from '../nav';
import Tit from '../components/Tit';
import Tab from '../components/Tab';
import ECharts from '../../components/ECharts';
import Map from '../../components/Map';
import options from './options';
import CountUp from 'react-countup';
import ScrollArea from 'react-scrollbar';
import empty from '../../assets/empty.png';
import moment from 'moment';
import './index.scss'
import _ from 'lodash';

const ProgressChartColors = ['#00DBFF', '#605BFF', '#91CC76', '#FA7531'];
class Index extends Component {
  constructor() {
    super()
    this.state = {
      data: [],
      level: 'SGC',
      needPieStat: [],
      waitNeedBarStat: [],
      gradesNeedBarStat: [],
      regionWaitNeedStat: [],
      orgPlaceFinishBlockStat: [],
      gradesFinishBlockStat: [],
      orgFinishBlockStat: [],
      avgPeriodStat: {},
      overviewStat: {},
      costPieStat: {},
      col1: true,
      col2: true,
      active: 1,
      statisMonth: moment().subtract(1, 'month').format('YYYYMM'),
      dataQualityInfo: ''
    }
  }
  _init = () => {
    const { level, statisMonth } = this.state
    cqRequest(`/hr/needPieStat?level=${level}&statisMonth=${statisMonth}`).then((res) => {// 招聘需求占比分布
      this.setState({
        needPieStat: res.data
      })
    })

    cqRequest(`/hr/offerWaitStat?level=${level}&statisMonth=${statisMonth}`).then((res) => {//待招需求棒图分布
      res.data.forEach(d => {
        let total = d.waitNum + d.offerNum
        d.percent1 = Number(((d.offerNum / total) * 100).toString().match(/^\d+(?:\.\d{0,2})?/))
        d.percent2 = Number(((d.waitNum / total) * 100).toString().match(/^\d+(?:\.\d{0,2})?/))

      })
      this.setState({
        waitNeedBarStat: res.data
      })
    })

    cqRequest(`/hr/gradesNeedBarStat?level=${level}&statisMonth=${statisMonth}`).then((res) => {// 职级招聘需求棒图分布
      let total = Number(_.sum(res.data.map(d => Number(d.value))))
      res.data.forEach(d => {
        d.value = Number(d.value)
        d.percent = Number(((d.value / total) * 100).toString().match(/^\d+(?:\.\d{0,2})?/))
      })
      this.setState({
        gradesNeedBarStat: res.data
      })
    })

    cqRequest(`/hr/overviewStat?level=${level}&statisMonth=${statisMonth}`).then((res) => {// 招聘概览（除图表外的其他单项统计整合）
      this.setState({
        overviewStat: res.data
      })
    })

    cqRequest(`/hr/regionWaitNeedStat?level=${level}&statisMonth=${statisMonth}`).then((res) => {// 待招和已完成地图分布
      this.setState({
        regionWaitNeedStat: res.data
      })
    })

    cqRequest(`/hr/costPieStat?level=${level}&statisMonth=${statisMonth}`).then((res) => {// 招聘成本统计（部门+渠道双饼）
      this.setState({
        costPieStat: res.data
      })
    })

    cqRequest(`/hr/avgPeriodStat?level=${level}&statisMonth=${statisMonth}`).then((res) => {// 平均招聘周期
      this.setState({
        avgPeriodStat: res.data
      })
    })


    cqRequest(`/hr/orgPlaceFinishBlockStat?level=${level}&statisMonth=${statisMonth}`).then((res) => {// 渠道完成量块状分布
      res.data = [...res.data]
      let total = Number(_.sum(res.data.map(d => Number(d.value))))
      res.data.forEach(d => {
        d.value = Number(d.value)
        d.percent = ((d.value / total) * 100).toFixed(1)
      })
      res.data = _.sortBy(res.data, function (item) {
        return -item.percent;
      });
      this.setState({
        orgPlaceFinishBlockStat: [...res.data]
      })
    })

    cqRequest(`/hr/gradesFinishBlockStat?level=${level}&statisMonth=${statisMonth}`).then((res) => {// 职级完成量分布
      this.setState({
        gradesFinishBlockStat: res.data
      })
    })

    cqRequest(`/hr/orgFinishBlockStat?level=${level}&statisMonth=${statisMonth}`).then((res) => {// 社招校招完成量分布
      this.setState({
        orgFinishBlockStat: res.data
      })
    })

    cqRequest(`/common/dataQualityInfo?level=${level}&pageType=${level === 'SGC' ? 'HR_PAGE' : level === 'MAP' ? 'HR_MAP_PAGE' : level === 'CS' ? 'HR_CS_PAGE' : level === 'CN' ? 'HR_CN_PAGE' : 'HR_CP_PAGE'}`).then((res) => {// 感叹号数据
      this.setState({
        dataQualityInfo: res.data
      })
    })
  }
  componentDidMount() {
    this._init()
    
  }
  getData = (data) => {
    this.setState({ data })
  }
  render() {
    const {
      needPieStat,
      waitNeedBarStat,
      gradesNeedBarStat,
      overviewStat,
      regionWaitNeedStat,
      costPieStat,
      avgPeriodStat,
      orgPlaceFinishBlockStat,
      gradesFinishBlockStat,
      orgFinishBlockStat,
      active,
      level
    } = this.state
    let orgFinishBlockStatList = active == 1 ? orgFinishBlockStat.societyBlock : orgFinishBlockStat.schoolBlock
    let all = document.documentElement.clientHeight == 720 ||
      document.documentElement.clientHeight == 864 ||
      document.documentElement.clientHeight == 1080 ||
      document.documentElement.clientHeight == 818 ||
      document.documentElement.clientHeight == 850 ||
      document.documentElement.clientHeight == 960 ||
      document.documentElement.clientHeight == 610 ||
      (document.documentElement.clientHeight == 578 && document.documentElement.clientWidth != 1280) ||
      document.documentElement.clientHeight == 681 ||

      document.documentElement.clientHeight == 781 ||
      document.documentElement.clientHeight == 823 ||
      document.documentElement.clientHeight == 1042 ||

      document.documentElement.clientHeight == 1010 ||
      document.documentElement.clientHeight == 1152
      ? true : false
    let all1 = (document.documentElement.clientHeight == 578 && document.documentElement.clientWidth != 1280) ?
      true : false
    return (
      <div className={`home w${window.screen.width}`}>
        <img src={bg} width={'100%'} alt="" className='full-bg' />

        <Nav />
        <div className='home-content'>
          <div className={`home-content-left mt0 w${window.screen.width}`} style={{ width: 480, marginLeft: 20 }}>
            <Tit text="YTD招聘需求(社招)"></Tit>
            <div className='flex-h-between mt0'>
              <div style={{ width: '26%' }}>
                <div className='common-title tl'>{this.state.level}招聘需求</div>
                <div className='common-text mt10'>
                  <b className='ml0'>
                    <CountUp
                      end={overviewStat.needNum}
                      duration={2.75}
                      decimals={0}
                      decimal="."
                      suffix=""
                      className='ml0'
                    />
                  </b>
                  <span className='fz12'>人</span>
                </div>
              </div>
              <div className='common-h-line' style={{ height: 200, marginLeft: 15, marginRight: 0 }}></div>
              <div style={{ width: '75%' }}>
                {
                  needPieStat && needPieStat.length ? <ECharts
                    className="home-content-left-distributed"
                    style={{ height: all ? 300 : 260, width: '100%' }}
                    option={options.getPie(needPieStat, `${this.state.level == 'SGC' ? 'BU' : ''}招聘需求`, ['#605BFF', '#FF9E00', '#91CC76', '#C93ACA', '#EE6666'], {
                      left: this.state.level == 'SGC' ? '13%' : '18%',
                      label: true,
                      right: all ? '-4%' : '-4%'
                    })}
                  /> : <div style={{ height: all ? 300 : 260, width: '100%' }} className="tc flex-h-center"><img src={empty}></img></div>
                }

              </div>
            </div>

            <h3 className='colb fz16 tc m10' style={{ marginTop: all ? 20 : -20 }}>各职级招聘需求</h3>
            <div className={`flex-h-between home-content-center-user-barlist mt10 ml0 ww${window.screen.width}`} style={{ width: 440, }}>

              {
                gradesNeedBarStat.length ?
                  <ScrollArea
                    smoothScrolling={true}
                    stopScrollPropagation={true}
                    style={{
                      maxHeight: 260,
                      zIndex: 10,
                      marginTop: all ? 20 : 0,
                      marginLeft: -20
                    }}
                  >
                    <ul style={{ width: 440 }} className="li">
                      {
                        gradesNeedBarStat.map(d => (
                          <li className='mb20'>
                            <span style={{ width: '28%' }} className="lispan">{d.name}</span>
                            <div className='bar lidiv' style={{ height: 8, width: '41.5%', borderRadius: '0px 21px 21px 0px', marginRight: 0 }}>
                              <div className='progress' style={{ width: `${d.percent}%`, height: 8, borderRadius: '0px 21px 21px 0px' }}></div>
                            </div>
                            <div className='flex-h-center lilastdiv' style={{ width: '30.5%' }}>
                              <span className='ml30'>{d.value}</span><b style={{ margin: '0 2px', fontWeight: 'normal' }}>人</b><span>{d.percent.toFixed(1)}%</span>
                            </div>
                          </li>
                        ))
                      }
                    </ul></ScrollArea> : <div style={{ height: 260, width: '100%' }} className="tc flex-h-center"><img src={empty}></img></div>
              }
            </div>

            <h3 className={`colb fz16 tc ${all ? 'mt30' : 'mt20'}`}>offer完成数&在招岗位</h3>
            <div className='filter mt30'>
              <ul>
                <li onClick={() => this.setState({ col1: !this.state.col1 })}><i className={`dot ${this.state.col1 ? 'col1' : ''} `} ></i><span>offer完成数</span></li>
                <li onClick={() => this.setState({ col2: !this.state.col2 })}><i className={`dot ${this.state.col2 ? 'col2' : ''} `} ></i><span>在招岗位</span></li>
              </ul>
            </div>
            <div className={`flex-h-between home-content-center-user-barlist barother mt40 ww${window.screen.width}`} style={{ width: '100%' }}>
              {
                waitNeedBarStat.length ?
                  <ScrollArea
                    smoothScrolling={true}
                    stopScrollPropagation={true}
                    style={{
                      maxHeight: 176,
                      zIndex: 10,
                      marginTop: -20,
                      marginLeft: 0
                    }}
                  >
                    <ul style={{ width: '110%' }}>
                      {
                        waitNeedBarStat.map(d => (
                          <li style={{ justifyContent: 'flex-start' }}>
                            <span style={{ width: 100 }}>{d.name}</span>
                            <div className='bar'>
                              {
                                this.state.col1 ?
                                  <div className='num waitAddNum' style={{ width: `${d.percent1}%` }}></div> : null
                              }
                              {
                                this.state.col2 ? <div className='num waitReplaceNum' style={{ width: `${d.percent2}%`, marginLeft: d.percent1 > 0 ? -4 : 0 }}></div> : null
                              }
                            </div>
                            <span style={{ width: 100, display: 'flex', justifyContent: 'flex-end' }}><span>{d.offerNum}</span><span className='ml5 mr5'>|</span><span> {d.waitNum}</span></span>
                          </li>
                        ))
                      }
                    </ul></ScrollArea> : <div style={{ height: 156, width: '100%' }} className="tc flex-h-center"><img src={empty}></img></div>
              }

            </div>
          </div>
          <div className='common-h-line' style={{ height: 824, marginLeft: 25, marginRight: 20 }}></div>
          <div className={`home-content-center w${window.screen.width}`}>
            <div className='flex-h-between' style={{ position: 'relative', top: -40, marginBottom: all ? 30 : -30, zIndex: 999 }}>
              <Tab dataQualityInfo={this.state.dataQualityInfo} onChange={(e) => {
                // console.log(9999, e)
                this.setState({
                  level: e,
                  regionWaitNeedStat: []
                }, () => {
                  this._init()
                })
              }} onChangeDate={(e) => {
                this.setState({
                  statisMonth: e,
                  regionWaitNeedStat: []
                }, () => {
                  this._init()
                })
              }}></Tab>
            </div>
            {/* <div className='home-content-center-preview' style={{ position: 'relative', top: 25 }}>
              <div className='flex-h-between'>
                <div className='common-text'>
                  <span>社招招聘需求</span>
                  <div> <b><CountUp
                    end={overviewStat.societyNum}
                    duration={2.75}
                    decimals={0}
                    decimal="."
                    suffix=""
                  /></b>
                    <span>人</span></div>
                </div>
                <div className='common-text'>
                  <span>完成率</span>
                  <div> <b><CountUp
                    end={overviewStat.societyFinishRatio}
                    duration={2.75}
                    decimals={1}
                    decimal="."
                    suffix=""
                  /></b>
                    <span>%</span></div>
                </div>
                <div className='common-h-line' style={{ height: 66, marginLeft: 20, marginRight: 20 }}></div>
                <div className='common-text'>
                  <span>校招招聘需求</span>
                  <div> <b><CountUp
                    end={overviewStat.schoolNum}
                    duration={2.75}
                    decimals={0}
                    decimal="."
                    suffix=""
                  /></b>
                    <span>人</span></div>
                </div>
                <div className='common-text'>
                  <span>完成率</span>
                  <div> <b><CountUp
                    end={overviewStat.schoolFinishRatio}
                    duration={2.75}
                    decimals={1}
                    decimal="."
                    suffix=""
                  /></b>
                    <span>%</span></div>
                </div>
              </div>
            </div> */}

            {/* 地图 */}
            <div className='home-content-center-map' key={this.state.level}>
              {
                regionWaitNeedStat.length ? <Map
                  top={-60}
                  type={2} datas={regionWaitNeedStat}></Map> :
                  <div style={{ height: 750, width: '100%', paddingTop: 230 }} className="tc"><img src={empty}></img></div>
              }
            </div>

            <div className='home-content-center-statistics' style={{ marginTop: all ? -260 : -350 }}>
              <Tit text="YTD招聘成本(社招)"></Tit>
              <div className='flex-h-center' style={{ marginTop: -20, marginLeft: -0 }}>
                <div style={{ width: '48%' }}>
                  {
                    costPieStat.orgPie && costPieStat.orgPie.length ? <ECharts
                      className="home-content-left-distributed"
                      style={{ height: 220, width: '100%', }}
                      option={options.getPie(costPieStat.orgPie, '部门分布', '', {
                        left: '28.5%',
                        title: costPieStat.orgPie[0].value.toFixed(1),
                        right: window.screen.width == 1280 || window.screen.width == 1920 || window.screen.width == 1536 ? '5%' : '0'
                      })}
                    /> : <div style={{ height: 220, width: '100%', paddingTop: 80 }} className="tc"><img src={empty}></img></div>
                  }
                </div>
                <div style={{ width: '48%' }}>
                  {
                    costPieStat.placePie && costPieStat.placePie.length ? <ECharts
                      className="home-content-left-distributed"
                      style={{ height: 220, width: '100%' }}
                      option={options.getPie(costPieStat.placePie, '渠道分布', '', {
                        left: '29%',
                        title: _.sum(costPieStat.placePie.map(d => d.value)).toFixed(1),
                        right: window.screen.width == 1280 || window.screen.width == 1920 || window.screen.width == 1536 ? '0%' : '0'
                      })}
                    /> : <div style={{ height: 220, width: '100%', paddingTop: 80 }} className="tc"><img src={empty}></img></div>
                  }
                </div>
              </div>
            </div>
            <div style={{ position: 'absolute', bottom: 70, left: 550, width: 790, height: 60, zIndex: 9999 }}>
              <img src={require('../../assets/scocal.svg')} height="60" width={'100%'} style={{ position: 'absolute', top: 0, left: 0 }} />
              <div dangerouslySetInnerHTML={{ __html: this.state.dataQualityInfo }} style={{ fontSize: 16, color: '#00DBFF', height: 60, paddingLeft: 50, paddingRight: 50, paddingTop: 5, lineHeight: '22px' }}></div>
            </div>
          </div>
          <div className='common-h-line' style={{ height: 824, marginLeft: 35, marginRight: 30 }}></div>
          <div className={`home-content-right w${window.screen.width}`} style={{ width: 520 }}>
            <div>
              <Tit text="YTD招聘完成量(社招)"></Tit>
              <div className=''>
                <div className='flex-h-start'>
                  <div className={`hr-content-left w${window.screen.width}`} style={{ height: 373 }}>
                    <h3 className='colb fz16 tc mt20'>{level}完成情况</h3>
                    <div className='flex-h-between'>
                      <div className='common-text tc'>
                        <b className='fz36'>
                          <CountUp
                            end={overviewStat.finishNum}
                            duration={2.75}
                            decimals={0}
                            decimal="."
                            suffix=""
                          />
                        </b>
                        <span className='fz17'>人</span>
                      </div>

                      <ECharts
                        className="progress_charts"
                        style={{ width: 60, height: 60 }}
                        option={options.getPieFour((overviewStat.finishNum / overviewStat.needNum) || 0, '#00DBFF', '',
                          ((overviewStat.finishNum / overviewStat.needNum) * 100).toFixed(1) || 0)}
                      />
                    </div>
                    <div className='common-v-line' style={{ width: 197, marginBottom: 10, marginTop: 10 }}></div>
                    <h3 className='colb fz16 tc mt0'>各职级完成情况</h3>
                    <div className='hr-list mt0'>
                      <div className='hr-list-list'>
                        {
                          gradesFinishBlockStat && gradesFinishBlockStat.length ? <ScrollArea
                            smoothScrolling={true}
                            stopScrollPropagation={true}
                            style={{
                              maxHeight: 205,
                              zIndex: 10
                            }}
                          >
                            <ul>
                              {
                                gradesFinishBlockStat.map(d => (
                                  <li>
                                    <span>{d.gradesName}</span>
                                    <span className='fz16'><b className='col6'>{d.finishNum}</b> <span className='fz14'>人</span></span>
                                    <ECharts
                                      className="progress_charts"
                                      style={{ width: 60, height: 60 }}
                                      option={options.getPieFour((d.finishRatio / 100) || 0, '#6FD588', '', parseFloat(d.finishRatio).toFixed(1))}
                                    />
                                  </li>
                                ))
                              }
                            </ul>
                          </ScrollArea> :
                            <div style={{ height: 200, width: '100%', paddingTop: 30 }} className="tc"><img src={empty}></img></div>
                        }

                      </div>
                    </div>
                  </div>
                  <div className='common-h-line' style={{ height: 373, marginLeft: 25, marginRight: 25 }}></div>
                  <div className={`hr-content-right w${window.screen.width}`} style={{ height: 373 }}>
                    <h3 className='colb fz16 tc' style={{ marginTop: 16 }}>{this.state.level == 'SGC' ? '各BU' : ''}完成情况</h3>
                    <div className='hr-list mt0'>
                      {/* <div className='hr-list-tab'>
                        <ul>
                          {
                            [{ name: '社招', key: 1 }, { name: '校招', key: 2 }].map(d => (
                              <li className={active == d.key ? 'active' : ''} onClick={() => this.setState({ active: d.key })}>{d.name}</li>
                            ))
                          }
                        </ul>
                      </div> */}
                      <div className='hr-list-list bu'>
                        {
                          orgFinishBlockStatList && orgFinishBlockStatList.length ?
                            <ScrollArea
                              smoothScrolling={true}
                              stopScrollPropagation={true}
                              style={{
                                height: 315,
                                zIndex: 10
                              }}
                            >
                              <ul>
                                {
                                  orgFinishBlockStatList.map(d => (
                                    <li>
                                      <span>{d.orgName}</span>
                                      <span className='fz16'><b className='colb'>{d.finishNum}</b> <span className='fz14'>人</span></span>
                                      <ECharts
                                        className="progress_charts"
                                        style={{ width: 60, height: 60 }}
                                        option={options.getPieFour((d.finishRatio / 100) || 0, '#00DBFF', '', d.finishRatio ? d.finishRatio.toFixed(1) : d.finishRatio)}
                                      />
                                    </li>
                                  ))
                                }
                              </ul></ScrollArea> : <div style={{ height: 315, width: '100%' }} className="tc flex-h-center"><img src={empty}></img></div>
                        }

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='mr30' style={{ marginTop: all ? -30 : -50, marginRight: 15 }}>
              <h3 className='colb fz16 tc mb10 mt40'>渠道分布</h3>
              <div className='hr-list1-channel1'>
                {/* {
                  orgPlaceFinishBlockStat.length ? <ul>
                    {
                      orgPlaceFinishBlockStat.map((d, i) => (
                        <li style={{ width: `${d.percent}%`, position: 'relative' }}>
                          {
                            d.percent < 40 ? <>
                              {
                                this.state.index == i ?
                                  <div className='ant-popover'>
                                    <div>{d.name}</div>
                                    <div>{d.value}人 <span>{d.percent}%</span></div>
                                  </div> : null
                              }
                              <span onMouseLeave={() => this.setState({ index: null })} onMouseOver={() => this.setState({ index: i })} style={{ writingMode: 'vertical-rl', textAlign: 'center', cursor: 'pointer' }} className="flex-h-center">{d.percent}%</span>
                            </> : <>
                              <div>{d.name}</div>
                              <div>{d.value}人 <span>{d.percent}%</span></div></>
                          }

                        </li>
                      ))
                    }
                  </ul> : <div style={{ height: 90, width: '100%' }} className="tc"><img src={empty}></img></div>
                } */}
                {
                  orgPlaceFinishBlockStat.length ? <ECharts
                    style={{ height: all ? 160 : 80, width: 525, marginLeft: -20 }}
                    option={options.getSmallBar(orgPlaceFinishBlockStat)}
                  /> : <div style={{ height: all ? 160 : 80, width: '100%', paddingTop: 10 }} className="tc"><img src={empty}></img></div>
                }
              </div>
            </div>
            <div className='mt20'>
              <Tit text="YTD平均招聘周期(社招)"></Tit>
              <div className={`${all ? 'mt20' : 'mt10'}`} style={{ paddingRight: 15 }}>
                {
                  avgPeriodStat.dimNames && avgPeriodStat.dimNames.length ?
                    <ECharts
                      style={{ height: all ? all1 ? 250 : 280 : 210, width: '109%' }}
                      className="OfflineGrowthTrend_charts"
                      option={options.getBarCharts(avgPeriodStat.dimNames, [
                        {
                          name: avgPeriodStat.categoryNames[0],
                          value: avgPeriodStat.categoryValues[0]
                        },
                        {
                          name: avgPeriodStat.categoryNames[1],
                          value: avgPeriodStat.categoryValues[1]
                        },
                        {
                          name: avgPeriodStat.categoryNames[2],
                          value: avgPeriodStat.categoryValues[2]
                        }
                      ], true, {
                        rotate: 0,
                        y: '天',
                        color: ['#00DBFF', '#FFE1A7', '#605BFF',]
                      })}
                    /> : <div style={{ height: all ? 280 : 210, width: '100%', paddingTop: 80 }} className="tc"><img src={empty}></img></div>
                }
              </div>

            </div>
          </div>
        </div>

      </div >
    );
  }
}

export default Index;